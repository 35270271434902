package components.account

import components.divs.SectionNoTopBottom
import components.divs.container
import csstype.ClassName
import react.VFC
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p

val termsAndConditions = VFC("termsAndConditions") {
	SectionNoTopBottom {
		container {
			div {
				className = ClassName("link-box text-center no-bottom")
				p {
					className = ClassName("small text-grey no-top-bottom")
					+"By connecting a payment, you agree to the Rooam's "
					a {

						href = "https://rooam.co/legal"
						target = AnchorTarget._blank
						className = ClassName("text-link")
						rel = "noreferrer"
						+"Terms & Conditions"
					}
					+" and "
					a {
						href = "https://rooam.co/policy"
						target = AnchorTarget._blank
						className = ClassName("text-link")
						rel = "noreferrer"
						+"Privacy Policy"
					}
				}
			}
		}
	}
}
