package api

import api.storage.storeRooamHeader
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.async
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mainScope

val logout = {
    mainScope.async {
        client.post("/logout") {
            addSessionHeader()
            header("Content-Type", ContentType("application", "json"))
        }.also { it.storeRooamHeader() }
    }
}

val resendCode = { phoneNumber: String,
                   updateState: (Boolean) -> Unit,
                   updateError: (String?) -> Unit ->
    mainScope.launch {
        updateState(true)
        val response: HttpResponse =
            client.post("/login/code") {
                setBody(Phone(phoneNumber, true))
                header("Content-Type", ContentType("application", "json"))
                expectSuccess = false
            }
        if (response.status == HttpStatusCode.BadRequest) {
            val receive = response.body<Map<String, Map<String, String?>>>()
            val errors = receive["errors"]
            updateError(errors?.values?.first { it!=null })
            updateState(false)
        } else {
        response.storeRooamHeader()
        launch {
            delay(5000)
            updateState(false)
        }
        }
    }
}
