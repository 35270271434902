package pages

import components.divs.SectionNoTopBottom
import components.layout
import components.location
import components.marketing.bigBanner
import components.marketing.smallBanner
import components.rooamFooter
import components.seo
import components.utils.orbit
import csstype.ClassName
import locationBase
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.p
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.useNavigate
import react.router.useParams

external interface TabVoidedProps : Props

val tabVoided = FC<TabVoidedProps> {
	val location = useParams()
	val history = useNavigate()

	layout {
		seo {
			titleTemplate = "Tab Voided - %s"

		}
		SectionNoTopBottom{
			location {
				showBack = false
			}
			smallBanner {
			}
			div {
				className = ClassName("section largest")
				div {
					className = ClassName("container text-center")
					div {
						className = ClassName("orbit-box")
						orbit()
						div {
							className = ClassName("icon")
							svg {
								className = ClassName("svg-icon")
								viewBox = "0 0 24 24"
								path {
									d =
										"M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M4,12c0-4.4,3.6-8,8-8c1.9,0,3.6,0.6,4.9,1.7L5.7,16.9 C4.6,15.6,4,13.9,4,12z M12,20c-1.9,0-3.6-0.6-4.9-1.7L18.3,7.1c1.1,1.3,1.7,3,1.7,4.9C20,16.4,16.4,20,12,20z"

									fill = "#ffffff"


								}
							}
						}
					}
					h2 {
						className = ClassName("no-top-bottom")
						+"Tab Voided"
					}
					p {
						className = ClassName("no-top-bottom")
						+"No payment was made."
					}
				}
			}
			SectionNoTopBottom{
				div {
					className = ClassName("container text-center")
					hr {}
					div {
						className = ClassName("section less no-bottom")
						a {
							href = "${locationBase(location)}/open"
							className = ClassName("btn red")
							onClick = {
								it.preventDefault()
								history("${locationBase(location)}/open")
							}

							+"Open Another Tab"

						}
					}
				}
			}
			bigBanner { }
			rooamFooter { }
		}
	}
}


