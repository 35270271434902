package api

import io.ktor.client.call.*
import io.ktor.http.*
import kotlinx.browser.localStorage
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import pages.Tips
import pages.getSingleFeed


suspend fun getFeed(
): Feed? {
	try {
		val feed = getSingleFeed()
		if (feed.status == HttpStatusCode.Unauthorized) {
			return null
		} else {
			return feed.body()
		}
	} catch (e: Throwable) {
		console.error("Feed pull unsuccessful",e)
		return null
	}
}

fun setTips(tips: Tips) {
	localStorage.setItem("TIPS", Json.encodeToString(tips))
}



