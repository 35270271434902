package components.marketing

import api.ActionMessage
import api.ClaimRewardState
import api.claimCampaignReward
import api.mutateCampaingClaim
import components.marketing.model.BannerAction
import components.marketing.model.BannerActionClaimReward
import components.marketing.model.BannerActionOpenLink
import components.marketing.model.SmallBanner
import components.themes.dialogTheme
import csstype.*
import emotion.react.css
import io.ktor.client.plugins.*
import kotlinx.coroutines.async
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.collectLatest
import kotlinx.js.jso
import mainScope
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.CardMedia
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.Drawer
import mui.material.DrawerAnchor
import mui.material.Typography
import mui.material.styles.ThemeProvider
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.query.UseMutationOptions
import react.query.useMutation

external interface BannerActionProps : Props {
	var title: String?
}

val bannerActionTitle = FC<BannerActionProps>("bannerActionTitle") { props ->
	var show by useState(false)
	useEffect {
		show = props.title != null
	}
	p {
		className = ClassName("small text-light-grey no-top-bottom")
		style = jso {
			display = if (show) Display.block else None.none
		}
		props.title?.let {
			+it
		}
	}
}

external interface BannerProps : Props {
	var booleanShowModal: Boolean
	var info: SmallBanner
	var claimActionPressed: (CampaignId) -> Unit
	var closeActionPressed: () -> Unit
}

val BannerShowModal = FC<BannerProps>("BannerActionClaimReward") { props ->

	ThemeProvider {
		theme = dialogTheme
		Drawer {
			open = props.booleanShowModal
			onClose = { event, _ ->
				event.stopPropagation()
				props.closeActionPressed()
			}
			anchor = DrawerAnchor.bottom
			PaperProps = jso {
				sx {
					borderTopRightRadius = 10.px
					borderTopLeftRadius = 10.px
					textAlign = TextAlign.center
				}
			}

			div {

				css {
					background = linearGradient(
						0.deg,
						stop(NamedColor.white, 0.pct),
						stop(Color(props.info.theme.backgroundColor), 3.pct)
					)
					padding = Padding(2.em, 2.em)
				}
				img {
					src = props.info.imageUrl
				}
			}
			DialogContent {
				Typography {
					variant = TypographyVariant.body1
					+props.info.text
				}

			}
			DialogActions {
				Button {
					sx {
						width = 100.pct
						minHeight = 48.px
						maxHeight = 72.px
					}
					onClick = {
						it.stopPropagation()
						props.closeActionPressed()
					}
					color = ButtonColor.primary

					variant = ButtonVariant.outlined
					+"Close"

				}
				Button {
					sx {
						width = 100.pct
						minHeight = 48.px
						maxHeight = 72.px
					}
					onClick = {
						it.stopPropagation()

						props.claimActionPressed(CampaignId(props.info.campaignId))
					}
					color = ButtonColor.primary

					variant = ButtonVariant.contained
					+"Claim"
				}
			}


		}
	}


//	div {
//		className = ClassName("section full-page modal active")
//		div {
//			className = ClassName("modal-overlay")
//
//			div {
//				className = ClassName("section less no-bottom")
//				style = jso {
//					display = if (bannerActionState == BannerActionState.FAILURE) Display.block else None.none
//				}
//				container {
//					div {
//						className = ClassName("message error")
//						style = jso {
//							backgroundColor = Color("#3B0A09")
//						}
//						ErrorMessage {
//							icon = StandardError
//							title = "Issue claiming promotion."
//							text = " Please try again"
//							isSmall = true
//						}
//					}
//				}
//			}
//		}
//		div {
//			className = ClassName("middle-content")
//			container {
//				div {
//					className = ClassName("modal-box")
//					div {
//						className = ClassName("text-center")
//						div {
//							className = ClassName("box-wrap")
//							style = jso {
//								background = linearGradient(
//									0.deg,
//									stop(NamedColor.white, 50.pct),
//									stop(Color(props.info.theme.backgroundColor), 50.pct)
//								)
//								overflow = Overflow.hidden
//							}
//							div {
//								className = ClassName("claim-reward-header-box")
//								style = jso {
//									backgroundColor = Color(props.info.theme.backgroundColor)
//								}
//								div {
//									className = ClassName("claim-reward-header-image-wrap")
//									img {
//										src = props.info.imageUrl
//									}
//								}
//
//								a {
//									onClick = {
//										gtag(
//											"event",
//											"banner_action_claim_reward_close",
//											json("campaignId" to props.info.campaignId)
//										)
//										props.closeActionPressed(it.nativeEvent)
//									}
//									className = ClassName("claim-reward-close-btn")
//									closeIcon()
//								}
//
//							}
//							div {
//								className = ClassName("padding")
//								h3 {
//									className = ClassName("no-top-bottom")
//									style = jso {
//										color = NamedColor.black
//									}
//									+props.info.text
//								}
//							}
//							div {
//								className = ClassName("padding no-top")
//								a {
//									className =
//										ClassName("btn black ${if (bannerActionState == BannerActionState.STARTED) "disabled" else ""}")
//									style = jso {
//										display =
//											if (bannerActionState == BannerActionState.DEFAULT || bannerActionState == BannerActionState.FAILURE) Display.block else None.none
//									}
//									onClick = {
//										gtag(
//											"event",
//											"banner_action_claim_reward_started",
//											json("campaignId" to props.info.campaignId)
//										)
//										claimCampaignReward(CampaignId(props.info.campaignId), setBannerActionState) {
//											props.claimActionPressed(it.nativeEvent, CampaignId(props.info.campaignId))
//											gtag(
//												"event",
//												"banner_action_claim_reward_success",
//												json("campaignId" to props.info.campaignId)
//											)
//										}
//									}
//									props.info.action?.primaryActionTitle?.let {
//										+it
//									}
//								}
//								div {
//									className = ClassName("text-center btn black disabled")
//									style = jso {
//										display =
//											if (bannerActionState == BannerActionState.STARTED) Display.block else None.none
//									}
//									loadingIcon()
//								}
//								div {
//									className = ClassName("text-center btn disabled")
//									style = jso {
//										display =
//											if (bannerActionState == BannerActionState.SUCCESS) Display.block else None.none
//										backgroundColor = Color("#36c299")
//									}
//									checkIcon {
//										fillColor = "#ffffff"
//									}
//								}
//							}
//						}
//					}
//				}
//			}
//		}
//	}
}

private fun claimCampaignReward(
	campaignId: CampaignId,
	setBannerActionState: StateSetter<BannerActionState>,
	onSuccess: () -> Unit
) {
	mainScope.async {
		claimCampaignReward(campaignId)
			.catch { handleClaimRewardError(it, campaignId, setBannerActionState) }
			.collectLatest { handleClaimRewardResult(it, campaignId, setBannerActionState, onSuccess) }
	}
}

private suspend fun handleClaimRewardResult(
	result: Result<ClaimRewardState>,
	campaignId: CampaignId,
	setBannerActionState: StateSetter<BannerActionState>,
	onSuccess: () -> Unit
) {
	result.onSuccess { state ->
		when (state) {
			ClaimRewardState.STARTED -> {
				setBannerActionState(BannerActionState.STARTED)
			}
			ClaimRewardState.SUCCESS -> {
				setBannerActionState(BannerActionState.SUCCESS)
				delay(1000)
				onSuccess()
			}
		}
	}.onFailure {
		handleClaimRewardError(it, campaignId, setBannerActionState)
	}
}

private suspend fun handleClaimRewardError(
	exception: Throwable,
	campaignId: CampaignId,
	setBannerActionState: StateSetter<BannerActionState>
) {
	console.log(exception)
	val message = "Issue claiming reward for campaign: ${campaignId.id} - ${exception.message ?: ""}"
	setBannerActionState(BannerActionState.FAILURE)
	error(ActionMessage("claim_campaign_reward", message))
}

val BannerAction.title: String
	get() = when (this) {
		is BannerActionClaimReward -> title
		is BannerActionOpenLink -> title
	}

val BannerAction.primaryActionTitle: String?
	get() = (this as? BannerActionClaimReward)?.primaryActionTitle

data class CampaignId(val id: String)

enum class BannerActionState {
	DEFAULT,
	STARTED, SUCCESS,
	FAILURE
}
