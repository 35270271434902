package components.summary

import api.HistoryData
import api.getSingleReceiptThrowing
import co.rooam.components.receipt.RooamReceipt
import components.animations.ErrorMessage
import components.animations.RotatingLoader
import components.divs.SectionNoTopBottom
import components.divs.container
import components.error.ConnectionError
import components.error.StandardError
import csstype.ClassName
import emotion.css.cx
import co.rooam.utilities.functions.sum
import io.ktor.client.plugins.*
import io.ktor.http.HttpStatusCode.Companion.NotFound
import io.ktor.http.HttpStatusCode.Companion.PaymentRequired
import io.ktor.http.HttpStatusCode.Companion.Unauthorized
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import kotlinx.js.jso
import mainScope
import react.FC
import react.Props
import react.VFC
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.query.QueryFunctionContext
import react.query.QueryKey
import react.query.UseQueryOptions
import react.query.useQuery
import react.router.useNavigate
import react.useState


enum class SummaryState {
	CONNECTION_ERROR, ERROR, LOADING, SUCCESS

}

data class SummaryData(
	val summaryState: SummaryState,
	val historyData: HistoryData? = null
)

external interface SummaryProps : Props {
	var historyData: HistoryData

}
typealias QueryOptions = UseQueryOptions<HistoryData, Throwable, HistoryData, QueryKey>


val Summary = VFC("Summary") {
	var summaryProps by useState(SummaryData(SummaryState.LOADING))
	val history = useNavigate()

	val query = useQuery(
		QueryKey<QueryKey>("receipt"), pullReceipt,
		jso<QueryOptions> {
			onSuccess = { summaryProps = SummaryData(SummaryState.SUCCESS, it) }
			onError = {
				if (it is ResponseException) {
					when (
						it.response.status
					) {
						PaymentRequired -> history("../overdue")
						Unauthorized -> history("../phone")
						NotFound -> summaryProps = SummaryData(SummaryState.ERROR)
						else -> summaryProps = SummaryData(SummaryState.ERROR)
					}
				} else summaryProps = SummaryData(SummaryState.ERROR)
			}

			retry = { failureCount, error ->

				val isNotPaymentRequired =
					if (error is ResponseException) error.response.status != PaymentRequired else true
				isNotPaymentRequired && failureCount <= 5
			}
		}
	)

	kotlinext.js.require("./styles/summary.css")
	SectionNoTopBottom {
		when (summaryProps.summaryState) {
			SummaryState.CONNECTION_ERROR -> {
				div {
					className = ClassName("section less summary-btn")
					container {
						ErrorMessage {
							title = "Connection Error."
							text = "Please try refreshing page or logging out and back in."
							icon = ConnectionError
						}
					}
				}
			}
			SummaryState.ERROR -> {
				div {
					className = ClassName("section less summary-btn")
					container {
						ErrorMessage {

							title = "Error Generating Tab Details."
							text = "Last Tab Details cannot be generated."
							icon = StandardError

						}
					}
				}
			}
			SummaryState.LOADING -> {
				div {
					className = ClassName("section less summary-btn")
					container {
						RotatingLoader {
							text = "Generating Tab Details..."
						}
					}
				}
			}
			SummaryState.SUCCESS -> {
				SuccessSummary {
					this.historyData = query.data!!

				}
			}

		}
	}

}

val SuccessSummary = FC<SummaryProps> {
	var isSummaryActive: Boolean by useState(false)

	a {
		className = ClassName("section less summary-btn")
		if (isSummaryActive) className = cx(className, ClassName(" active"))

		onClick = {
			it.preventDefault()
			isSummaryActive = !isSummaryActive
		}
		tabIndex = 0
		container {
			div {
				className = ClassName("row clearfix")
				div {
					className = ClassName("column")
					p {
						className = ClassName("text-white font-weight-600 no-top-bottom")
						+"Tab Details"

						div {
							className = ClassName("expand-btn")
							span {
								className = ClassName("icon")
								svg {
									className = ClassName("svg-icon")
									viewBox = "0 0 24 24"
									path {
										d =
											"M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-18.005-1.568l1.415-1.414 4.59 4.574 4.579-4.574 1.416 1.414-5.995 5.988-6.005-5.988z"
										fill = "#bebebe"

									}
								}
							}
						}
					}
				}

				div {
					className = ClassName("column text-right")
					p {
						className = ClassName("text-white font-weight-600 no-top-bottom")
						+"\$${it.historyData?.receipt?.total}"
					}
				}
			}
		}
	}
	div {
		className = cx(
			ClassName("section"),
			ClassName(" summary-bg"),
			ClassName(" summary-info"),
			ClassName(" no-top-bottom"),
			if (isSummaryActive) {
				ClassName(" active")
			} else null
		)

		SummaryItems {
			items = it.historyData.items
		}
		div {
			className = ClassName("section less")
			RooamReceipt {

				val receipt = it.historyData.receipt
				this.fees = receipt.fee ?: "0.00"
				this.tax = receipt.tax ?: "0.00"
				this.tip = receipt.tip
				this.discount = receipt.discounts ?: "0.00"
				this.subtotal = receipt.subTotal
				this.serviceCharges = receipt.serviceCharges ?: "0.00"
				this.credit = receipt.creditApplied
				this.taxesFees = sum(sum(receipt.tax, receipt.fee), receipt.serviceCharges)

			}
		}
		div {
			className = ClassName("section no-top")
			container {
				div {
					className = ClassName("row clearfix")
					div {
						className = ClassName("column")
						p {
							className = ClassName("medium text-white font-weight-500 no-top-bottom")
							+"Total:"
						}
					}
					div {
						className = ClassName("column text-right")
						p {
							className = ClassName("medium text-white font-weight-500 no-top-bottom")
							+it.historyData!!.receipt.total
						}
					}
				}
			}
		}

	}


}

val pullReceipt = { context: QueryFunctionContext<QueryKey, *> ->
	mainScope.async {
		getSingleReceiptThrowing()
	}.asPromise()
}


