package api

import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import io.ktor.util.*
import kotlinx.browser.localStorage
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import io.ktor.client.plugins.logging.*

val client = HttpClient(Js) {
	this.engine {
		this.threadsCount = 1
	}
	install(ContentNegotiation) {
		json(json = Json {
			ignoreUnknownKeys = true
		})
	}

	if (environment.contentEquals("local", ignoreCase = true)) {
		install(Logging) {
			logger = Logger.DEFAULT
			level = LogLevel.ALL
		}
	}

	this.defaultRequest {
		url {
			this.protocol = URLProtocol.createOrDefault(hostProtocol)
			port = hostPort
			host = hostPath

		}
		setAttributes {
			put(AttributeKey("credentials"), "include")
			put(AttributeKey("mode"), "cors")
		}
	}
}

object SessionConstants {
	const val rooamSessionHeader = "X-ROOAM-Session"
	const val rooamSessionStaticHeader = "X-ROOAM-Session-Static"
}

fun HttpRequestBuilder.addSessionHeader() {
	header(SessionConstants.rooamSessionHeader, localStorage[SessionConstants.rooamSessionHeader] ?: "INVALID")
}

fun HttpRequestBuilder.addSessionStaticHeader() {
	header(SessionConstants.rooamSessionStaticHeader, localStorage[SessionConstants.rooamSessionHeader] ?: "INVALID")
}

enum class MessageSendState {
	NONE,
	SUCCESS,
	ERROR
}
