package components.help

import components.modals.IframeModal
import components.themes.dialogTheme
import csstype.NamedColor
import csstype.TextAlign
import csstype.minus
import csstype.pct
import csstype.px
import csstype.vh
import emotion.react.css
import kotlinx.js.jso
import mui.material.Box
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.styles.ThemeProvider
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.iframe


external interface HelpModalProps : Props {
	var booleanShowModal: Boolean?
	var cancelActionPressed: () -> Unit
}

val HelpModal = FC<HelpModalProps> { props ->
	IframeModal {
		iframeAddress =
			"https://docs.google.com/forms/d/e/1FAIpQLSfj-MFkVgr8JgYH-aEBvXb2Ak0ZLx1BGp0nSVGKrao_gozUng/viewform?embedded=true"
		cancelActionPressed = props.cancelActionPressed
		booleanShowModal = props.booleanShowModal
	}
}

