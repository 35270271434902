import api.hostPath
import api.traceRate
import api.version
import co.rooam.theme.ThemesProvider
import components.themes.dialogTheme
import csstype.LengthProperty
import imported.devTools.ReactQueryDevtools
import imported.sentry.*
import kotlinx.browser.document
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.js.jso
import mui.material.CssBaseline
import mui.material.PaletteMode
import mui.material.styles.ThemeOptions
import mui.material.styles.ThemeProvider
import mui.material.styles.createTheme
import react.StrictMode
import react.create
import react.dom.client.createRoot
import react.query.QueryClient
import react.query.QueryClientProvider
import react.rawUseEffect
import react.router.createRoutesFromChildren
import react.router.dom.BrowserRouter
import react.router.matchRoutes
import react.router.useLocation
import react.router.useNavigationType


fun baseThemeOptions() = jso<ThemeOptions> {

	typography = jso {
		fontFamily = "'Calibre', Helvetica Neue, Helvetica, Arial, sans-serif"
		h3 = jso {
			fontSize = "20px"
			lineHeight = "24px"
			fontWeight = "600"
		}
	}

	shape = jso {
		borderRadius = 8.unsafeCast<LengthProperty>()
	}
	palette = jso {
		background = jso {
			default = "#040507"
			paper = "#1a1a1c"
		}
		primary = jso {
			main = "#d9211b"
		}
		mode = PaletteMode.dark
		secondary = jso {
//			main ="#6533F1"
			main = "#ffffff"
		}
		info = jso {
			main = "#353739"
		}
		divider = "rgba(255, 255, 255, 0.40)"

	}

}

val taoTheme = baseThemeOptions().apply {
	shape = jso {
		borderRadius = 0.unsafeCast<LengthProperty>()
	}
	palette = jso {
		background = jso {
			default = "#242526"
			paper = "#1a1a1c"
		}
		primary = jso {
			main = "#d9211b"
		}
		mode = PaletteMode.dark
		secondary = jso {
//			main ="#6533F1"
			main = "#ffffff"
		}
		info = jso {
			main = "#353739"
		}
		success = jso{
			main = "#3EC18E"
		}
		divider = "rgba(255, 255, 255, 0.40)"

	}

}

val rooamTheme = baseThemeOptions()
fun isTaoEnabled() = sessionStorage.getItem("taoEnabled") != null

val defaultThemeOptions = if (isTaoEnabled()) {
	taoTheme
} else rooamTheme

val defaultTheme = createTheme(defaultThemeOptions)

@Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
fun main() {

	ThemesProvider.dialogTheme = dialogTheme
	ThemesProvider.mainTheme = defaultTheme

	window.onload = {
		init(
			jso {
				debug = false
				dsn = "https://fd688b95873340f9b829d9c85a499c49@o1001471.ingest.sentry.io/5961105"
				tracesSampleRate = traceRate
				integrations = arrayOf(
					BrowserTracing(
						jso {
							tracingOrigins = arrayOf<String>(hostPath)
							idleTimeout = 60000
							routingInstrumentation = reactRouterV6Instrumentation(
								useEffect = ::rawUseEffect,
								useLocation = ::useLocation,
								useNavigationType = ::useNavigationType,
								createRoutesFromChildren = ::createRoutesFromChildren,
								matchRoutes = ::matchRoutes
							)
						}
					),
					CaptureConsole(jso { levels = arrayOf("warn", "error") })
				)
				environment = api.environment
				release = version
				beforeSend = { event, hint ->
					event
				}
				ignoreErrors = arrayOf(
					"UnknownError: setOptions failed"
				)

			}
		)
		if (window.navigator.cookieEnabled) {


			createRoot(
				document.getElementById("root")!!
			).render(
				StrictMode.create {

					QueryClientProvider {
						client = queryClient
						ThemeProvider {
							theme = ThemesProvider.mainTheme
							CssBaseline {
								enableColorScheme = true
							}
							BrowserRouter {

								app {}
							}
						}
						ReactQueryDevtools {

						}
					}
				})

		} else {
			window.alert("Web Tab to work requires cookies, Please enable them and reload page")
		}
	}
}

val queryClient = QueryClient()

