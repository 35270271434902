package components.marketing.model

import kotlinx.serialization.InternalSerializationApi
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.serializer
import kotlin.js.Json
import kotlin.js.json
import kotlin.reflect.KClass

@Serializable
abstract class Banner {
	abstract val type: String
	abstract val campaignId: String
}

@Serializable
@SerialName("BANNER_SMALL")
data class SmallBanner(
	override val type: String,
	val imageUrl: String,
	val text: String,
	val theme: BannerTheme,
	val action: BannerAction?,
	override val campaignId: String
) : Banner()

@Serializable
@SerialName("BANNER_BIG")
data class BigBanner(
	override val type: String,
	val imageUrl: String,
	val title: String,
	val text: String,
	val theme: BannerTheme,
	val action: BannerLink,
	override val campaignId: String
): Banner()

@Serializable
data class BannerTheme(
	val backgroundColor: String,
	val borderColor: String
)

@Serializable
data class BannerLink(
	val title: String,
	val url: String,
	val target: String = "_blank"
)

@Serializable
enum class BannerType {
	BANNER_SMALL, BANNER_BIG
}

const val BannerTypeParam = "bannerType"

@Serializable
sealed class BannerAction

@Serializable
@SerialName("BANNER_ACTION_CLAIM_REWARD")
data class BannerActionClaimReward(
	val title: String,
	val primaryActionTitle: String
): BannerAction()

@Serializable
@SerialName("BANNER_ACTION_OPEN_LINK")
data class BannerActionOpenLink(
	val title: String,
	val url: String,
	val target: String
): BannerAction()

val BannerAction.type: String get() = when (this) {
	is BannerActionClaimReward -> "BANNER_ACTION_CLAIM_REWARD"
	is BannerActionOpenLink -> "BANNER_ACTION_OPEN_LINK"
}

inline fun <reified T : Banner> bannerTypeForClass(clazz: KClass<T>): BannerType = when (clazz) {
	BigBanner::class -> BannerType.BANNER_BIG
	else -> BannerType.BANNER_SMALL
}

fun Banner.toJson(): Json = when (this) {
	is SmallBanner -> json(
		"type" to type,
		"campaignId" to campaignId,
		"action" to (action?.type ?: "NONE")
	)
	is BigBanner -> json(
		"type" to type,
		"campaignId" to campaignId,
		"action" to "BANNER_ACTION_OPEN_LINK"
	)
	else -> json()
}

