package components

import csstype.ClassName
import csstype.Color
import emotion.react.css
import kotlinext.js.require
import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import kotlin.js.Date

//@JsModule("./images/logo/logo192x192.png")
//@JsNonModule
//external val logo: dynamic



val rooamFooter = FC<Props> {
	require("./styles/footer.css")
	val logo = require("./images/logo/logo192x192.png")
	div {
		className = ClassName("section footer")
		div {
			className = ClassName("container text-center")
			span {
				className = ClassName("foot-logo")
				img {
					alt = "Rooam Icon"
					src = logo
				}
			}
			p {
				css(ClassName("small font-weight-600 no-bottom")){
					color = Color("#bebebe")
				}
				+"Powered by Rooam"
			}
			div {
				className = ClassName("link-box text-center no-bottom")
				a {
					href = "mailto:help@rooam.co"
					target = AnchorTarget._blank
					className = ClassName("btn block margin outline")
					+"Give Feedback"
				}
				a {
					href = "https://rooam.co"
					target = AnchorTarget._blank
					className = ClassName("btn block margin outline")
					+"About Rooam"
				}


			}
			p {
				className = ClassName("small text-grey no-bottom")
				+"\u00A9 Copyright ${Date().getFullYear()} - Rooam Inc."
			}
		}

	}
}


