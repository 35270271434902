package components.divs

import csstype.ClassName
import emotion.react.css
import org.w3c.dom.HTMLDivElement
import react.FC
import react.PropsWithChildren
import react.dom.html.HTMLAttributes
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p

val SectionNoTopBottom = FC<PropsWithChildren> { props ->
	div {
		className = ClassName("section no-top-bottom")
		+props.children
	}
}


val p500 = FC<PropsWithChildren> { props ->
	p {
		className = ClassName("font-weight-500 no-top-bottom")
		+props.children
	}
}

val container =   FC<HTMLAttributes<HTMLDivElement>> { props ->
	div {
		css(props.className,ClassName("container")){}
		style = props.style
		+props.children
	}
}


val containerTextCenter = FC<PropsWithChildren> { props ->
	div {
		className = ClassName("container text-center")
		+props.children
	}
}
