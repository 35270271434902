package api

import co.rooam.components.payment.Payment
import co.rooam.components.tab.live.OrderedItem
import kotlinx.serialization.Serializable

@Serializable
data class Feed(
    val open: Boolean = false,
    val voided: Boolean = false,
    val tab: Tab? = null,
    val totals: Totals? = null,
    val paymentMethod: ApiPayment? = null
)

@Serializable
data class ApiPayment(
    override val type: String,
    override val label: String,
    override val default: Boolean=false,
    override val id: String,
    override val image: String
) : Payment

@Serializable
data class Totals(
    val total: String,
    val tax: String,
    val subTotal: String,
    val fee: String,
    val partialPayment: String,
    val serviceCharges: String,
    val credit: String,
    val discount: String?=null
)

@Serializable
data class Tab(
    val tabNumber: String?,
    val items: Array<TabItem>
)

@Serializable
data class TabItem(
    override val name: String,
    override val price: String,
    override val pricePerUnit: String,
    override val quantity: Int
) : OrderedItem
