package components.google

import csstype.ClassName
import emotion.css.cx
import google.prepareGooglePay
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import mainScope
import react.*
import react.dom.html.ReactHTML.button
import react.router.NavigateFunction
import react.router.useNavigate

external interface GooglePayProps : Props {
	var setLoading: (Boolean) -> Unit
	var navigate: (NavigateFunction) -> Unit
}

val googlePayButton = FC<GooglePayProps> { props ->
	val history = useNavigate()
	var notReady by useState(true)
	val googleBehavior = useRef<(() -> Any)>(null)
	useEffectOnce {
		mainScope.launch {
			googleBehavior.current = prepareGooglePay(
				null, { mainScope.async { } },
				{ props.setLoading(it) }) {
				props.navigate(history)
			}.await()
			notReady = false
		}
	}
	button {
		className = ClassName("btn brand white")
		if (notReady) {
			className = cx(className, ClassName(" disabled"))
		}
		onClick = {
			console.log("Pressed", googleBehavior)
			googleBehavior.current?.invoke()
		}

		components.icons.googlePayIcon {}
	}
}
