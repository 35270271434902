package api

import api.storage.storeRooamHeader
import imported.incode.IncodeOCRData
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.async
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import mainScope
import kotlin.js.Promise

fun getIdentityProfile(): Promise<IdentityVerificationProfile> {
	return Promise { resolve, reject ->
		mainScope.async {
			val response: HttpResponse = client.get {
				url(path = "/identity/verification/profile")
				addSessionHeader()
				expectSuccess = false
				header("Content-Type", ContentType("application", "json"))
			}.also { it.storeRooamHeader() }

			when (response.status) {
				HttpStatusCode.OK -> resolve(response.body())
				else -> reject(Exception("Something went wrong getting User Identity Verification Profile - $response"))
			}
		}
	}
}

suspend fun updateIdentityProfile(id: String, state: IdentityVerificationState, ocrData: IncodeOCRData?, base64FaceImage: String?) {

	console.log("trying to update profiled identity!", id, state, ocrData?.birthDate, base64FaceImage)

	val body = IdentityVerificationProfileUpdate()
	body.id = id
	body.state = state.name
	body.provider = IdentityVerificationProvider.INCODE.name
	body.firstName = ocrData?.name?.firstName
	body.lastName = ocrData?.name?.paternalLastName
	body.birthdateTimestamp = ocrData?.birthDate?.toString()
	body.base64FaceImage = base64FaceImage

	console.log("update request", body)

	try {
		client.post {
			url(path = "/identity/verification/profile")
			setBody(body)
			addSessionHeader()
			header("Content-Type", ContentType("application", "json"))
			expectSuccess = true
		}.also { it.storeRooamHeader() }
	} catch (exception: Exception) {
		console.error("updateIdentityProfile() Error updating User Identity Verification Profile", exception, Json.encodeToString(body))
		throw exception
	}
}

@Serializable
class IdentityVerificationProfileUpdate {
	var id: String? = null
	var state: String? = null
	var provider: String? = null
	var firstName: String? = null
	var lastName: String? = null
	var birthdateTimestamp: String? = null
	var base64FaceImage: String? = null
}

@Serializable
class IdentityVerificationProfile {
	var id: String? = null
	var state: IdentityVerificationState? = null
	var provider: IdentityVerificationProvider? = null
	var firstName: String? = null
	var lastName: String? = null
	var birthdateTimestamp: Long? = null
	var faceImageUrl: String? = null
	var ageVerified: Boolean = false
}

@Serializable
enum class IdentityVerificationState {
	VERIFIED,
	VERIFYING,
	VERIFIED_INVALID
}

@Serializable
enum class IdentityVerificationProvider {
	INCODE
}
