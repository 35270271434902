package components.item

import csstype.ClassName
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span


val ItemSmall = FC<ItemProps>("itemSmall") { props ->
	div {
		className = ClassName("padding")
		span {
			className = ClassName("colored-dot")
		}
		div {
			className = ClassName("row clearfix")
			div {
				className = ClassName("column three-four")
				p {
					className = ClassName("font-weight-500 text-white no-top-bottom")
					id = "item-count"
					+"${props.quantity} x ${props.itemName}"
				}
			}
			div {
				className = ClassName("column one-four text-right")
				p {
					className = ClassName("font-weight-500 text-white no-top-bottom")
					+"\$${props.itemTotal}"
				}
			}
		}

	}

}


