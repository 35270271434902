package co.rooam.components.payment


fun getImageFromPayment(payment: Payment): String {
    if (payment.type=="LOADING") {
        return "/images/payments/other.jpg"
    }
    if (payment.type == "AP")
        return "/images/payments/apple-pay.jpg"
    if (payment.type == "APPLE_PAY")
        return "/images/payments/apple-pay.jpg"
    if (payment.type == "GOOGLE_PAY")
        return "/images/payments/google-pay.jpg"
    if (payment.type == "GP")
        return "/images/payments/google-pay.jpg"
    if (payment.image.contains("visa"))
        return "/images/payments/visa.jpg"
    if (payment.image.contains("american"))
        return "/images/payments/amex.jpg"
    if (payment.image.contains("apple"))
        return "/images/payments/apple-pay.jpg"
    if (payment.image.contains("google"))
        return "/images/payments/google-pay.jpg"
    if (payment.image.contains("android"))
        return "/images/payments/google-pay.jpg"
    if (payment.image.contains("master"))
        return "/images/payments/mastercard.jpg"
    if (payment.image.contains("discover"))
        return "/images/payments/discover.jpg"
    if (payment.image.contains("diners"))
        return "/images/payments/diners-club.jpg"
    if (payment.image.contains("maestro"))
        return "/images/payments/maestro.jpg"
    if (payment.image.contains("jcb"))
        return "/images/payments/jcb.jpg"
    return "/images/payments/other.jpg"
}
