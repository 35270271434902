package components

import co.rooam.components.payment.Payment
import co.rooam.components.payment.getImageFromPayment
import csstype.AlignItems.Companion.center
import csstype.AspectRatio
import csstype.Auto
import csstype.Border
import csstype.Color
import csstype.Display
import csstype.LineStyle.Companion.solid
import csstype.NamedColor
import csstype.Padding
import csstype.Position
import csstype.integer
import csstype.number
import csstype.pct
import csstype.px
import emotion.react.css
import imported.transitiongroup.TransitionGroup
import isTaoEnabled
import kotlinx.js.jso
import mui.icons.material.AddCard
import mui.icons.material.CheckCircle
import mui.icons.material.RadioButtonUnchecked
import mui.material.Box
import mui.material.Card
import mui.material.CardActionArea
import mui.material.CardContent
import mui.material.CardMedia
import mui.material.Collapse
import mui.material.Grid
import mui.material.Grow
import mui.material.PaperVariant
import mui.material.Skeleton
import mui.material.SkeletonVariant
import mui.material.Stack
import mui.material.SvgIconColor
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.material.styles.useTheme
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.router.useNavigate
import react.useEffect
import react.useState


external interface PaymentList : Props {
	var payments: Array<Payment>
	var selectedPayment: Payment?
	var onPaymentSelect: (Payment) -> Unit
	var showDelete: Boolean?
	var reload: () -> Unit
}

val paymentListGrid = FC<PaymentList>("payments") { props ->
	val (selectedPayment, setPayment) = useState(props.selectedPayment)
	val navigation = useNavigate()
	useEffect(props.payments) {
		console.log(props.payments)
		props.payments.forEach {
			if (it.default) setPayment(it)
		}
	}
	Box {
		sx = jso {
			flexGrow = number(1.0)
		}

		Grid {
			spacing = responsive(1)
			columns = responsive(
				Breakpoint.xs to responsive(12),
				Breakpoint.sm to responsive(12),
				Breakpoint.md to responsive(12)
			)
			container = true


			TransitionGroup {
				component = null

				props.payments.forEach {
					val selected = (selectedPayment == it)
					Grow {
						Grid {
							item = true
							xs = 6
							SinglePaymentMethod {
								payment = it
								isSelected = selected
								onPaymentSelect = {
									setPayment(it)
									props.onPaymentSelect(it)
								}
								showDelete = props.showDelete
							}
						}


					}
				}
				Grow {
					Grid {
						item = true
						xs = 6
						CardActionArea {
							Card {
								sx {
									alignItems = center
									padding = Padding(top = 6.px, right = 10.px, bottom = 6.px, left = 10.px)
								}
								variant = PaperVariant.outlined
								onClick = {
									navigation("../addpayment")
								}
								CardMedia {
									component = AddCard

									sx {
										aspectRatio = "115/81".unsafeCast<AspectRatio>()
										width = 100.pct
										height = Auto.auto
									}
								}
								CardContent {
									sx {
										padding = Padding(0.px, 0.px)
									}
									Typography {
										sx {
											fontWeight = integer(500)
											color = NamedColor.white
											padding = Padding(0.px, 10.px)
										}
										+"New Payment"
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

val paymentList = FC<PaymentList>("payments") { props ->
	val (selectedPayment, setPayment) = useState(props.selectedPayment)
	val navigation = useNavigate()
	useEffect(props.payments) {
		console.log(props.payments)
		props.payments.forEach {
			if (it.default) setPayment(it)
		}
	}
	Box {
		sx = jso {
			flexGrow = number(1.0)
		}

		Stack {
			spacing = responsive(1)

			TransitionGroup {
				component = null

				props.payments.forEach {
					val selected = (selectedPayment == it)
					Collapse {
						SingleRowPaymentMethod {
							payment = it
							isSelected = selected
							onPaymentSelect = {
								setPayment(it)
								props.onPaymentSelect(it)
							}
							showDelete = props.showDelete
						}
					}


				}

				Collapse {
					CardActionArea {
						Card {
							sx {
								alignItems = center
								padding = Padding(top = 6.px, right = 10.px, bottom = 6.px, left = 10.px)
								display = Display.Companion.flex
							}
							variant = PaperVariant.outlined
							onClick = {
								navigation("../addpayment")
							}
							AddCard {
								sx {
									aspectRatio = "115/81".unsafeCast<AspectRatio>()
									width = 50.px
									height = Auto.auto
									marginRight = 10.px
								}
							}
							Typography {
								sx {
									fontWeight = integer(500)
									padding = Padding(0.px, 10.px)
								}
								+"New Payment"

							}
						}
					}
				}
			}
		}
	}
}

external interface SinglePaymentMethodProps : Props {
	var payment: Payment
	var isSelected: Boolean
	var onPaymentSelect: (Payment) -> Unit
	var showDelete: Boolean?

}


val SinglePaymentMethod = FC<SinglePaymentMethodProps>("SinglePayment") {
	var loading by useState(it.payment.type == "LOADING")
	val theme = useTheme<Theme>()
	useEffect(it.payment) {
		loading = (it.payment.type == "LOADING")
	}


	CardActionArea {
		Card {

			elevation = if (it.isSelected) 24 else 0
			sx {
				padding = Padding(top = 6.px, right = 10.px, bottom = 6.px, left = 10.px)

				alignItems = center;
				border = if (it.isSelected) Border(1.px, solid, theme.palette.success.dark) else
					Border(1.px, solid, Color("#29292b"))


			}
			onClick = { event ->

				it.onPaymentSelect(it.payment)
			}
			if (loading) Skeleton {
				variant = SkeletonVariant.rectangular
				width = 100.pct
				CardMedia {
					component = img
					sx {
						width = 100.pct
						borderRadius = theme.shape.borderRadius
						aspectRatio = "115/81".unsafeCast<AspectRatio>()
						if (!it.isSelected) opacity = number(0.3)
					}
					image = getImageFromPayment(it.payment)

				}
			} else CardMedia {
				component = img
				sx {
					width = 100.pct
					borderRadius = theme.shape.borderRadius
					aspectRatio = "115/81".unsafeCast<AspectRatio>()
					if (!it.isSelected) opacity = number(0.3)
				}
				image = getImageFromPayment(it.payment)

			}
			CardContent {
				sx {
					padding = Padding(0.px, 0.px)
				}

				Typography {
					variant = TypographyVariant.body1
					sx {
						fontWeight = integer(500)

					}
					if (loading) {
						Skeleton {
							variant = SkeletonVariant.text
						}
					} else
						+it.payment.label
				}
			}
		}
	}
	if (it.showDelete == true && it.payment.type == "CC") {
		div {
			css {
				position = Position.absolute
				top = 6.px
				right = 2.px
			}
			deletePaymentButton {
				payment = it.payment
				disable = { disable ->
					loading = disable
				}
			}
		}
	}
}


val SingleRowPaymentMethod = FC<SinglePaymentMethodProps>("SinglePaymentRow") {
	var loading by useState(it.payment.type == "LOADING")
	val theme = useTheme<Theme>()
	useEffect(it.payment) {
		loading = (it.payment.type == "LOADING")
	}


	CardActionArea {
		Card {

			elevation = if (it.isSelected) 24 else 0
			sx {
				padding = Padding(top = 6.px, right = 10.px, bottom = 6.px, left = 10.px)
				display = Display.flex
				alignItems = center;
				border = if (it.isSelected) Border(1.px, solid, theme.palette.success.dark) else
					Border(1.px, solid, Color("#29292b"))
			}
			onClick = { event ->
				it.onPaymentSelect(it.payment)
			}
			if (loading) Skeleton {
				variant = SkeletonVariant.rectangular
				height = 100.pct
				img {
					css {
						width = 50.px
						borderRadius = if (isTaoEnabled()) {
							0.px
						} else 8.px
						marginRight = 10.px
						aspectRatio = "115/81".unsafeCast<AspectRatio>()
						if (!it.isSelected) opacity = number(0.3)
					}
					src = getImageFromPayment(it.payment)

				}
			} else img {
				css {
					width = 50.px
					borderRadius = if (isTaoEnabled()) {
						0.px
					} else 8.px
					marginRight = 10.px
					aspectRatio = "115/81".unsafeCast<AspectRatio>()
					if (!it.isSelected) opacity = number(0.3)
				}
				src = getImageFromPayment(it.payment)

			}


			Typography {
				variant = TypographyVariant.body1
				sx {
					fontWeight = integer(500)
					flexGrow = number(1.0)
				}
				if (loading) {
					Skeleton {
						variant = SkeletonVariant.text
					}
				} else
					+it.payment.label
			}



			if (it.showDelete == true && it.payment.type == "CC") {

				deletePaymentButton {
					payment = it.payment
					disable = { disable ->
						loading = disable
					}
				}
			}
			if (it.isSelected) {
				CheckCircle {
					color = SvgIconColor.success
				}
			} else {
				RadioButtonUnchecked {

				}
			}
		}
	}
}




