package components.utils

import csstype.ClassName
import react.FC
import react.Props
import react.dom.html.ReactHTML.div

val orbit = FC<Props> {
	div {
		className = ClassName("orbit-outter")
	}
	div {
		className = ClassName("orbit-inner")
	}
}
