package api.hooks

import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import mainScope
import react.useEffectOnce

fun useServiceWorker(serviceWorkerScriptUrl: String = "/serviceWorker.js") {


	suspend fun loadServiceWorkerState()  {
		try {
			window.navigator.serviceWorker.register(serviceWorkerScriptUrl).await()
		} catch (e: Exception) {

		}
	}
	useEffectOnce {
		mainScope.launch {
			loadServiceWorkerState()
		}
	}
}
