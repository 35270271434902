package components.modals

import co.rooam.theme.ThemesProvider
import csstype.ClassName
import csstype.NamedColor
import csstype.Padding
import csstype.TextAlign
import csstype.pct
import csstype.px
import emotion.react.css
import kotlinx.js.jso
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.Drawer
import mui.material.DrawerAnchor
import mui.material.styles.ThemeProvider
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.p


external interface AlertModalProps : Props {
	var alertShow: Boolean
	var text: String
	var title: String
	var closeAlert: () -> Unit
}

val AlertModal = FC<AlertModalProps>("alertModal") { props ->
	ThemeProvider {
		theme = ThemesProvider.dialogTheme

		Drawer {
			open = props.alertShow
			onClose = { _, _ -> props.closeAlert() }
			anchor = DrawerAnchor.bottom
			PaperProps = jso {
				sx {
					borderTopRightRadius = 10.px
					borderTopLeftRadius = 10.px
					textAlign = TextAlign.center
					color = NamedColor.black
				}
			}


			Box {
				sx {
					padding = Padding(38.px, 28.px, 34.px)
				}
				DialogTitle {
					h2 {
						css(ClassName("no-top-bottom")) {
							color = NamedColor.black
						}
						+props.title
					}
				}
				DialogContent {
					p {
						css(ClassName("text-grey")) {
						}
						+props.text
					}
				}
				DialogActions {

					Button {
						sx {
							width = 100.pct
							minHeight = 48.px
							maxHeight = 72.px
						}
						variant = ButtonVariant.outlined
						onClick = { props.closeAlert() }
						+"Ok"
					}
				}
			}
		}
	}
}
