package api

val hostPath = js("HOST")
val hostProtocol = js("HOSTPROTOCOL")
val hostPort = js("PORT")


val googlePayEnvironment: String = js("GOOGLE_PAY_ENVIRONMENT")
val googlePayMerchantID: String = js("GOOGLE_PAY_MERCHANT_ID")
val googleAnalytics : String= js("GOOGLE_ANALYTICS")
val version : String= js("VERSION")
val environment : String= js("ENVIRONMENT")
val traceRate : Double= js("TRACE")

val incodeClientId: String = js("INCODE_CLIENT_ID")
val incodeApiKey: String = js("INCODE_API_KEY")
val incodeApiURL: String = js("INCODE_API_URL")
val incodeFlowId: String = js("INCODE_FLOW_ID")
