package components.marketing

import api.getBannerForLocationIfNeeded
import components.marketing.model.BannerLink
import components.marketing.model.BannerTheme
import components.marketing.model.BigBanner
import csstype.ClassName
import csstype.Display
import csstype.None
import kotlinx.coroutines.launch
import kotlinx.js.jso
import mainScope
import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.router.useLocation
import react.useEffectOnce
import react.useState

val bigBanner = FC<Props> { props ->
	var show by useState(false)
	var banner by useState(
		BigBanner("BANNER_BIG",
			"", "", "",
			theme = BannerTheme("", ""),
			action = BannerLink("", url = "", target = ""),
			campaignId = ""
		)
	)
	val location = useLocation()
	useEffectOnce {
		mainScope.launch {
			getBannerForLocationIfNeeded<BigBanner>(
				location,
				onSuccess = {
					banner = it
					show = true
				},
				onFailure = {
					console.log(it)
				}
			)
		}
	}
	kotlinext.js.require("./styles/marketing/banner.css")
	div {
		id = "banner-container"
		className = ClassName("section no-bottom")
		style = jso {
			display = if (show) Display.block else None.none
		}
		div {
			className = ClassName("container text-center")
			div {
				className = ClassName("section big-notice no-bottom")
				div {
					className = ClassName("box-wrap")
					div {
						className = ClassName("img-wrap")
						img {
							src = banner.imageUrl
						}
					}
					div {
						className = ClassName("padding")
						h3 {
							className = ClassName("no-top-bottom")
							+banner.title
						}
						p {
							className = ClassName("text-light-grey no-bottom")
							+banner.text
						}
					}
					div {
						className = ClassName("padding no-top")
						a {
							className = ClassName("btn white")
							href = banner.action.url
							target = AnchorTarget._blank
							+banner.action.title
						}
					}
				}
			}
		}
	}
}
