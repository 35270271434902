package components.modals

import co.rooam.theme.ThemesProvider
import csstype.pct
import csstype.vh
import emotion.react.css
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.Size
import mui.material.styles.ThemeProvider
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.iframe


external interface IframeModalProps : Props {
	var iframeAddress: String
	var booleanShowModal: Boolean?
	var cancelActionPressed: () -> Unit
}

val IframeModal = FC<IframeModalProps>("iframeModal") { props ->
	ThemeProvider {
		theme = ThemesProvider.dialogTheme
		Dialog {
			fullScreen = true
			open = props.booleanShowModal!!
			onClose = { _, _ -> props.cancelActionPressed() }

			DialogContent {
				sx {
					width = 100.pct
					height = 75.vh

				}
				iframe {
					src = props.iframeAddress
					css {
						width = 100.pct
						height = 100.pct
					}
					+"""Loading…"""
				}
			}
			DialogActions {
				Button {
					fullWidth = true
					size = Size.large
					onClick = { props.cancelActionPressed() }
					color = ButtonColor.primary
					variant = ButtonVariant.outlined
					+"Go Back"

				}
			}
		}


	}
}


