package pages.tab

import api.ActionMessage
import api.closeTab
import api.log
import components.divs.SectionNoTopBottom
import components.divs.container
import components.identity.verification.IdentityVerificationProfileBanner
import components.location
import components.marketing.smallBanner
import components.modals.ConfirmModal
import components.rooamFooter
import components.utils.orbit
import csstype.ClassName
import csstype.Display.Companion.block
import csstype.None
import csstype.px
import kotlinx.js.jso
import locationBase
import react.CSSProperties
import react.FC
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.useNavigate
import react.router.useParams
import react.useState


val tabEmpty = FC<TabEmptyProps> { props ->
	val history = useNavigate()
	val codeProps = useParams()
	val (closing, setClosing) = useState(false)
	val (modalShow, setShowModal) = useState(false)
	val (error, setError) = useState(false)
	SectionNoTopBottom {
		ConfirmModal {
			showIcon = true
			title = "Are you sure?"
			text = "No items have been added to your tab. Please confirm you would like to close your tab."
			booleanShowModal = modalShow
			this.cancelActionPressed = {
				setShowModal(false)

			}
			this.confirmActionPressed = {
				setClosing(true)
				setShowModal(false)
				setError(false)

				closeTab(codeProps["code"], 20 to "0.00", null, null, {
					setError(true)
					setClosing(false)
					api.error(ActionMessage("voiding_tab", "Tab voiding failed"))
				}, { history("${locationBase(codeProps)}/") }) {
					log(ActionMessage("voiding_tab", "Tab voided"))
					history("${locationBase(codeProps)}/voided")
				}
			}

		}
		location {
			showBack = false
		}
		IdentityVerificationProfileBanner {}
		smallBanner {
		}
		div {
			className = ClassName("section largest")
			div {
				className = ClassName("container text-center")
				div {
					className = ClassName("orbit-box small green")
					orbit()
					div {
						className = ClassName("icon")
						svg {
							className = ClassName("svg-icon")
							viewBox = "0 0 29.371 22.736"
							path {
								d = "M15.772,29.736,5.541,19.917,8.71,16.654l7.005,6.678L31.679,7l3.233,3.2Z"
								transform = "translate(-5.541 -7)"


								fill = "#ffffff"


							}
						}
					}
				}
				h1 {
					className = ClassName("text-green no-top-bottom")
					+"Your Tab is Open!"
				}
				p {
					className = ClassName("text-white float-middle")


					style = jso<CSSProperties>().apply {
						maxWidth = 280.px
					}
					+"Show the tab number below and order with your bartender or server:"
				}
				if (props.tabNumber != null) {
					p {
						className = ClassName("huge text-white no-bottom")
						+props.tabNumber!!
					}
				} else {
					p {
						className = ClassName("huge text-white no-bottom flashing")
						+"Loading"
					}
				}
			}
		}
		div {
			className = ClassName("section less no-bottom")


			style= jso {
				display = if (error) block else None.none
			}
			container {
				div {
					className = ClassName("message error")
					div {
						className = ClassName("message-wrap")
						span {
							className = ClassName("icon")
							svg {
								className = ClassName("svg-icon")
								viewBox = "0 0 24 24"
								path {
									d =
										"M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"



									fill = "#ec0b19"


								}
							}
						}
						p {
							className = ClassName("small text-white no-top-bottom")
							span {
								className = ClassName("font-weight-600")
								+"Issue Closing Tab."
							}
							+" Please try again."
						}
					}
				}
			}
		}
		SectionNoTopBottom{
			div {
				className = ClassName("container text-center")
				hr {}
				div {
					className = ClassName("section less no-bottom")
					a {
						href = "${locationBase(codeProps)}/voided"
						className = ClassName("btn outline ${if (closing || modalShow) "disabled" else "white"}")

						onClick = {
							it.preventDefault()
							setShowModal(true)
						}
						+if (closing) "Closing Tab..." else "Close Tab"
					}
				}
			}
		}
		rooamFooter { }
	}
}
