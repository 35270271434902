package imported.googlepay

import kotlinx.js.jso

fun GooglePayClient(env: String): GooglePayClient = PaymentsClient(jso { environment = env })


external interface IsReadyToPayResponse {
	var result: Boolean?
	var paymentMethodPresent: Boolean?
}

external interface GoogleIsReadyToPayParams {
	var apiVersion: Int
	var apiVersionMinor: Int
	var allowedPaymentMethods: dynamic
	var existingPaymentMethodRequired: Boolean?

}
