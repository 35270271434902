package api

import kotlinx.serialization.Serializable


fun log(actionMessage: ActionMessage) = console.log(actionMessage)


fun error(actionMessage: ActionMessage) = console.error(actionMessage)


@Serializable
data class ActionMessage(val action: String, val message: String)
