package components

import api.VenueDetails
import components.utils.orbit
import csstype.AlignItems
import csstype.Border
import csstype.ClassName
import csstype.Color
import csstype.Display
import csstype.FlexDirection
import csstype.JustifyContent.Companion.center
import csstype.LineStyle
import csstype.pct
import csstype.px
import csstype.rem
import csstype.vh
import csstype.vw
import emotion.react.css
import kotlinx.browser.sessionStorage
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import mui.icons.material.MoreHoriz
import mui.material.Avatar
import mui.material.SvgIconClasses
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.material.styles.useTheme
import mui.system.sx
import org.w3c.dom.get
import react.FC
import react.Props
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.useParams

val fullPageLoading = VFC {
	val codeProps = useParams()
	val venueDetails = sessionStorage["VENUE-${codeProps["code"]}"]?.let { Json.decodeFromString<VenueDetails>(it) }
	val theme = useTheme<Theme>()
	div {
		css {
			backgroundColor = Color(theme.palette.background.default)
			width = 100.vw
			height = 100.vh
			color = Color("#c9c9c9")
			display = Display.inlineFlex
			alignItems = AlignItems.center
			justifyContent = center
			flexDirection= FlexDirection.column
		}
		Avatar{
			sx {
				borderRadius= 100.pct
				backgroundColor = Color("hsl(0, 0%, 24%)")
				border = Border(4.px, LineStyle.solid, Color("hsl(0, 0%, 19%)"))
				margin = 8.px;
				padding = 8.px;
			}
			span{
			css(ClassName("flashing")){}
				MoreHoriz{
				sx{
					color = Color("#939597")
					width= 16.px
					height= 16.px

				}

			}
			}
		}
		p {
			className = ClassName("text-grey")
			+"Loading ${venueDetails?.name} - Web Tab..."
		}
	}
}

