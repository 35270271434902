package api.pour

import api.addSessionHeader
import api.client
import api.storage.storeRooamHeader
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import mainScope
import kotlin.js.Promise


fun pourDrink(
	pourInfo: PourInfo,
): Promise<PourResponse> {
	return mainScope.async{
		val pour = client.post {
		url(path = "tab/pour")
		header("Content-Type", ContentType("application", "json"))
		addSessionHeader()
		setBody(
			pourInfo
		)
		expectSuccess = true
	}.also { it.storeRooamHeader() }
		pour.body<PourResponse>()
	}.asPromise()



}

@kotlinx.serialization.Serializable
data class PourInfo(
	val barId: String,
	val tapId: String
)

@kotlinx.serialization.Serializable
data class PourResponse(
	val success: Boolean?,
	val errorMessage: String?
)
