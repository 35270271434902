package api

import api.storage.storeRooamHeader
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.async
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.serialization.Serializable
import mainScope

val sendReceipt = { email: String, resultFun: (MessageSendState) -> Unit ->
	mainScope.async {
		val post: HttpResponse =
			client.post(
				"/receipt",
			) {
				setBody(EmailRequest(email))
				addSessionHeader()
				header("Content-Type", ContentType("application", "json"))
				expectSuccess = false
			}.also { it.storeRooamHeader() }
		if (post.status == HttpStatusCode.OK) {
			resultFun(MessageSendState.SUCCESS)
		} else {
			resultFun(MessageSendState.ERROR)
		}
	}

}


private suspend fun getSingleReceipt() = client.get("/receipt") {
	header("Content-Type", ContentType("application", "json"))
	addSessionStaticHeader()
	expectSuccess = false
}

suspend fun getSingleReceiptThrowing() = try {
	client.get("/receipt") {
		header("Content-Type", ContentType("application", "json"))
		addSessionStaticHeader()
		expectSuccess = true
	}.body<HistoryData>()
} catch (ex: Throwable) {
	console.log(ex)
	throw ex
}

@Serializable
data class EmailRequest(val email: String)


@Serializable
data class HistoryData(
	val receipt: Receipt,
	val place: PlaceShort,
	val items: List<TabItem>
)


@Serializable
data class PlaceShort(
	val name: String
)


@Serializable
data class Receipt(
	val total: String,
	val subTotal: String,
	val fee: String?,
	val tip: String,
	val tax: String?,
	val ticketNumber: String,
	val serviceCharges: String?,
	val partialPayments: String?,
	val discounts: String?,
	val totalWithCredit: String?,
	val creditApplied: String?
)
