package pages

import api.addPaymentFunction
import api.attachBrainTreeFields
import apple.applyApplePay
import components.account.termsAndConditions
import components.apple.applePayButton
import components.divs.SectionNoTopBottom
import components.divs.container
import components.error.StandardError
import components.google.googlePayButton
import components.help.help
import components.layout
import components.location
import components.marketing.smallBanner
import components.rooamFooter
import components.seo
import csstype.ClassName
import csstype.Display
import csstype.None
import getBraintreeClient
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.js.jso
import mainScope
import react.FC
import react.Props
import react.dom.aria.AriaRole
import react.dom.aria.ariaSelected
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.router.NavigateFunction
import react.router.useNavigate
import react.useEffect
import react.useEffectOnce
import react.useState

external interface AddPaymentProps : Props {
	var navigate: (NavigateFunction) -> Unit
}

val addPayment = FC<AddPaymentProps> { props ->
	val history = useNavigate()
	val braintreeClient = getBraintreeClient()

	val (isLoading, setLoading) = useState(false)
	val (showError, setShowError) = useState(false)
	useEffect(braintreeClient.data) {
		if (braintreeClient.data != null) {
			mainScope.launch {
				attachBrainTreeFields(braintreeClient.data!!).await()
			}
		}
	}
	layout {
		seo {
			titleTemplate = "Complete Your Account - %s"
		}
		SectionNoTopBottom {
			location {
			}
			smallBanner {
			}
			div {
				className = ClassName("section no-bottom")
				container {
					h1 {
						className = ClassName("no-top-bottom")
						+"Connect a Payment."
					}
				}
			}
			div {
				className = ClassName("section less")
				container {

					div {
						className = ClassName("section less no-top")

						style = jso {

							display = None.none
						}
						container {
							div {
								className = ClassName("message error")
								div {
									className = ClassName("message-wrap")
									StandardError {}
								}
							}
						}
					}

					div {
						className = ClassName("row clearfix")
						applePayButton {
							appleBehavior =
								applyApplePay({ }, null, { _, _ -> mainScope.async { } }, { setLoading(it) }) {
									props.navigate(history)
								}

						}
						div {
							className = ClassName("column")
							div {
								className = ClassName("padding")
								googlePayButton {
									this.setLoading = { setLoading(it) }
									this.navigate = props.navigate
								}

							}
						}
					}
				}
			}
		}
		div {
			className = ClassName("section less no-top")
			container {
				div {
					className = ClassName("divider text-center")
					span {
						+"Or pay with card"
					}
				}
			}
		}
		form {
			div {
				className = ClassName("section input-group three short no-top")
				container {
					label {
						className = ClassName("no-top")
						htmlFor = "card-information"
						+"Card Information"
					}
					div {
						className = ClassName("row clearfix")
						div {
							className = ClassName("column full")
							div {
								className = ClassName("form-control frame")

								placeholder = "Card Number"
								id = "card-number"
								role = AriaRole.option
								ariaSelected = true
								tabIndex = 0

							}
						}
						div {
							className = ClassName("column")
							div {
								className = ClassName("form-control frame")
								placeholder = "Expires"
								id = "card-expiry"
								role = AriaRole.option
								ariaSelected = true
								tabIndex = 0

							}
						}
						div {
							className = ClassName("column")
							div {
								className = ClassName("form-control frame")
								placeholder = "CVV"
								id = "card-cvv"
								role = AriaRole.option
								ariaSelected = true
								tabIndex = 0

							}
						}
					}
				}
			}
			SectionNoTopBottom {
				container {
					div {
						className = ClassName("row clearfix")
						div {
							className = ClassName("column full")
							div {
								className = ClassName("form-control frame")
								placeholder = "Zip Code"
								id = "card-zip"
								role = AriaRole.option
								ariaSelected = true
								tabIndex = 0

							}
						}
					}
					div {
						className = ClassName("error-message")
						style = jso {

							display = if (showError) Display.block else None.none
						}
						p {
							className = ClassName("small text-red no-top-bottom")
							+"Your card was declined. Please try a different card."
						}
					}
				}
			}
			div {
				className = ClassName("section less no-bottom")
				container {
					button {
						className = ClassName("btn red ${if (isLoading) "disabled" else ""}")

						onClick = {
							addPaymentFunction(it.nativeEvent, {

								setShowError(true)
								setLoading(false)
							}) {
								props.navigate(history)
							}
						}

						+if (isLoading) "Please Wait..." else "Connect"
					}
				}
			}
		}
		termsAndConditions {}
		help { isLess = false }
		rooamFooter { }
	}
}

