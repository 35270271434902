package co.rooam.components.receipt

import csstype.Display
import csstype.JustifyContent
import csstype.TransitionProperty
import csstype.deg
import csstype.integer
import csstype.px
import csstype.rem
import csstype.rotate
import emotion.react.css
import mui.icons.material.ArrowDropDownCircle
import mui.material.Box
import mui.material.Collapse
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.PropsWithSx
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.span
import react.useState

external interface ReceiptLineProps : PropsWithChildren, PropsWithSx {
	var title: String
	var id: String
	var value: String
	var positive: Boolean?
	var greenText: String?
	var expanded: Boolean?
	var noDollar: Boolean?
}

val ReceiptLine = FC<ReceiptLineProps>("ReceiptLine") { props ->
	val theme = useTheme<Theme>()
	var expandChilden by useState(props.expanded ?: false)
	Box {
		sx {
			display = Display.flex
			justifyContent = JustifyContent.spaceBetween
			fontSize = 0.85.rem
		}
		if (props.children != null) {
			onClick = { expandChilden = !expandChilden }
		}
		Typography {
			if (props.sx != null) sx = props.sx else
				sx {
					fontWeight = integer(500)
					fontSize = 0.85.rem
					color = theme.palette.text.secondary
				}

			+props.title
			if (props.positive == true) {
				span {
					css {
						color = theme.palette.success.main
					}
					+"${props.greenText}"
				}
			}
			if (props.children != null) {
				ArrowDropDownCircle {
					sx {
						if (expandChilden) transform = rotate(90.deg) else transform = rotate(0.deg)
						transitionProperty = TransitionProperty.all
						fontSize = 0.85.rem
						marginLeft = 7.px
					}
				}
			}
		}
		Typography {
			if (props.sx != null) sx = props.sx else
				sx {
					fontWeight = integer(500)
					fontSize = 0.85.rem
					if (props.positive == true) color = theme.palette.success.main else color =
						theme.palette.text.secondary

				}
			+"${if (props.noDollar == true) "" else "\$"}${props.value}"
		}
	}

	if (props.children != null) {
		Collapse {
			`in` = expandChilden
			+props.children
		}
	}

}
