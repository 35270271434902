package components

import api.VenueDetails
import imported.helmet.helmet
import kotlinx.browser.sessionStorage
import kotlinx.serialization.decodeFromString
import org.w3c.dom.get
import react.FC
import react.Props
import react.router.useParams
import kotlin.js.Json
import kotlin.js.json

external interface SeoProps : Props {
	var description: String
	var lang: Any?
	var meta: List<Json>?
	var title: String?
	var titleTemplate: String?
}

val seo = FC<SeoProps>("seo") { props ->
	val codeProps = useParams()
	val get = sessionStorage["VENUE-${codeProps["code"]}"]
	val venueDetails = if (get != null) {
		kotlinx.serialization.json.Json.decodeFromString(get)
	} else
		VenueDetails("Rooam Tab", "", "", "Loading")

	helmet {

		htmlAttributes = json("lang" to (props.lang ?: "en"))
		title = props.title ?: venueDetails.name
		titleTemplate = props.titleTemplate ?: "%s"
		meta = (props.meta?.toTypedArray() ?: arrayOf())

	}
}
