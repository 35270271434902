package components.identity.verification

import csstype.*
import emotion.react.css
import kotlinx.js.jso
import mui.material.*
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p

@JsNonModule
@JsModule("./images/identity-verification-failed.png")
external val idCheckFailedImage: dynamic

external interface IdentityVerificationFailureProps : Props {
	var show: Boolean
	var verifyIdentityPressed: () -> Unit
	var logoutPressed: () -> Unit
}

val IdentityVerificationFailure = FC<IdentityVerificationFailureProps>("identityVerificationFailure") { props ->
	div {
		className = ClassName("section no-top-bottom")
		div {
			id = "identity-verification-failure-container"
			className = ClassName("container text-center padding")
			style = jso {
				display = if (props.show) Display.block else None.none
			}
			IdentityVerificationFailureTitle {}

			div {
				style = jso {
					paddingTop = 30.px
				}
				img {
					css {
						borderRadius = 8.px
					}
					alt = "Identity Verification Failed image"
					src = idCheckFailedImage
				}
			}
			p {
				className = ClassName("float-middle")
				style = jso {
					maxWidth = 380.px
					paddingTop = 30.px
				}
				ReactHTML.span {
					className = ClassName("text-white font-weight-600")
					+"Could not verify your identity "
				}
				+" — To be able to open a Tab at this place you need to verify your identity."
			}

			div {
				div {
					style = jso {
						paddingTop = 30.px
					}
					ReactHTML.button {
						className = ClassName("btn white")
						onClick = {
							it.preventDefault()
							props.verifyIdentityPressed()
						}
						+"Verify Identity"
					}
				}
				div {
					style = jso {
						paddingTop = 15.px
					}
					ReactHTML.button {
						className = ClassName("btn no-animation small outline white")
						onClick = {
							it.preventDefault()
							props.logoutPressed()
						}
						+"Log Out"
					}
				}
			}
		}
	}
}

val IdentityVerificationFailureTitle = FC<Props> {
	div {
		className = ClassName("container text-center")
		h1 {
			className = ClassName("big float-middle no-bottom")
			style = jso {
				maxWidth = 420.px
			}
			+"Identity Verification failed!"
		}
	}
}

