package google

import api.ActionMessage
import api.collectDeviceData
import api.error
import api.googlePayEnvironment
import api.googlePayMerchantID
import api.postPayment
import imported.googlepay.GooglePayClient
import imported.braintree.google.createGooglePay
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.js.jso
import mainScope
import suspendedBrainTreeClient


fun prepareGooglePay(
	totalPay: String?,
	storeName: suspend (String) -> Unit,
	loading: (Boolean) -> Unit,
	navigation: (String) -> Unit
) =
	mainScope.async {
		console.log("Starting prepare google pay")
		val brainTreeClient = suspendedBrainTreeClient.await()

		val googlePaymentInstance = createGooglePay(jso {
			this.googlePayVersion = 2
			this.client = brainTreeClient
			this.googleMerchantId = googlePayMerchantID
		}).await()

		val googleClient = GooglePayClient(googlePayEnvironment)
		val payResponse = googleClient.isReadyToPay(jso {
			apiVersion = 2
			apiVersionMinor = 0
			allowedPaymentMethods = googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods
			existingPaymentMethodRequired = true
		}).await()
		if (payResponse.result == true) {
			return@async {
				mainScope.async {
					loading(true)

					val createPaymentDataRequest =
						googlePaymentInstance.createPaymentDataRequest(jso {
							transactionInfo = jso {

								totalPriceStatus = if (totalPay != null) "FINAL" else "NOT_CURRENTLY_KNOWN"
								if (totalPay != null) {
									totalPrice = totalPay
								}
								currencyCode = "USD"
							}
						})

					//JS DIRECT
					val cardPaymentMethod = createPaymentDataRequest.allowedPaymentMethods[0]
					cardPaymentMethod.parameters.billingAddressRequired = true
					cardPaymentMethod.parameters.billingAddressParameters = jso { format = "FULL" }

					val unparsedPaymentData = googleClient.loadPaymentData(createPaymentDataRequest).then(
						{ it }
					) {
						error(ActionMessage("google_pay", "Google pay failed: $it"))
						loading(false)
					}.await()
					val deviceData = collectDeviceData((brainTreeClient))
						.await()
						.deviceData
					val parsedPaymentData = googlePaymentInstance.parseResponse(unparsedPaymentData).await()
					try {
						storeName(unparsedPaymentData.asDynamic().paymentMethodData.info.billingAddress.name)
						if (totalPay == null) {
							if (!postPayment(parsedPaymentData.nonce, deviceData, "GOOGLE_PAY").await()) {
								throw RuntimeException("Payment not added")
							}
						}
						//this is success scenario during refactor change
						navigation(parsedPaymentData.nonce)
					} catch (e: Throwable) {
						loading(false)
					}
				}
			}
		} else
			return@async {}

	}




