package imported.incode

import api.*
import components.updateSentryUser
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.js.jso
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.HTMLElement
import org.w3c.dom.mediacapture.MediaStream
import kotlin.js.Promise
import kotlin.time.Duration.Companion.seconds

object Incode {

	suspend fun <T : IdentityVerificationStatus<*>> startIdentityVerification(containerHTMLElementId: String): Flow<T> {
		updateSentryUser()
		val container = kotlin.runCatching { document.getElementById(containerHTMLElementId) as HTMLElement }
			.getOrElse {
				return flowOf(GenericFailure(it) as T)
			}
		return startIdentityVerification(container)
	}

	suspend fun <T : IdentityVerificationStatus<*>> startIdentityVerification(container: HTMLElement): Flow<T> {
		val instance: IncodeInstance = createInstance().getOrElse {
			console.error("Error getting Incode instance", it)
			return flowOf(GenericFailure(it) as T)
		}
		return instance.startIdentityVerification(container)
	}

	private fun createInstance(): Result<IncodeInstance> = runCatching {
		val options = jso<IncodeInstanceOptions> {
			clientId = incodeClientId
			apiKey = incodeApiKey
			apiURL = incodeApiURL
			lang = "en"
		}
		createIncodeInstance(options)
	}
}

private suspend fun <T : IdentityVerificationStatus<*>> IncodeInstance.startIdentityVerification(container: HTMLElement): Flow<T> {
	var insance = this
	return flow {
		emit(Loading(true) as T)

		var session: IncodeSession? = null

		try {
			session = createSession("ALL", null, jso { configurationId = incodeFlowId }).await()
			session.setCurrentUserIdentityVerificationState(IdentityVerificationState.VERIFYING, insance)

			emit(Loading(false) as T)

			val frontId = captureImage<Any>("front", -1, session, container).await()
			val backId = captureImage<Any>("back", -1, session, container).await()
			emit(Loading(true) as T)
			val processId = processId(session).await()
			emit(Loading(false) as T)
			val selfie = captureImage<IncodeRenderCameraSelfieResponse>("selfie", -1, session, container).await()
			val faceMatches = checkFaceMatches(selfie.liveness, selfie.existingUser, session, container).await()
			emit(Loading(true) as T)
			val retry = retryStepsIfNeeded(3, session, container).await()
			emit(Loading(true) as T)
			// was recommended by Incode team to add a small delay
			delay(3.seconds)
			val identity = checkIdentity(session).await()

			session.setCurrentUserIdentityVerificationState(IdentityVerificationState.VERIFIED, insance)

			emit(VerificationState(IdentityVerificationState.VERIFIED) as T)

			emit(Loading(false) as T)
		} catch (exception: Exception) {
			console.error("Error verifying user identity! Session Id: ${session?.interviewId ?: "<Unknown>"}", exception)
			emit(Loading(false) as T)
			when (exception) {
				is NotValidIdentityException -> {
					session?.setCurrentUserIdentityVerificationState(IdentityVerificationState.VERIFIED_INVALID, insance)
					emit(VerificationState(IdentityVerificationState.VERIFIED_INVALID) as T)
				}

				else -> emit(GenericFailure(exception) as T)
			}
		}
	}
}

interface IdentityVerificationStatus<T : Any> {
	val value: T
}

data class Loading(override val value: Boolean) : IdentityVerificationStatus<Boolean>
data class VerificationState(override val value: IdentityVerificationState) :
	IdentityVerificationStatus<IdentityVerificationState>

data class GenericFailure(override val value: Throwable) : IdentityVerificationStatus<Throwable>


private suspend fun IncodeSession.setCurrentUserIdentityVerificationState(
	state: IdentityVerificationState,
	incodeInstance: IncodeInstance
) {
	val sessionToken = token
	console.log(sessionToken)
	val identityData = incodeInstance.ocrData(jso { this.token = sessionToken }).await()
	val images = incodeInstance.getSelfieImage(this).await()
	val result = updateIdentityProfile(this.interviewId, state, identityData, images.croppedFace)
	console.log(result)
}

private fun <T> IncodeInstance.captureImage(
	type: String,
	numberOfTries: Int = -1,
	session: IncodeSession,
	container: HTMLElement
): Promise<T> {
	return Promise { resolve, reject ->
		val options = jso<IncodeRenderCameraOptions>() {
			this.onSuccess = { resolve(it as T) }
			this.onError = { error ->
				console.error("captureImage() Issue capturing $type Id", error)
				reject(Throwable("Issue capturing $type Id"))
			}
			this.onLog = {}
			this.token = session
			this.numberOfTries = numberOfTries
			this.nativeCamera = true
			this.showTutorial = true
		}
		this.renderCamera(type, container, options)
	}
}

private fun IncodeInstance.processId(session: IncodeSession): Promise<Any> {
	return Promise { resolve, reject ->
		val options = jso<IncodeProcessIdOptions>() {
			token = session.token
		}
		this.processId(options)
			.then { resolve(it) }
			.catch { reject(it) }
	}
}

private fun IncodeInstance.checkFaceMatches(
	liveness: Boolean,
	userExists: Boolean,
	session: IncodeSession,
	container: HTMLElement
): Promise<Unit> {
	return Promise { resolve, reject ->
		val options = jso<IncodeRenderFaceMatchOptions>() {
			this.onSuccess = { resolve(Unit) }
			this.onError = { error ->
				console.error("checkFaceMatches() Issue checking face match", error)
				reject(Throwable("Issue checking face match"))
			}
			this.token = session
			this.liveness = liveness
			this.userExists = userExists
		}
		this.renderFaceMatch(container, options)
	}
}

private fun IncodeInstance.checkIdentity(session: IncodeSession): Promise<Unit> {
	return Promise { resolve, reject ->
		val options = jso<IncodeProcessIdOptions>() {
			token = session.token
		}
		this.getScore(options).then {
			if (it.overall.status == "OK") {
				resolve(Unit)
			} else {
				console.error("checkIdentity() failure, overall status not OK, reason: ${it.reasonMsg}, ${it.overall.value}")
				reject(NotValidIdentityException(it.reasonMsg))
			}
		}.catch { reject(it) }
	}
}

private fun IncodeInstance.getSelfieImage(session: IncodeSession): Promise<IncodeImages> {
	return Promise { resolve, reject ->
		val options = jso<IncodeGetImagesOptions> {
			token = session.token
			body = jso {
				images = arrayOf("croppedFace")
			}
		}
		getImages(options)
			.then {
				resolve(it)
			}
			.catch {
				console.error("getSelfieImage() Failure getting images", it)
				reject(it)
			}
	}
}

private fun IncodeInstance.retryStepsIfNeeded(
	numberOfTries: Int = -1,
	session: IncodeSession,
	container: HTMLElement
): Promise<Unit> {
	return Promise { resolve, reject ->
		val options = jso<IncodeRenderRetryStepsOptions>() {
			this.token = session
			this.numberOfTries = numberOfTries
		}
		val completionOptions = jso<IncodeRenderRetryStepsCompletionOptions> {
			this.onSuccess = {
				resolve(Unit)
			}
			this.onError = { error ->
				console.error("retryStepsIfNeeded() Issue retrying steps", error)
				reject(Throwable("Issue retrying steps"))
			}
		}
		this.renderRetrySteps(container, options, completionOptions)
	}
}

class NotValidIdentityException(message: String, cause: Throwable? = null) : Exception(message, cause)

private fun IncodeInstance.requestCameraPermissions(): Promise<MediaStream> =
	window.navigator.mediaDevices.getUserMedia(jso { video = true })
