package pages

import api.storage.StorageKeys
import components.divs.SectionNoTopBottom
import components.divs.container
import components.divs.containerTextCenter
import components.help.help
import components.icons.infoIcon
import components.installationComponent
import components.layout
import components.location
import components.marketing.smallBanner
import components.phoneInput
import components.rooamFooter
import components.seo
import csstype.ClassName
import csstype.Display
import csstype.None
import csstype.px
import emotion.css.cx
import emotion.react.css
import getCode
import imported.numberformat.Values
import kotlinx.browser.sessionStorage
import kotlinx.js.jso
import locationBase
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.router.dom.Link
import react.router.useNavigate
import react.router.useParams
import react.useState

external interface LoginPhoneProps : PropsWithChildren

val loginPhone = FC<LoginPhoneProps> {
	val history = useNavigate()
	val location = useParams()
	val (phoneValues, setPhoneValues) = useState<Values?>(null)
	val (isPhoneValid, setPhoneValid) = useState(false)
	val (showErrorMessage, setShowErrorMessage) = useState(false)
	val (errorMessage, setErrorMessage) = useState("Please enter a valid phone number")
	val (loadingMessage, setLoading) = useState(false)
	layout {
		seo {

			titleTemplate = "%s - Pay Your Tab From Your Phone"
		}
		SectionNoTopBottom {
			location {
				isLong = true
				showMenu = false
				showBack = false
			}
			smallBanner {
			}
			div {
				className = ClassName("section no-bottom")
				container {
					h1 {
						className = ClassName("no-top-bottom")
						+"What's Your Number?"
					}
					p {
						className = ClassName("no-bottom")
						+"We’ll text a code to verify your phone."
					}
				}
			}
			div {
				className = ClassName("section less")
				container {
					form {
						action = "${locationBase(location)}/verify"
						method = "get"
						id = "form-phone-number"
						div {
							className = ClassName("row clearfix")
							div {
								className = ClassName("column full")
								phoneInput {

									showError = showErrorMessage
									onValueChange = {
										setPhoneValues(it)
										if (it.value.length == 10) {
											setPhoneValid(true)
										} else {
											setPhoneValid(false)
										}
									}

								}
							}
							div {
								className = ClassName("error-message")
								style = jso {
									this.display = if (showErrorMessage) Display.block else None.none

								}
								p {
									className = ClassName("small text-red no-top-bottom")
									+"$errorMessage."
								}
							}
						}
						div {
							className = ClassName("link-box no-bottom")
							button {
								className = ClassName("btn red")
								if (!isPhoneValid or loadingMessage) {
									className = cx(className, ClassName(" disabled"))
								}
								onClick = {
									it.preventDefault()
									setLoading(true)
									sessionStorage.setItem(
										StorageKeys.formattedPhone,
										phoneValues?.formattedValue ?: ""
									)

									sessionStorage.setItem(
										StorageKeys.phone,
										phoneValues?.value ?: ""
									)
									phoneValues?.value?.let { phoneValue ->
										getCode(phoneValue, history) {
											it?.let { setErrorMessage(it) }
											setPhoneValid(false)
											setShowErrorMessage(true)
											setLoading(false)
										}
									}
								}

								+if (loadingMessage) "Loading..." else "Next"
							}
						}
					}
				}
			}

			div {
				className = ClassName("section less no-bottom")

				containerTextCenter {
					div {
						className = ClassName("icon hero")
						infoIcon {}
					}
					p {
						className = ClassName("float-middle no-top-bottom")
						style = jso {
							maxWidth = 380.px
						}
						span {
							className = ClassName("text-white font-weight-600")
							+"Not sure what this is? "
						}
						+" — Head to our explainer page to find out what a web tab link is."
					}
					div {
						css(ClassName("section"), ClassName("less")) {}
						Link {
							className = ClassName("btn block")
							to = "../explainer"
							+"Learn More"
						}
					}

				}
			}


			help { isLess = true }

			installationComponent {}
			rooamFooter { }
		}
	}
}
