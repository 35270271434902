package pages.complete

import api.MessageSendState
import api.sendReceipt
import components.divs.SectionNoTopBottom
import components.divs.container
import components.icons.successIcon
import components.installationComponent
import components.layout
import components.location
import components.marketing.bigBanner
import components.marketing.smallBanner
import components.modals.IframeModal
import components.rooamFooter
import components.seo
import components.summary.Summary
import csstype.AlignItems
import csstype.Auto
import csstype.Border
import csstype.ClassName
import csstype.Color
import csstype.Display
import csstype.Display.Companion.block
import csstype.Display.Companion.flex
import csstype.FlexDirection
import csstype.JustifyContent
import csstype.LineStyle.Companion.solid
import csstype.Margin
import csstype.None
import csstype.Padding
import csstype.PropertiesBuilder
import csstype.em
import csstype.integer
import csstype.px
import csstype.rem
import emotion.react.css
import imported.googleanalitycs.gtag
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.js.jso
import mui.icons.material.Check
import mui.icons.material.Close
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.Avatar
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Card
import mui.material.CardActions
import mui.material.CardHeader
import mui.material.Collapse
import mui.material.FormControlVariant
import mui.material.IconButton
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.InputBaseComponentProps
import mui.material.InputBaseProps
import mui.material.InputProps
import mui.material.PaperVariant
import mui.material.Size
import mui.material.SvgIconSize
import mui.material.TextField
import mui.material.TextFieldColor
import mui.material.styles.TypographyVariant
import mui.material.useMediaQuery
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.VFC
import react.create
import react.dom.html.InputType
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.onChange
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.useNavigate
import react.useLayoutEffectOnce
import react.useState
import kotlin.js.json


external interface TabCompleteProps : Props {
	var showFeedback: Boolean?
}


val TabComplete = FC<TabCompleteProps> { props ->
	val isPaytronix = sessionStorage.getItem("taoEnabled")

	useLayoutEffectOnce {
		window.scrollTo(0.0, 0.0)
	}

	layout {
		seo {
			titleTemplate = "Tab Complete - %s"
		}
		SectionNoTopBottom {
			location { showBack = false }

				Summary {
				}
				smallBanner {
				}
				PaymentComplete {}
				BreakLine {

				}
				OpenAnotherTabButton {}

				if (props.showFeedback == true && isPaytronix == null) {
					FeedbackForm {}
				}
				SendReceiptForm {

				}

				bigBanner { }
				installationComponent {

				}
				rooamFooter { }

		}
	}
}

val OpenAnotherTabButton = VFC {
	val navigate = useNavigate()
	val mobileMode = useMediaQuery("screen and (max-width:640px)")
	div {
		css {
			produceContainerStyle(mobileMode)
		}
		Button {
			size = Size.large
			fullWidth = true
			variant = ButtonVariant.contained
			color = ButtonColor.primary
			sx {
				textTransform = None.none
				fontWeight = integer(600)
			}
			onClick = {
				it.preventDefault()
				navigate("../open")
			}
			+"Open Another Tab"
		}
	}
}
val BreakLine = VFC {
	div {
		css(ClassName("container")) {}
		hr {}
	}

}

val SendReceiptForm = VFC("SendReceipt") {
	val (email, setEmail) = useState("")
	val (emailSend, setEmailSend) = useState(MessageSendState.NONE)

	form {
		css {
			paddingTop = 1.rem
		}
		id = "form-receipt-send"
		container {
			Collapse {
				`in` = emailSend == MessageSendState.SUCCESS
				Alert {
					severity = AlertColor.success
					onClose = { setEmailSend(MessageSendState.NONE) }
					AlertTitle {
						+"Email Receipt Sent!"
					}
					+" Thank you. It should arrive shortly."

				}
			}
			Collapse {
				`in` = emailSend == MessageSendState.ERROR
				Alert {
					onClose = { setEmailSend(MessageSendState.NONE) }
					severity = AlertColor.error
					AlertTitle {
						+"Send Error."
					}
					+" There was an issue sending your receipt. Please try again."
				}
			}
			TextField {
				variant = FormControlVariant.outlined
				type = InputType.email
				fullWidth = true
				color = TextFieldColor.secondary
				label = ReactNode("Email Receipt (optional)")
				value = email
				sx {
					borderBottomWidth = 2.px
				}
				onChange = { setEmail(it.target.asDynamic().value.toString()) }
				asDynamic().InputProps = jso<InputProps> {
					endAdornment = InputAdornment.create {
						position = InputAdornmentPosition.end
						Button {
							variant = ButtonVariant.contained
							size = Size.small
							color = if (emailSend == MessageSendState.SUCCESS) ButtonColor.success else
								ButtonColor.secondary
							onClick = {
								setEmailSend(MessageSendState.NONE)
								sendReceipt(email) {
									setEmailSend(it)
								}


							}
							+"Send"
						}
					}
				}
//			label {
//				className = ClassName("no-top")
//				htmlFor = "email-receipt"
//				+"Email Receipt? (optional)"
//			}
//			div {
//				className = ClassName("row clearfix")
//				div {
//					className = ClassName("column full")
//					div {
//						className = ClassName("input-wrap email")
//						input {
//							type = InputType.email
//							className = ClassName("form-control")
//							placeholder = "Email Receipt"
//							id = "email-receipt"
//							value = email
//							onChange = { setEmail(it.target.asDynamic().value.toString()) }
//						}
//						span {
//							className = ClassName("btn-right")
//							a {
//								className = ClassName("btn no-animation white")
//
//								onClick = {
//									it.preventDefault()
//									setEmailSend(MessageSendState.NONE)
//									sendReceipt(email) {
//										setEmailSend(it)
//									}
//
//								}
//
//								+"Send"
//							}
//						}
//					}
//				}
//			}
			}
		}


	}	}

	val PaymentComplete = VFC {
		div {
			css {
				padding = Padding(30.px, 0.px)
				display = Display.flex
				flexDirection = FlexDirection.column
				alignItems = AlignItems.center
			}
			Avatar {
				sx {
					backgroundColor = Color("hsl(157, 51%, 50%)")
					border = Border(10.px, solid, Color("hsl(157, 51%, 30%)"))
					width = 100.px
					height = 100.px
					color = Color.currentcolor
				}
				Check {
					fontSize = SvgIconSize.large
				}
			}
			h2 {
				+"Payment Complete."
			}
		}
	}

val FeedbackForm = VFC {
	val mobileMode = useMediaQuery("screen and (max-width:640px)")
	var shouldBeVisible by useState(true)
	var iframeLink by useState("")
	var showModal by useState(false)

	Collapse {
		`in` = shouldBeVisible
		sx {

			produceContainerStyle(mobileMode)

		}
		Card {
			sx {
				paddingBottom = 0.25.rem
			}
			elevation = 4
			variant = PaperVariant.outlined
			CardHeader {
				title = mui.material.Typography.create {
					variant = TypographyVariant.body1
					+"How was your payment experience?"
				}

				action = closeIcon { shouldBeVisible = false }

			}
			CardActions {
				sx {
					justifyContent = JustifyContent.spaceEvenly
				}
				Button {
					fullWidth = true
					color = ButtonColor.info
					variant = ButtonVariant.contained
					onClick = {
						gtag("event", "feedback_bad", json())
						iframeLink =
							"https://docs.google.com/forms/d/e/1FAIpQLSdWgtmI_0Rb16KTXm8V49aT4bJGziyjqVtv8bAm9iGyfNodGQ/viewform?embedded=true"
						showModal = true
						shouldBeVisible = false
					}
					+"Bad"
				}

				Button {
					fullWidth = true
					color = ButtonColor.info
					variant = ButtonVariant.contained
					onClick = {
						gtag("event", "feedback_great", json())
						iframeLink =
							"https://docs.google.com/forms/d/e/1FAIpQLSf0qix6R0TBLIY9L0fbcyI1t6LP5JX11Zckq1m0P3r0zq-j4A/viewform?embedded=true"
						showModal = true
						shouldBeVisible = false
					}
					+"Great"
				}
			}
		}
	}
	IframeModal {
		iframeAddress = iframeLink
		booleanShowModal = showModal
		cancelActionPressed = { showModal = false }
	}
}

private fun PropertiesBuilder.produceContainerStyle(mobileMode: Boolean) {
	if (mobileMode) {
		padding = Padding(10.px, 18.px)
	} else {
		maxWidth = 1008.px
		padding = Padding(10.px, 46.px)
	}
	margin = Margin(0.px, Auto.auto)
//	textAlign = TextAlign.center
//	paddingBottom = 0.5.em
}

fun closeIcon(click: (Any) -> Unit) = IconButton.create {
	onClick = click
	size = Size.small
	Close {
		fontSize = SvgIconSize.small
	}
}

