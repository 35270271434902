package api

import imported.braintree.hosted.HostedFields
import imported.braintree.hosted.braintreeHostedFields
import io.ktor.client.request.*
import io.ktor.client.statement.*
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.js.jso
import mainScope
import org.w3c.dom.events.Event
import suspendedBrainTreeClient
import kotlin.js.json

var braintreeHostedFieldsIntance: HostedFields? = null


val attachBrainTreeFields = { braintreeClient: Any ->
	mainScope.async {
		val braintreeClientAwaited = braintreeClient
		braintreeHostedFieldsIntance = try {
			buildHostedFields(braintreeClientAwaited).await()
		} catch (e: Exception) {
			console.log(e)
			var error = true
			var fields: HostedFields? = null
			while (error) {
				delay(100)
				try {
					error = false
					fields = buildHostedFields(braintreeClientAwaited).await()
				} catch (newEx: Exception) {
					console.log(newEx)
					error = true
				}
			}
			fields
		}


	}
}

private fun buildHostedFields(braintreeClientAwaited: Any) = braintreeHostedFields(
	jso {
		client = braintreeClientAwaited
		styles = jso {
			input = json(
				"color" to "#ffffff",
				"background-color" to "#1A1A1A",
				"font-size" to "14px",
				"font-family" to "'Calibre', Helvetica Neue, Helvetica, Arial, sans-serif"
			)
		}
		fields = jso {
			cvv = jso {
				container = "#card-cvv"
				placeholder = "CVV"
			}
			expirationDate = jso {
				container = "#card-expiry"
				placeholder = "MM/YYYY"
			}

			number = jso {
				container = "#card-number"
				placeholder = "Card Number"
			}
			postalCode = jso {
				container = "#card-zip"
				placeholder = "Zip Code"
			}
		}
	}
)


val addPaymentFunction = { it: Event,
                           errorHandler: () -> Unit,
                           historyChange: () -> Unit
	->
	it.preventDefault()
	if (braintreeHostedFieldsIntance != null) {
		mainScope.launch {
			try {
				val deviceData = collectDeviceData(
					suspendedBrainTreeClient.await()
				).await().deviceData
				val braintreeHostedFieldsInstanceCopy =
					braintreeHostedFieldsIntance ?: throw RuntimeException("NULL")

				braintreeHostedFieldsInstanceCopy.tokenize { error, bTreePayload ->
					if (error != null) {
						error(ActionMessage("cc_add", "Failed to add CC data because of: $error"))
						errorHandler()
					} else {
						if (bTreePayload == null) {
							error(ActionMessage("cc_add", "Failed to add CC Payment data because of null payload"))
							errorHandler()
						} else
							mainScope.launch {
								if (postPayment(bTreePayload.nonce, deviceData, bTreePayload.type).await()) {
									historyChange()
								} else {

									errorHandler()
								}
							}
					}
				}
			} catch (e: Throwable) {
				console.log(e)
				error(ActionMessage("cc_add", "An error $e was thrown"))
				errorHandler()
			}
		}
	} else {
		error(ActionMessage("cc_add", "Braintree fields are null"))
		errorHandler()
	}
}

suspend fun loadToken(): HttpResponse = client.get("/payments/token")
