package components

import csstype.*
import csstype.JustifyContent.Companion.center
import emotion.react.css
import mui.icons.material.MoreHoriz
import mui.material.Avatar
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span

external interface FullScreenLoaderProps : Props {
	var show: Boolean
	var translucentBackground: Boolean
	var text: String?
}

val FullScreenLoader = FC<FullScreenLoaderProps> { props ->
	val theme = useTheme<Theme>()

	div {
		css {
			backgroundColor = Color(theme.palette.background.default + (if (props.translucentBackground) "e6" else ""))
			width = 100.vw
			height = 100.vh
			color = Color("#c9c9c9")
			display = if (props.show) Display.inlineFlex else None.none
			alignItems = AlignItems.center
			justifyContent = center
			flexDirection = FlexDirection.column
			position = Position.absolute
			zIndex = integer(999)
		}
		Avatar {
			sx {
				borderRadius = 100.pct
				backgroundColor = Color("hsl(0, 0%, 24%)")
				border = Border(4.px, LineStyle.solid, Color("hsl(0, 0%, 19%)"))
				margin = 8.px;
				padding = 8.px;
			}
			span {
				css(ClassName("flashing")) {}
				MoreHoriz {
					sx {
						color = Color("#939597")
						width = 16.px
						height = 16.px
					}
				}
			}
		}
		p {
			css {
				display = if (props.text != null) Display.block else None.none
			}
			className = ClassName("text-grey")
			+(props.text ?: "")
		}
	}
}

val FullScreenTranslucentLoader =   FC<TranslucentLoader> { props ->
	FullScreenLoader {
		show = props.show
		text = props.text
		translucentBackground = true
	}
}

external interface TranslucentLoader : Props {
	var show: Boolean
	var text: String?
}

