package pages

import components.divs.SectionNoTopBottom
import components.layout
import components.rooamFooter
import components.seo
import components.utils.orbit
import csstype.ClassName
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.dom.Link

val notExisting = FC<Props> {
	layout {
		seo {
			title = "404: Page Not Found - Rooam Tab"
		}
		SectionNoTopBottom {
			div {
				className = ClassName("section largest no-bottom")
				div {
					className =ClassName( "container text-center")
					div {
						className = ClassName("orbit-box medium")
						orbit()
						div {
							className =ClassName( "icon")
							svg {
								className = ClassName("svg-icon")
								viewBox = "0 0 24 24"
								path {
									d =
										"M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.31 7.526c-.099-.807.528-1.526 1.348-1.526.771 0 1.377.676 1.28 1.451l-.757 6.053c-.035.283-.276.496-.561.496s-.526-.213-.562-.496l-.748-5.978zm1.31 10.724c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"

									fill = "#ffffff"


								}
							}
						}
					}
					h1 {
						className = ClassName("no-top-bottom")
						+"404: Page Not Found"
					}
					p {
						className = ClassName("no-bottom")
						+"We searched high and low but couldn’t find what you’re looking for."
					}
					div {
						className = ClassName("section no-bottom")
						Link {
							to = "/"
							className = ClassName("btn white")

							+"Head Back Home"
						}
					}
				}
			}
			rooamFooter {}
		}
	}
}
