import co.rooam.icons.DollarIcon
import components.animations.ErrorMessage
import components.divs.container
import components.error.StandardError
import components.icons.dollarIcon
import components.tao.api.TaoRedeemStatus
import components.tao.api.TaoRewardsResponse
import components.tao.api.getRewardsQuery
import components.tao.api.redeemRewardsMutation
import csstype.Background
import csstype.BackgroundSize
import csstype.Border
import csstype.BoxShadow
import csstype.ClassName
import csstype.Color
import csstype.Display
import csstype.LineStyle
import csstype.Margin
import csstype.None
import csstype.Overflow
import csstype.Padding
import csstype.Position
import csstype.number
import csstype.px
import csstype.rgba
import csstype.url
import emotion.css.cx
import co.rooam.utilities.functions.minus
import co.rooam.utilities.functions.toCents
import csstype.integer
import csstype.rem
import emotion.react.css
import imported.numberformat.Values
import imported.numberformat.numberFormat
import io.ktor.client.plugins.*
import kotlinx.js.jso
import mui.base.SwitchUnstyled
import mui.lab.LoadingButton
import mui.material.Alert
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Collapse
import mui.material.Container
import mui.material.FormControlColor
import mui.material.FormControlMargin
import mui.material.InputBaseProps
import mui.material.Paper
import mui.material.PaperVariant
import mui.material.Size
import mui.material.Switch
import mui.material.SwitchColor
import mui.material.TextField
import mui.material.Toolbar
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.material.styles.useTheme
import mui.system.sx
import pages.tab.feedQuery
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.query.InvalidateQueryFilters
import react.query.QueryKey
import react.query.UseMutationOptions
import react.query.UseQueryOptions
import react.query.useMutation
import react.query.useQuery
import react.query.useQueryClient
import react.useState
import styles.Toggle
import kotlin.js.Promise


external interface TaoProps : Props {
	var subtotal: String?
	var discount: String?
}


@JsModule("./images/tao/img.png")
@JsNonModule
external val taoRewardsLogo: dynamic

val taoDiscount = FC<TaoProps>("Tao Discount") { props ->
	val theme = useTheme<Theme>()
	var error by useState(false)
	var rewards by useState("0.00")
	var active by useState(false)
	var applied by useState("0.00")
	var alreadyApplied = applied !== "0.00"
	val queryClient = useQueryClient()
	var values by useState(jso<Values> {
		value = "0.00"
		formattedValue = "0.00"
		floatValue = 0.0f
	})
	val rewardsResponse = useQuery(
		QueryKey<QueryKey>("taoRewards"),
		{ getRewardsQuery() },
		jso<UseQueryOptions<Any, Any, TaoRewardsResponse, QueryKey>> {
			onSuccess = {
				rewards = minus(it.amount, it.used)
				applied = it.used ?: "0.00"
				if (applied != "0.00") {
					values = jso {
						value = applied
						formattedValue = applied
						floatValue = toCents(applied) / 100.0f
					}
				}
			}
		})
	val redeemRewards = useMutation(
		::redeemRewardsMutation,
		jso<UseMutationOptions<TaoRedeemStatus, ResponseException, String, String>> {

			onSuccess = { redeemStatus, _, context ->
				queryClient.invalidateQueries(
					QueryKey<QueryKey>("taoRewards"),
					jso<InvalidateQueryFilters<String>> {},
					jso {})
				queryClient.invalidateQueries(
					feedQuery,
					jso<InvalidateQueryFilters<String>> {},
					jso {})
				alreadyApplied = true
				applied = values.value
				rewards = minus(rewards, applied)
				Promise.resolve("")
			}
			onError = { exception, _, _ ->
				error = true
				Promise.resolve("")
			}
		})

	val valueInRange = values.value.lowerThan(rewards, props.subtotal)

	Container {
		sx{
			marginBottom = 15.px
		}
		Typography {
			variant = TypographyVariant.h3
			sx {
				padding = Padding(0.px, 0.px)
			}
			+"Would you like to Redeem Rewards?"
		}
		Collapse {
			`in` = error
			Alert {
				title = "Error with rewards. "
				+"Try again"
			}
		}
		Paper {
			sx {
				padding = Padding(15.px, 15.px)
			}
			variant = PaperVariant.elevation
			elevation = 0
			Toolbar {
				img {
					css {
						width = 42.px
						height = 42.px
						marginRight = 12.px
					}
					src = taoRewardsLogo
				}
				div {
					css {
						flexGrow = number(1.0)
					}
					Typography {
						sx {
							fontSize = 0.85.rem
						}
						+"Rewards Dollars"
					}
					Typography {
						sx {
							fontSize = 0.85.rem
						}
						+"\$$rewards available"

					}
				}
				button {
					className = Toggle.wrapper
					if (active || alreadyApplied) {
						className = cx(Toggle.wrapper, Toggle.active)
					}



					id = "rewards-toggle"
					onClick = {
						it.preventDefault()
						active = !active
					}
					span {

						className = Toggle.toggle(active || alreadyApplied)

					}
				}

			}
			Collapse {
				`in` = active || alreadyApplied
				numberFormat {
					fullWidth = true
					margin = FormControlMargin.normal
					customInput = TextField
					color = FormControlColor.secondary
					asDynamic().InputProps = jso<InputBaseProps> {
						startAdornment = DollarIcon.create()
						endAdornment = LoadingButton.create {
							if (alreadyApplied) {
								color = ButtonColor.success
							} else {
								color = ButtonColor.secondary
							}
							variant = ButtonVariant.contained
							size = Size.small
							disabled = !canBeApplied(alreadyApplied, valueInRange)
							sx {
								if (alreadyApplied) {
									backgroundColor = theme.palette.success.main
								}
								textTransform = None.none
							}

							onClick = {
								it.preventDefault()
								if (!alreadyApplied) {
									redeemRewards.mutate(values.value, null)
								}
							}
							disableFocusRipple = alreadyApplied


							loading = redeemRewards.isLoading
							+(if (alreadyApplied) "Applied " else "Apply ")
						}

					}
					placeholder = "Enter Amount to Redeem"
					thousandSeparator = true
					allowNegative = false
					if (alreadyApplied) value = applied
					decimalScale = 2
					fixedDecimalScale = true
					disabled = alreadyApplied
					onValueChange = {
						if (it.floatValue < 1.0e14) {
							values = it
						}
					}

				}
				Typography {
					variant = TypographyVariant.body2
					sx {
						color = theme.palette.text.secondary
						fontSize = 0.7.rem
						fontWeight = integer(400)
					}
					+"""Enter the amount of Rewards Dollars you would like to redeem. Redemption cannot exceed the subtotal amount ($${props.subtotal}). Your selection is final."""

				}

			}
		}
	}
}


fun String.lowerThan(rewards: String, subtotal: String?): Boolean {
	val cents = toCents(this)
	return (cents <= toCents(rewards)) and
			(cents <= toCents(subtotal ?: "0.00"))
}

private fun canBeApplied(alreadyApplied: Boolean, valueInRange: Boolean): Boolean {
	return alreadyApplied or valueInRange
}


object TaoBlock {
	val wrapper = emotion.css.css(
		jso {
			display = Display.block
			border = Border(1.px, LineStyle.solid, Color("#29292b"))
			backgroundColor = Color("#1a1a1c")
			margin = Margin(5.px, 0.px)
			padding = Padding(15.px, 15.px, 15.px, 15.px)
			boxShadow = BoxShadow(0.px, 1.px, 6.px, 1.px, rgba(0, 0, 0, 0.09))


//			boxShadow(
//				color = Color("rgba(0,0,0,0.09)"),
//				offsetX = LinearDimension.none,
//				offsetY = LinearDimension("1px"),
//				blurRadius = LinearDimension("6px"),
//				spreadRadius = LinearDimension("1px")
//			)


		}

	)
}

object TaoRewards {
	val wrapper = emotion.css.css(jso {

		display = Display.inlineBlock
		background = "50% 50% no-repeat #f0f0f0".unsafeCast<Background>()
		backgroundSize = BackgroundSize.cover
		position = Position.relative
		width = 42.px
		height = 42.px
		overflow = Overflow.hidden
		flexShrink = number(0.0)
		marginRight = 12.px
		backgroundImage = url(taoRewardsLogo)
	}
	)
}
