package components.qr

import components.themes.dialogTheme
import csstype.AlignItems
import csstype.ClassName
import csstype.Display
import csstype.NamedColor
import csstype.Padding
import csstype.TextAlign
import csstype.pct
import csstype.px
import emotion.react.css
import imported.qrscanner.QrReader
import kotlinx.js.jso
import mui.icons.material.Close
import mui.material.Alert
import mui.material.AlertColor
import mui.material.Box
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Collapse
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.Drawer
import mui.material.DrawerAnchor
import mui.material.IconButton
import mui.material.styles.ThemeProvider
import mui.system.sx
import org.w3c.dom.mediacapture.ENVIRONMENT
import org.w3c.dom.mediacapture.MediaTrackConstraints
import org.w3c.dom.mediacapture.VideoFacingModeEnum
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.h2
import react.useState


external interface QrModalPRops : Props {
	var text: String
	var title: String
	var booleanShowModal: Boolean?
	var confirmActionPressed: (String, String) -> Unit
	var cancelActionPressed: () -> Unit
}

val QrModal = FC<QrModalPRops> { props ->
	var barId by useState("")
	var tapId by useState("")
	var alert by useState("")

	ThemeProvider {
		theme = dialogTheme
		Drawer {
			open = props.booleanShowModal!!
			onClose = { _, _ -> props.cancelActionPressed() }
			anchor = DrawerAnchor.bottom
			PaperProps = jso {
				sx {
					borderTopRightRadius = 10.px
					borderTopLeftRadius = 10.px
					textAlign = TextAlign.center
					color = NamedColor.black
				}
			}
			Box {
					sx {
						padding = Padding(38.px, 28.px, 34.px)
					}
				DialogTitle {
					h2 {
						css(ClassName("no-top-bottom")) {
							color = NamedColor.black
						}
					+"Scan to Pour"
					}
				}
				DialogContent {

					QrReader {
						onResult = { result, cameraError ->
							console.log("QRCode Value:", result)
							if (result?.text != null && result.text!!.isNotBlank()) {
								val split = result.text!!.split("|")
								if (split.size >= 2) {
									barId = split[0]
									tapId = split[1]
								} else {
									alert = "Invalid qr code"
								}

							}
						}
						scanDelay = 500
						constraints = MediaTrackConstraints(facingMode = VideoFacingModeEnum.ENVIRONMENT)
					}

					Collapse {
						`in` = alert.isNotEmpty()
						Alert {
							severity = AlertColor.warning
							action= IconButton.create{
								onClick= {alert = ""}
								Close{}
							}
							+alert
						}
					}
					Collapse{
						`in`=barId.isNotEmpty()
					Alert{
						severity= AlertColor.success
						+"Bar: $barId, Tap: $tapId"
					}
					}
				}
				DialogActions {
					Button {
						sx {
							width = 100.pct
							minHeight = 48.px
							maxHeight = 72.px
						}
						onClick = { props.cancelActionPressed() }
						color = ButtonColor.primary
						variant = ButtonVariant.outlined
						+"Cancel"
					}

					Button {
						sx {
							width = 100.pct
							minHeight = 48.px
							maxHeight = 72.px
						}
						disabled = barId.isEmpty() || tapId.isEmpty()
						onClick = { props.confirmActionPressed(barId, tapId) }
						color = ButtonColor.primary
						variant = ButtonVariant.contained
						+"Send"
					}


				}
			}


		}
	}
}

