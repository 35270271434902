package pages.identity.verification

import api.IdentityVerificationState
import api.StateResponse
import api.logout
import components.FullScreenTranslucentLoader
import components.identity.verification.IdentityVerificationDisclaimer
import components.identity.verification.IdentityVerificationFailure
import components.layout
import components.seo
import csstype.Display
import csstype.None
import csstype.Position
import imported.incode.*
import imported.sentry.setUser
import io.ktor.client.plugins.*
import kotlinx.browser.sessionStorage
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.launch
import kotlinx.js.jso
import locationBase
import mainScope
import pages.getStateQuery
import pages.moveToNext
import react.*
import react.dom.html.ReactHTML.div
import react.query.QueryKey
import react.query.UseQueryOptions
import react.query.useQuery
import react.router.NavigateFunction
import react.router.Params
import react.router.useNavigate
import react.router.useParams
import kotlin.js.json

external interface VerifyIdentityPageProps : PropsWithChildren

val verifyIdentityPage = FC<VerifyIdentityPageProps> {
	val params = useParams()
	val history = useNavigate()

	val (isLoading, setLoading) = useState(false)
	var (showIdentityVerificationFailure, setShowIdentityVerificationFailure) = useState(false)
	val (showIdentityVerificationDisclaimer, setShowIdentityVerificationDisclaimer) = useState(true)

	val query = useQuery(QueryKey<QueryKey>("verifyIdentity"), {
		mainScope.async { getStateQuery() }.asPromise()
	}, jso<UseQueryOptions<StateResponse, ResponseException, StateResponse, QueryKey>> {
		onSuccess = {
			history("${locationBase(it.placeCode, params)}/${it.nextPath}")
		}
		onError = {
			val username = sessionStorage.getItem("phone") ?: "<unknown user>"
			console.error("Error checking state at Identity Verification page for user $username", it)
			history(locationBase(params))
		}
		retry = { _, _ -> false }
	})

	layout {
		seo {
			titleTemplate = "Verify your Identity - %s"
		}
		div {
			style = jso {
				position = Position.relative
			}
			FullScreenTranslucentLoader {
				show = isLoading
				text = "Please wait"
			}
			CameraContainer {
				display = (!showIdentityVerificationFailure && !showIdentityVerificationDisclaimer)
			}
			IdentityVerificationDisclaimer {
				show = showIdentityVerificationDisclaimer
				verifyIdentityPressed = handleDisclaimerVerifyIdentityPressed(
					params,
					history,
					setLoading,
					setShowIdentityVerificationFailure,
					setShowIdentityVerificationDisclaimer
				)
				logoutPressed = handleLogoutPressed(params, history, setLoading)
			}
			IdentityVerificationFailure {
				show = showIdentityVerificationFailure
				verifyIdentityPressed = handleVerifyIdentityPressed(params, history, setLoading)
				logoutPressed = handleLogoutPressed(params, history, setLoading)
			}
		}
	}
}

external interface CameraContainerProps : Props {
	var display: Boolean
}

val CameraContainer = FC<CameraContainerProps> { props ->
	div {
		style = jso {
			display = if (props.display) Display.block else None.none
		}
		id = "camera-container"
	}
}

private fun handleDisclaimerVerifyIdentityPressed(
	params: Params, history: NavigateFunction,
	setLoading: StateSetter<Boolean>,
	setShowIdentityVerificationFailure: StateSetter<Boolean>,
	setShowIdentityVerificationDisclaimer: StateSetter<Boolean>,
): () -> Unit {
	return {
		mainScope.launch {
			setLoading(true)
			setShowIdentityVerificationDisclaimer(false)
			Incode.startIdentityVerification<IdentityVerificationStatus<*>>("camera-container")
				.catch {
					console.error("Failure performing Identity Verification", it)
					setLoading(false)
					setShowIdentityVerificationFailure(true)
				}.collect {
					handleVerificationStatus(it, history, params, setLoading, setShowIdentityVerificationFailure)
				}
		}
	}
}

private fun handleVerifyIdentityPressed(
	codeProps: Params, history: NavigateFunction, setLoading: StateSetter<Boolean>
): () -> Unit {
	return {
		mainScope.launch {
			setLoading(true)
			history("${locationBase(codeProps)}")
		}
	}
}

private fun handleLogoutPressed(
	codeProps: Params, history: NavigateFunction, setLoading: StateSetter<Boolean>
): () -> Unit {
	return {
		mainScope.launch {
			setLoading(true)
			logout()
			history("${locationBase(codeProps)}/phone")
		}
	}
}

private fun handleVerificationStatus(
	verificationStatus: IdentityVerificationStatus<*>,
	history: NavigateFunction,
	params: Params,
	setLoading: StateSetter<Boolean>,
	setShowIdentityVerificationFailure: StateSetter<Boolean>
) {
	when (verificationStatus) {
		is Loading -> setLoading(verificationStatus.value)
		is VerificationState -> handleVerificationState(
			verificationStatus, history, params, setLoading, setShowIdentityVerificationFailure
		)

		is GenericFailure -> {
			setShowIdentityVerificationFailure(true)
		}

		else -> {}
	}
}

private fun handleVerificationState(
	verificationState: VerificationState,
	history: NavigateFunction,
	params: Params,
	setLoading: StateSetter<Boolean>,
	setShowIdentityVerificationFailure: StateSetter<Boolean>
) {
	console.log(verificationState)
	when (verificationState.value) {
		IdentityVerificationState.VERIFIED -> {
			setLoading(true)
			moveToNext(history, params)
		}

		IdentityVerificationState.VERIFIED_INVALID -> {
			setLoading(false)
			setShowIdentityVerificationFailure(true)
		}

		else -> {}
	}
}
