package api

import api.storage.storeRooamHeader
import components.marketing.CampaignId
import components.marketing.model.*
import imported.googleanalitycs.gtag
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.flow
import mainScope
import react.StateSetter
import kotlin.Result.Companion.failure
import kotlin.Result.Companion.success

/**
 * Get Banner for specific location If Needed
 * The result is affected by the HIDE_MARKETING_CAMPAIGN_BANNER A/B Testing
 * if present and value is TRUE, no banner will be fetched
 */
suspend inline fun <reified T : Banner> getBannerForLocationIfNeeded(
	location: history.Location,
	crossinline onSuccess: suspend (T) -> Unit,
	crossinline onFailure: suspend (Throwable) -> Unit
) {
	getCampaignBannerForLocation<T>(location)
		.onSuccess { banner ->
			getHideBannerABTest(banner.campaignId) { abTest ->
				if (abTest.active && abTest.value) return@getHideBannerABTest
				gtag("event", "banner_data", banner.toJson())
				onSuccess(banner)
			}
		}
		.onFailure {
			onFailure(it)
		}
}

suspend inline fun <reified T : Banner> getCampaignBannerForLocation(
	location: history.Location
): Result<T> = runCatching {
	val path = "/marketing/banner${location.pathname}"
	val bannerType: BannerType = bannerTypeForClass(T::class)
	val response: HttpResponse = client.get(path) {
		addSessionStaticHeader()
		expectSuccess = false
		parameter(BannerTypeParam, bannerType)
	}.also {
		it.storeRooamHeader()
	}
	if (response.status.isSuccess()) {
		response.body()
	} else {
		throw Exception("Something went wrong getting the Banner for Location: ${location.pathname}, Banner Type: ${bannerType.name} $response")
	}
}

fun claimCampaignReward(campaignId: CampaignId): Flow<Result<ClaimRewardState>> {
	return flow {
		emit(success(ClaimRewardState.STARTED))
		val response: HttpResponse = client.post {
			url(path = "/marketing/campaign/reward/claim/${campaignId.id}")
			addSessionHeader()
			expectSuccess = false
		}.also { it.storeRooamHeader() }
		val allowedStatus = listOf(HttpStatusCode.OK, HttpStatusCode.NoContent)
		if (allowedStatus.contains(response.status)) {
			emit(success(ClaimRewardState.SUCCESS))
		} else {
			val receive = response.body<Map<String, Map<String, String?>>>()
			val errors = receive["errors"]
			emit(failure(Exception(errors?.values?.first { it != null } ?: "Something went wrong! Please try again")))
		}

	}
}


fun mutateCampaingClaim(campaignId: CampaignId) = mainScope.async {
	val response: HttpResponse = client.post {
		url(path = "/marketing/campaign/reward/claim/${campaignId.id}")
		addSessionHeader()
		expectSuccess = true
	}.also { it.storeRooamHeader() }
	val allowedStatus = listOf(HttpStatusCode.OK, HttpStatusCode.NoContent)
	if (allowedStatus.contains(response.status)) {
		ClaimRewardState.SUCCESS
	} else
		throw ResponseException(response, "Something went wrong! Please try again")
}.asPromise()


suspend fun updateClaimStateForCampaign(campaignId: CampaignId, setBannerRewardClaimed: StateSetter<Boolean>) {
	val response: HttpResponse = client.get {
		url(path = "/marketing/campaign/reward/claim/state/${campaignId.id}")
		addSessionHeader()
		expectSuccess = false
	}
	response.status
	setBannerRewardClaimed(response.status.value == 226)
	response.storeRooamHeader()
}


enum class ClaimRewardState {
	STARTED, SUCCESS
}
