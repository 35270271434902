package pages

import api.StateResponse
import api.getVenueDetails
import components.fullPageLoading
import components.layout
import components.seo
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.http.*
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.js.jso
import locationBase
import mainScope
import react.FC
import react.Props
import react.query.QueryKey
import react.query.UseQueryOptions
import react.query.useQuery
import react.router.NavigateFunction
import react.router.Params
import react.router.useNavigate
import react.router.useParams
import react.useLayoutEffect


external interface SlashPageProps : Props


val slashPage = FC<SlashPageProps> {
	val location = useParams()
	val history = useNavigate()
	useLayoutEffect(location) {
		getVenueDetails(location.get("code") ?: "") {
		}
	}
	val query = useQuery(QueryKey<QueryKey>("slash"), {
		mainScope.async { getStateQuery() }.asPromise()
	}, jso<UseQueryOptions<StateResponse, ResponseException, StateResponse, QueryKey>> {
		onSuccess = { history("${locationBase(it.placeCode, location)}/${it.nextPath}") }
		onError = { history("phone") }
		retry = { _, _ -> false }
	})
	layout {
		seo {
			titleTemplate = "Loading - %s"
		}
		fullPageLoading { }
	}
}

val moveToNext =
	{ history: NavigateFunction, location: Params ->
		mainScope.launch {
			console.log("get")
			val state = getState()
			console.log("get", state)
			if (state.status != HttpStatusCode.OK) {
				history("phone")
			} else {
				val (nextPath, placeCode) = state.body<StateResponse>()
				console.log("receive", nextPath, placeCode)
				history("${locationBase(placeCode, location)}/$nextPath")
			}
		}
	}
