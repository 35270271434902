package components.explainer


import components.faq.Faq
import components.faq.Question
import csstype.ClassName
import kotlinx.js.jso
import react.FC
import react.Props
import react.dom.html.ReactHTML.div

val explainerFAQ = FC<Props> {
	div {
		className = ClassName("section large text-left no-bottom")
		Faq {

			rows = arrayOf(
				jso<Question> {
					title = "Do I need to create an account?"
					lines = arrayOf(
						"There is no need to create an account nor download an app. You will only need to verify your phone number."
					)
				},
				jso {
					title = "Do I order items at the bar or order on this web tab link?"
					lines =
						arrayOf("You will still order items directly from the bartender. First show your tab number, and then verbally tell them your order like normal. Web Tabs simply allow you to open your tab without handing over your card, and then close your tab from your phone without having to return to the bar to pay.")
				},
				jso {
					title = "When do I open my tab?"
					lines =
						arrayOf("It’s best to open your tab as you’re arriving at the location so you are ready to order when you approach the bar. If you do not have a link beforehand, you will be able to scan a QR code at the entryway or on the bar to open your tab.")
				},
				jso {
					title = "When do I close my tab?"
					lines =
						arrayOf("You can continue to order on the same tab as many times as you would like. Close your tab whenever you are done ordering, or when you’re ready to leave the location.")
				},
				jso {
					title = "What happens if I forgot to close my tab at the end of the night?"
					lines =
						arrayOf("If you forget to close your tab at the location, there’s no need to return. You will still be able to close it remotely before service ends. If you do not close it by the end of service, it will automatically be closed on your behalf with a 20% gratuity.")
				},
				jso {
					title = "What happens if my phone dies?"
					lines = arrayOf(
						"If your phone dies, you can simply continue to provide your tab number verbally to the bartender before placing your order. They will verify your name on the tab for security purposes.","","When you are ready to close your tab, they will be able to close it manually in the POS, or you can close it yourself, once your phone is charged provided it is before the end of service."
					)
				},
				jso {
					title = "I closed my tab, but I want another drink?"
					lines = arrayOf(
						"No worries, you can simply open another tab by scanning a QR code if available or tapping on the original link you opened with."
					)
				},
				jso {
					title = "What happens if I close my browser?"
					lines = arrayOf(
						"If you accidentally close your web browser, simply click on the original link to re-open your tab."
					)
				}
			)
		}
	}
}
