package components.themes

import csstype.px
import kotlinx.browser.sessionStorage
import kotlinx.js.jso
import mui.material.styles.PaletteOptions
import mui.material.styles.ThemeOptions
import mui.material.styles.createTheme

private fun isTaoEnabled() = sessionStorage.getItem("taoEnabled") != null

val dialogTheme = createTheme(jso {
	palette = paletteOptions()
}
)


private fun ThemeOptions.paletteOptions(): PaletteOptions =
	if (isTaoEnabled()) {
		jso {
			primary = jso {
				main = "#000000"
			}
			typography =
				jso {
					fontFamily = "'Calibre', Helvetica Neue, Helvetica, Arial, sans-serif"
				}
			shape = jso {
				borderRadius = 0.px
			}
		}
	} else
		jso {
			primary = jso {
				main = "#000000"
			}
			typography =
				jso {
					fontFamily = "'Calibre', Helvetica Neue, Helvetica, Arial, sans-serif"
				}
			shape = jso {
				borderRadius = 8.px
			}
		}
