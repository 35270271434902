package components.explainer

import csstype.ClassName
import react.FC
import react.Props
import react.dom.svg.ReactSVG.circle
import react.dom.svg.ReactSVG.ellipse
import react.dom.svg.ReactSVG.g
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.rect
import react.dom.svg.ReactSVG.svg

val cards = FC<Props> {
	svg {
		className = ClassName("svg-icon")
		viewBox = "0 0 358.545 248.628"
		g {
			id = "credit-cards"
			transform = "translate(-35 -311.372)"
			g {
				id = "cc-blue"
				transform = "translate(43.455 356.603) rotate(-8)"
				fill = "#0f2578"
				stroke = "#20368d"
				strokeWidth = 2.0
				rect {
					width = 325.0
					height = 203.0
					rx = 14.0
					stroke = "none"
				}
				rect {
					x = 1.0
					y = 1.0
					width = 323.0
					height = 201.0
					rx = 13.0
					fill = "none"
				}
			}
			g {
				id = "cc-dark"
				transform = "translate(35 343)"
				fill = "#101010"
				stroke = "#2d2d2d"
				strokeWidth = 2.0
				rect {
					width = "345".toDouble()
					height = "217".toDouble()
					rx = "14".toDouble()
					stroke = "none"
				}
				rect {
					x = "1".toDouble()
					y = "1".toDouble()
					width = "343".toDouble()
					height = "215".toDouble()
					rx = "13".toDouble()
					fill = "none"
				}
			}
			g {
				id = "mastercard-icon"
				transform = "translate(-21 -13.735)"
				ellipse {
					id = "Ellipse_3"
//					attributes["data-name"] = "Ellipse 3"
					cx = "18.98".toDouble()
					cy = "18.367".toDouble()
					rx = "18.98".toDouble()
					ry = "18.367".toDouble()
					transform = "translate(311 503)"
					fill = "#eb0b1b"
				}
				circle {
					id = "Ellipse_4"

//					attributes["data-name"] = "Ellipse 4"
					cx = "18.367".toDouble()
					cy = "18.367".toDouble()
					r = "18.367".toDouble()
					transform = "translate(334.265 503)"
					fill = "#f79e27"
				}
				path {
					id = "Intersection_1"
					//attributes["data-name"] = "Intersection 1"
					d =
						"M0,14.719A18.521,18.521,0,0,1,7.259,0a18.521,18.521,0,0,1,7.259,14.719A18.521,18.521,0,0,1,7.259,29.438,18.52,18.52,0,0,1,0,14.719Z"
					transform = "translate(333.899 506.466)"
					fill = "#fd5f1f"
				}
			}
			path {
				id = "chip"
				d =
					"M20.559,61.182H48.131A11.225,11.225,0,0,0,59.345,49.97V36.38A11.228,11.228,0,0,0,48.131,25.166H20.559A11.228,11.228,0,0,0,9.345,36.38V49.97A11.227,11.227,0,0,0,20.559,61.182ZM12.695,39.641h8.394l6.067-6.065-2.367-2.367L19.7,36.294h-7a7.871,7.871,0,0,1,7.863-7.778H32.675V39.135h3.348V28.516H48.136A7.871,7.871,0,0,1,56,36.294H49L43.91,31.209l-2.367,2.367,6.067,6.065H56v7.067H47.6l-6.067,6.065L43.9,55.141l5.084-5.086h7a7.873,7.873,0,0,1-7.863,7.778H36.016V47.908H32.668v9.926H20.556a7.873,7.873,0,0,1-7.863-7.778h7l5.084,5.086,2.367-2.369-6.065-6.065H12.688V39.641Z"
				transform = "translate(55.465 365.724)"
				fill = "#3d3d3d"
			}
			rect {
				id = "name"
				width = "132".toDouble()
				height = "10".toDouble()
				rx = "5".toDouble()
				transform = "translate(65 497)"
				fill = "#6d6d6d"
			}
			rect {
				id = "number"
				width = "83".toDouble()
				height = "8".toDouble()
				rx = "4".toDouble()
				transform = "translate(65 517)"
				fill = "#6d6d6d"
			}
		}
	}
}

