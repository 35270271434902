package co.rooam.components.payment.choose

import co.rooam.components.payment.Payment
import co.rooam.components.payment.getImageFromPayment
import csstype.AlignItems
import csstype.AspectRatio
import csstype.Border
import csstype.Color
import csstype.Display
import csstype.LineStyle.Companion.solid
import csstype.None
import csstype.Padding
import csstype.integer
import csstype.number
import csstype.px
import emotion.react.css
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Card
import mui.material.Size
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.material.styles.useTheme
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import react.router.dom.Link


external interface ChoosePaymentProps : Props {
	var payment: Payment
}


val ChoosePayment = FC<ChoosePaymentProps>("choosePayment") {
	val theme = useTheme<Theme>()
	Card {

		elevation = 0
		sx {
			padding = Padding(top = 6.px, right = 10.px, bottom = 6.px, left = 10.px)
			display = Display.flex
			alignItems = AlignItems.center;
			border = Border(1.px, solid, Color("#29292b"))
		}
		img {
			css {
				width = 50.px
				borderRadius = theme.shape.borderRadius
				marginRight = 10.px
				aspectRatio = "115/81".unsafeCast<AspectRatio>()
			}
			src = getImageFromPayment(it.payment)

		}
		Typography {
			variant = TypographyVariant.body1
			sx {
				fontWeight = integer(500)
				flexGrow = number(1.0)
			}
			+it.payment.label
		}
		Button {
			variant = ButtonVariant.contained
			color = ButtonColor.info
			size = Size.small
			asDynamic().component = Link
			asDynamic().to = "../select"
			sx{
				textTransform = None.none
				fontSize=15.px
				fontWeight = integer(600)
			}
			+"Change"
		}
	}

}


