package components

import api.deletePaymentCall
import co.rooam.components.payment.Payment
import components.modals.ConfirmModal
import csstype.Auto
import csstype.Padding
import csstype.px
import io.ktor.client.plugins.*
import io.ktor.client.statement.*
import kotlinx.js.jso
import mui.icons.material.DeleteForeverSharp
import mui.material.IconButton
import mui.material.Size
import mui.material.SvgIconColor
import mui.system.sx
import react.FC
import react.Props
import react.query.InvalidateQueryFilters
import react.query.QueryKey
import react.query.UseMutationOptions
import react.query.useMutation
import react.query.useQueryClient
import react.useState
import kotlin.js.Promise

external interface DeletePaymentProps : Props {
	var payment: Payment
	var disable: (Boolean) -> Unit
}

val deletePaymentButton = FC<DeletePaymentProps> { props ->
	val queryClient = useQueryClient()
	val mutation =
		useMutation(
			::deletePaymentCall,
			jso<UseMutationOptions<Promise<HttpResponse>, ResponseException, Payment, String>> {
				onSuccess = { _, _, context ->
					console.log(context)
					queryClient.invalidateQueries(QueryKey<QueryKey>("payments"),jso<InvalidateQueryFilters<String>>{},jso{})
					Promise.resolve("")
				}
			})
	val (showModal, setShowModal) = useState(false)
	ConfirmModal {
		showIcon = false
		text = "Tap 'Confirm' if you wish to delete your payment."
		title = "Delete payment?"
		booleanShowModal = showModal
		confirmActionPressed = {
			props.disable(true)
			mutation.mutate(props.payment, null)
			setShowModal(false)

		}
		cancelActionPressed = {
			setShowModal(false)
		}
	}
	IconButton {
		size= Size.small
		sx {
			padding = Padding(0.px,0.px)
			marginLeft = Auto.auto
		}

		DeleteForeverSharp {

			color = SvgIconColor.secondary
		}
		onClick = {
			it.preventDefault()
			it.stopPropagation()
			setShowModal(true)
		}
	}
//		span {
//			className = ClassName("icon")
//			svg {
//				className = ClassName("svg-icon")
//				viewBox = "0 0 24 24"
//				path {
//					d =
//						"M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"
//					fill = "#737373"
//
//
//				}
//			}
//		}
//	}
}

