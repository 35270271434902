package components

import api.VenueDetails
import api.logout
import components.help.HelpModal
import csstype.ClassName
import csstype.Display
import csstype.None
import csstype.url
import imported.googleanalitycs.gtag
import kotlinext.js.require
import kotlinx.browser.sessionStorage
import kotlinx.coroutines.launch
import kotlinx.js.jso
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import locationBase
import mainScope
import org.w3c.dom.get
import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.ul
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.useLocation
import react.router.useNavigate
import react.router.useParams
import react.useState
import kotlin.js.json


external interface LocationProps : Props {
	var isExplainer: Boolean?
	var isLong: Boolean?
	var showMenu: Boolean?
	var showBack: Boolean?

}


val location = FC<LocationProps> { props ->

	val codeProps = useParams()
	val history = useNavigate()
	val location = useLocation()
	var showHelp by useState(false)

	val get = sessionStorage["VENUE-${codeProps["code"]}"]
	val isPaytronix = sessionStorage.getItem("taoEnabled")
	val venueDetails = if (get != null) {
		Json.decodeFromString(get)
	} else
		VenueDetails("LOADING", "", "", "Loading")

	val (isMenuActive, setMenuActive) = useState(false)


	require("./styles/location.css")

	if (isPaytronix != null) {
		div {}
	} else {
		HelpModal {
			booleanShowModal = showHelp
			cancelActionPressed = { showHelp = false }
		}
		div {
			className = ClassName("section header ${if (props.isLong == true) "" else "short"}  no-top-bottom")
			a {
				className = ClassName("nav-btn back")
				style = jso {
					if (props.showBack == false) {
						display = None.none
					}
				}

				onClick = {
					it.preventDefault()
					history(-1)
				}

				div {
					className = ClassName("icon")
					svg {
						className = ClassName("svg-icon")
						viewBox = "0 0 24 24"
						path {

							d = "M20,11H7.8l5.6-5.6L12,4l-8,8l8,8l1.4-1.4L7.8,13H20V11z"
							fill = "#ffffff"
						}
					}
				}

			}
			a {
				className = ClassName(
					"nav-btn more no-select" + if (isMenuActive) {
						" active"
					} else ""
				)
				style = jso {
					display = if (props.showMenu == false) {
						None.none
					} else Display.block
				}

				onClick = {
					it.preventDefault()
					setMenuActive(!isMenuActive)
				}

				div {
					className = ClassName("icon more")
					svg {
						className = ClassName("svg-icon")
						viewBox = "0 0 24 24"
						path {
							d =
								"M6,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S7.1,10,6,10z M18,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S19.1,10,18,10z M12,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,10,12,10z"

							fill = "#ffffff"

						}
					}
				}
				div {
					className = ClassName("icon close")
					svg {
						className = ClassName("svg-icon")
						viewBox = "0 0 24 24"
						path {
							d =
								"M19,6.4L17.6,5L12,10.6L6.4,5L5,6.4l5.6,5.6L5,17.6L6.4,19l5.6-5.6l5.6,5.6l1.4-1.4L13.4,12L19,6.4z"

							fill = "#ffffff"


						}
					}
				}
			}
			ul {
				className = if (isMenuActive)
					ClassName("more-sub-nav active")
				else
					ClassName("more-sub-nav")
				li {
					a {
//						href = "https://forms.gle/jqzZSNnEBNcmynsz6"
						target = AnchorTarget._blank
						className = ClassName("help-wrap")
						rel = "noreferrer"
						onClick = {
							it.preventDefault()
							gtag(
								"event", "help_request", json(
									"location" to codeProps["code"],
									"path" to location.pathname
								)
							)
							showHelp = true
							setMenuActive(false)
						}

						p {
							className = ClassName("medium text-white font-weight-500 no-top-bottom")
							+"Need Help"
						}
					}
				}
				li {
					a {
						href = "${locationBase(codeProps)}/logout"

						onClick = {
							it.preventDefault()
							mainScope.launch {
								logout()
								history("${locationBase(codeProps)}/phone")
								setMenuActive(false)
							}
						}
						p {
							className = ClassName("medium text-white font-weight-500 no-top-bottom")
							+"Log Out"
						}
					}
				}
			}
			div {
				className = ClassName("location-img-hero")
				style = jso {
					backgroundImage = url(venueDetails.image)
				}
				div {
					className = ClassName("overlay")
				}
			}
			div {
				className = ClassName("location-profile-wrap ${if (props.isExplainer == true) "explainer" else ""}")
				div {
					className = ClassName("profile-box")
					div {
						className = ClassName("profile-outter")
					}
					div {
						className = ClassName("profile-inner")
						style = jso {
							backgroundImage = url(venueDetails.logo)
						}
					}
				}
			}
			if (props.isExplainer != true) {
				div {
					className = ClassName("section location-info no-top")
					div {
						className = ClassName("container")
						h1 {
							className = ClassName("big no-top-bottom")
							+venueDetails.name
						}
						p {
							className = ClassName("address medium less-space no-bottom")
							+venueDetails.address
						}
					}
				}
				hr {}
			}
		}
	}
}




