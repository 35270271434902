package pages.tab

import api.ActionMessage
import api.Feed
import api.closeTab
import api.collectDeviceData
import api.error
import api.log
import api.setTips
import apple.applyApplePay
import apple.canUseApplePay
import co.rooam.components.receipt.RooamReceipt
import co.rooam.components.payment.choose.ChoosePayment
import co.rooam.components.tab.live.OrderedItem
import components.divs.SectionNoTopBottom
import components.help.help
import components.location
import components.marketing.smallBanner
import components.modals.ConfirmModal
import co.rooam.components.tab.live.OrderedItems
import components.rooamFooter
import co.rooam.components.tab.tips.Tip
import co.rooam.components.receipt.ReceiptLine
import csstype.ClassName
import csstype.Color
import csstype.Display.Companion.block
import csstype.None
import csstype.integer
import csstype.px
import csstype.rem
import emotion.react.css
import co.rooam.utilities.functions.minus
import co.rooam.utilities.functions.sum
import co.rooam.utilities.functions.tipRate
import components.identity.verification.IdentityVerificationProfileBanner
import google.prepareGooglePay
import history.LocationState
import io.ktor.client.call.*
import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.js.jso
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import locationBase
import mainScope
import mui.icons.material.Payment
import mui.lab.LoadingButton
import mui.lab.LoadingPosition
import mui.material.Alert
import mui.material.AlertColor
import mui.material.ButtonVariant
import mui.material.Collapse
import mui.material.Container
import mui.material.Paper
import mui.material.PaperVariant
import mui.material.Size
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import pages.Tip
import pages.Tips
import pages.complete.TabCompleteProps
import pages.getTips
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.query.QueryKey
import react.query.useIsFetching
import react.router.NavigateFunction
import react.router.useNavigate
import react.router.useParams
import react.useEffect
import react.useMemo
import react.useState
import suspendedBrainTreeClient
import taoDiscount

external interface TabProps : Props {
	var feed: Feed
}

var payGoogleBehavior: (() -> Any)? = null
var payAppleBehavior: (() -> Unit)? = null
var tabPagedeviceData: String? = null

val tabOrdered = FC<TabProps> { props ->
	val history = useNavigate()
	val codeProps = useParams()
	val (tips, setNewTips) = useState(localStorage.getItem("TIPS")?.let {
		Json.decodeFromString(it)
	} ?: Tips(listOf(Tip(18), Tip(20), Tip(22)), Tip(20)))
	val (tipValue, setTipValue) = useState(
		tips.defaultTip.percentage to tipRate(
			sum(props.feed.totals?.subTotal, props.feed.totals?.discount),
			tips.defaultTip.percentage
		)
	)
	val (closing, setClosing) = useState(false)
	val (error, setError) = useState(false)
	val (showApplePayAlert, setShowApplePayAlert) = useState(false)
	val calculateTotal = useMemo(props, tipValue) {
		calculateTotal(props, tipValue)
	}
	val (modalShow, setShowModal) = useState(false)
	val useIsFetching = useIsFetching(QueryKey<QueryKey>("feed"))
	val isPaytronix = sessionStorage.getItem("taoEnabled")
	useEffect {
		if (localStorage.getItem("TIPS") == null) {
			mainScope.launch {
				val receive = getTips().body<Tips>()
				setTips(receive)
				setNewTips(receive)
				if (tipValue.first != -1) {
					if (receive.tips.none { it.percentage == tipValue.first }) {
						setTipValue(
							receive.defaultTip.percentage to tipRate(
								sum(props.feed.totals?.subTotal, props.feed.totals?.discount),
								receive.defaultTip.percentage
							)
						)
					}
				}
			}
		}
	}
	useEffect(calculateTotal) {
		mainScope.launch {
			val braintreeClientAwaited = suspendedBrainTreeClient.await()
			tabPagedeviceData = collectDeviceData(braintreeClientAwaited).await().deviceData
			payGoogleBehavior = prepareGooglePay(

				calculateTotal,
				{ mainScope.async { } },
				{
					setClosing(it)
					if (!it) {
						setError(!it)
					}
				}
			) {
				closeTab(codeProps["code"], tipValue, it, tabPagedeviceData, {
					setError(true)
					setClosing(false)
					error(

						ActionMessage("close_tab", "Closing tab with tip = $tipValue , $calculateTotal FAILED")
					)
				}, { history("../overdue") }
				) {
					log(ActionMessage("close_tab", "Closing tab with tip = $tipValue success $it"))
					pushToComplete(history)
				}
			}.await()
			payAppleBehavior = applyApplePay(
				{ },
				calculateTotal,
				{ _, _ -> mainScope.async { } },
				{
					setClosing(it)
					if (!it) {
						setError(!it)
					}
				}) {

				closeTab(codeProps["code"], tipValue, it, tabPagedeviceData, {
					setError(true)
					setClosing(false)
					error(
						ActionMessage("close_tab", "Closing tab with tip = $tipValue , $calculateTotal FAILED")
					)
				},
					{ history("../overdue") }) {
					log(
						ActionMessage("close_tab", "Closing tab with tip = $tipValue success $it")
					)
					pushToComplete(history)
				}
			}.await()
		}

	}


	SectionNoTopBottom {
		div {
			style = jso {
				display = if (showApplePayAlert) block else None.none
			}
			ConfirmModal {
				showIcon = false
				title = "Apple Pay unavailable."
				text =
					"You cannot use Apple Pay with current browser. Please switch to Safari or choose another payment method."
				confirmButtonText = "Change"
				confirmActionPressed = {
					setShowApplePayAlert(false)
					history("${locationBase(codeProps)}/select")
				}
				cancelActionPressed = {
					setShowApplePayAlert(false)
				}
			}
		}
		div {
			style = jso {
				display = if (modalShow) block else None.none
			}
			ConfirmModal {
				showIcon = true
				title = "Are you sure?"
				text =
					"Make sure to check your items and your tip before closing your tab. When you are ready, tap 'Confirm' to close you tab."
				booleanShowModal = modalShow
				this.cancelActionPressed = {
					setShowModal(false)
				}
				this.confirmActionPressed = {
					setClosing(true)
					setError(false)
					setShowModal(false)
					if (props.feed.paymentMethod?.type == "GOOGLE_PAY") {
						payGoogleBehavior?.invoke()
					} else if (props.feed.paymentMethod?.type == "APPLE_PAY") {
						payAppleBehavior?.invoke()
					} else {
						setClosing(true)
						setError(false)
						closeTab(codeProps["code"], tipValue, null, tabPagedeviceData,
							{
								setError(true)
								setClosing(false)
								error(
									ActionMessage(
										"close_tab",
										"Closing tab with tip = $tipValue , $calculateTotal FAILED"
									)
								)
							},
							{ history("${locationBase(codeProps)}/overdue") },
							{
								log(
									ActionMessage("close_tab", "Closing tab with tip = $tipValue , $calculateTotal")
								)
								pushToComplete(history)
							})
					}
				}

			}
		}
		location {
			showBack = false
		}
		IdentityVerificationProfileBanner {}
		smallBanner {
		}
		div {
			className = ClassName("section")
			div {
				className = ClassName("container text-center")
				p {
					className = ClassName("huge text-white no-top-bottom")
					+"${props.feed.tab?.tabNumber}"
				}
				p {
					css(ClassName("no-bottom")) {
						color = Color("#bebebe")
					}
					+"Tab Number"
				}
			}
		}

		Container {
			OrderedItems {
				items = props.feed.tab?.items as Array<OrderedItem>  ?: arrayOf()
			}
		}

		Container {
			sx {
				marginBottom = 20.px
			}
			Typography {
				variant = TypographyVariant.h3
				sx {
					paddingTop = 0.px
				}
				+"Select Tip Amount"
			}
			Tip {

				this.subtotal = props.feed.totals?.subTotal ?: "0.00"
				this.tips =
					tips.tips.associate { it.percentage to tipRate(props.feed.totals?.subTotal, it.percentage) }


				this.chosenTip = tipValue
				this.onTipChange = { tipNumber, value ->
					setTipValue(tipNumber to value)
				}

			}
		}

		val totals = props.feed.totals
		if (isPaytronix != null) {
			taoDiscount {

				subtotal = props.feed.totals?.subTotal
				discount = props.feed.totals?.discount

			}
		}
		div {
			css {
				marginBottom = 20.px
			}
			RooamReceipt {

				this.tip = if (tipValue.first in setOf(18, 20, 22)) tipRate(
					sum(props.feed.totals?.subTotal, props.feed.totals?.discount),
					tipValue.first
				) else tipValue.second
				this.taxesFees = sum(
					sum(
						totals?.tax,
						totals?.fee
					),
					totals?.serviceCharges
				)
				this.serviceCharges = totals?.serviceCharges
				this.credit = totals?.credit
				this.tax = totals?.tax ?: "0.00"
				this.fees = totals?.fee ?: "0.00"
				this.subtotal = totals?.subTotal ?: "0.00"
				this.discount = totals?.discount ?: "0.00"

			}
		}
		Container {
			sx {
				marginBottom = 20.px
			}
			ChoosePayment {

				this.payment = props.feed.paymentMethod!!

			}
		}

		Paper {
			square = true
			elevation = 0
			variant = PaperVariant.outlined
			sx {
				paddingTop = 20.px
				paddingBottom = 20.px
				marginBottom = 20.px
			}
			Container {

				ReceiptLine {
					title = "Total:"
					value = calculateTotal
					sx {
						fontSize = 1.rem
						fontWeight = integer(600)

					}
				}
			}
		}
	}
	Collapse {
		`in` = error
		Alert {
			severity = AlertColor.error
			span {
				css {
					fontWeight = integer(600)
				}
				+"Issue Closing Tab. "
			}
			+"Please try again."
		}
	}
	Container {
		LoadingButton {
			variant = ButtonVariant.contained
			size = Size.large
			loading = closing
			disabled = modalShow
			sx {
				textTransform = None.none
			}
			startIcon = Payment.create()
			onClick = {

				it.preventDefault()
				if (props.feed.paymentMethod?.type == "APPLE_PAY" && !canUseApplePay()) {
					setShowApplePayAlert(true)
				} else
					setShowModal(true)
			}
			loadingPosition = LoadingPosition.start

			fullWidth = true

			if (closing) {
				+"Closing Tab..."
			} else {
				+"Close Tab: \$$calculateTotal"
			}

		}
		div {
			className = ClassName("link-box text-center no-bottom")
			p {
				className = ClassName("small text-grey no-top-bottom")
				+"Tabs left open will auto-close with a 20% tip."
			}
		}

	}

	help {
		isLess = false
	}
	rooamFooter {}

}

private fun pushToComplete(history: NavigateFunction) {
	history(
		"../complete",
		jso { state = jso<TabCompleteProps> { showFeedback = true } as LocationState })
}


private fun calculateTotal(props: TabProps, tipValue: Pair<Int, String>) =
	if (props.feed.totals != null) {
		with(props.feed.totals!!) {
			minus(sum(sum(sum(sum(subTotal, tax), fee), serviceCharges), tipValue.second), credit)
		}
	} else tipValue.second
