package components.explainer

import csstype.ClassName
import csstype.px
import kotlinx.js.jso
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1

val floatText = FC<Props> {

	div {
		className = ClassName("container text-center")
		h1 {
			className = ClassName("big float-middle no-bottom")
			style = jso {
				maxWidth = 420.px
			}
			+"Open a tab without ever handing over your credit card."
		}
	}

}
