package components.tao.api

import api.addSessionHeader
import api.addSessionStaticHeader
import api.client
import api.storage.storeRooamHeader
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import mainScope
import kotlin.js.Promise

fun getRewards(apply: (TaoRewardsResponse) -> Unit) = mainScope.launch {
	client.get("tao")
	{
		header("Content-Type", ContentType("application", "json"))
		addSessionStaticHeader()
		expectSuccess = false
	}.apply { apply(body()) }

}


fun getRewardsQuery(): Promise<TaoRewardsResponse> = mainScope.async {
	client.get("tao") {
		header("Content-Type", ContentType("application", "json"))
		addSessionStaticHeader()
		expectSuccess = true
	}.body<TaoRewardsResponse>()
}.asPromise()


fun redeemRewards(dollars: String, apply: (TaoRedeemStatus) -> Unit) {
	mainScope.launch {
		apply(
			client.post(
				"/tao",

				) {
				setBody(body = TaoRedeemRequest(dollars))
				header("Content-Type", ContentType("application", "json"))
				addSessionHeader()
				expectSuccess = false
			}.also { it.storeRooamHeader() }
				.body()
		)
	}
}


fun redeemRewardsMutation(dollars: String) = mainScope.async {
	client.post(
		"/tao",

		) {
		setBody(body = TaoRedeemRequest(dollars))
		header("Content-Type", ContentType("application", "json"))
		addSessionHeader()
		expectSuccess = false
	}.also { it.storeRooamHeader() }
		.body<TaoRedeemStatus>()

}.asPromise()


@Serializable
data class TaoRedeemRequest(val amount: String)


@Serializable
data class TaoRewardsResponse(val amount: String? = null, val used: String? = null)

@Serializable
data class TaoRedeemStatus(val status: String)
