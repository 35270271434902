package co.rooam.utilities.functions

import kotlinx.browser.window

fun isInstalled(): Boolean {
	if (window.navigator.asDynamic().standalone) return true

	if (window.matchMedia("(display-mode: standalone)").matches ||
		window.matchMedia("(display-mode: fullscreen)").matches
	) return true

	return false
}
