package components.help

import components.divs.container
import csstype.BackgroundColor
import csstype.ClassName
import csstype.None
import csstype.px
import csstype.rem
import imported.googleanalitycs.gtag
import mui.icons.material.QuestionMark
import mui.material.Avatar
import mui.material.Button
import mui.material.ButtonColor
import mui.material.SvgIconColor
import mui.material.SvgIconSize
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.div
import react.router.useLocation
import react.router.useParams
import react.useState
import kotlin.js.json

external interface HelpProps : Props {
	var isLess: Boolean?
}

val help = FC<HelpProps> { props ->
	val codeProps = useParams()
	val location = useLocation()
	val theme = useTheme<Theme>()
	var showHelp by useState(false)
	div {
		className = ClassName("section${if (props.isLess == true) " less" else ""} no-bottom")

		container {
			Button {
				color = ButtonColor.secondary
				sx {
					textTransform = None.none
				}
				startIcon = Avatar.create {
					sx {
						width = 32.px
						height = 32.px
						backgroundColor = theme.palette.background.paper.unsafeCast<BackgroundColor>()
					}
					QuestionMark {
						fontSize = SvgIconSize.small
						color = SvgIconColor.secondary
						sx {
							fontSize = 0.75.rem
						}
					}
				}
				onClick = {
					it.preventDefault()
					gtag(
						"event", "help_request", json(
							"location" to codeProps["code"],
							"path" to location.pathname
						)
					)
					showHelp = true
				}
				+"Need Help?"
			}
		}
	}
	HelpModal {
		booleanShowModal = showHelp
		cancelActionPressed = { showHelp = false }
	}
}

