package api

import api.storage.storeRooamHeader
import imported.googleanalitycs.gtag
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.w3c.dom.get
import kotlin.js.json

suspend fun linkExperimentToUser(campaignId: String, experiment: Experiment) {
	if (!isUserLoggedInAndHasTabOpen()) return

	console.log("Linking experiment to user and campagin: $campaignId  $experiment")

	client.post {
		url(path = "/marketing/campaign/$campaignId/experiment/link")
		setBody(experiment)
		addSessionHeader()
		header("Content-Type", ContentType("application", "json"))
	}.also {
		it.storeRooamHeader()
	}
}

private fun isUserLoggedInAndHasTabOpen(): Boolean {
	if (localStorage[SessionConstants.rooamSessionHeader].isNullOrBlank()) return false
	if (localStorage["FEED"].isNullOrBlank()) return false
	return true
}

suspend fun getHideBannerABTest(campaignId: String, completion: suspend (HideBannerABTest) -> Unit) {
	val hideBannerABTest = hideBannerABTest()
	if (hideBannerABTest.active) {
		gtag("event", "ab_test_active", hideBannerABTest.json)
		console.log("ABTest Active: ${JSON.stringify(hideBannerABTest.json)}")

		linkExperimentToUser(campaignId, hideBannerABTest.toExperiment())
	}
	completion(hideBannerABTest)
}

fun hideBannerABTest(): HideBannerABTest {
	val active = !sessionStorage["HIDE_MARKETING_CAMPAIGN_BANNER"].isNullOrBlank()
	val hideBanner = sessionStorage["HIDE_MARKETING_CAMPAIGN_BANNER"]?.let { it.toBoolean() } ?: false
	return HideBannerABTest(key = "HIDE_MARKETING_CAMPAIGN_BANNER", active = active, value = hideBanner)
}

@Serializable
@SerialName("HIDE_MARKETING_CAMPAIGN_BANNER")
data class HideBannerABTest(
	override val key: String,
	val active: Boolean,
	val value: Boolean
) : ABTest() {
	val json: kotlin.js.Json = json(
		"type" to key,
		"active" to active,
		"value" to value
	)
}

@Serializable
abstract class ABTest {
	abstract val key: String
}

@Serializable
data class Experiment(val key: String, val value: String)

fun HideBannerABTest.toExperiment(): Experiment = Experiment(key, value.toString())
