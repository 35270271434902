package co.rooam.components.receipt

import csstype.integer
import csstype.rem
import mui.material.Container
import mui.material.Divider
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.sx
import react.FC
import react.Props


external interface ReceiptProps : Props {
	var fees: String
	var tip: String
	var tax: String
	var serviceCharges: String?
	var credit: String?
	var subtotal: String
	var discount: String
	var taxesFees: String
}

val RooamReceipt = FC<ReceiptProps>("receipt") { props ->
	val theme = useTheme<Theme>()

		if (props.discount != "0.00") {

			ReceiptLine {
				title = "Discount:"
				value = props.discount
				positive = true
			}

		}

		ReceiptLine {
			title = "Subtotal:"
			value = props.subtotal
			greenText = if (props.discount != "0.00") {
				" (with discounts) "
			} else null
		}


		ReceiptLine {
			title = "Taxes & Fees"
			value = props.taxesFees
			Divider {}

			ReceiptLine {
				title = "Tax:"
				value = props.tax
			}

			if (props.serviceCharges != null && props.serviceCharges != "0.00") {

				ReceiptLine {
					title = "Service Charges:"
					value = props.serviceCharges!!
				}

			}
			ReceiptLine {
				title = "Fees"
				value = props.fees
				expanded = true
				Typography {
					sx{
						color=theme.palette.text.secondary
						fontSize = 0.7.rem
						fontWeight = integer(400)
					}
					+"This fee helps cover the convenience costs of mobile payments, additional security to prevent fraud and maintain a high EMV level to stay PCI compliant."
				}
			}


			Divider {}
		}
		if (props.credit != null && props.credit != "0.00")
			ReceiptLine {
				title = "Credit:"
				value = props.credit!!
				positive = true
			}


		ReceiptLine {
			title = "Tip:"
			value = props.tip
		}

	}



