package components.identity.verification

import api.IdentityVerificationProfile
import csstype.*
import getIdentityProfileQuery
import mui.icons.material.Check
import mui.icons.material.Clear
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.VFC
import react.useEffect
import react.useState

val IdentityVerificationProfileBanner = VFC {
	val successColor = "#66bb6a"
	val failureColor = "#d9211b"

	var identityProfileQuery = getIdentityProfileQuery()
	var profile by useState<IdentityVerificationProfile?>(null)
	var ageVerifiedColor by useState(Color(successColor))

	useEffect(identityProfileQuery.data) {
		(identityProfileQuery.data as? IdentityVerificationProfile)?.let {
			ageVerifiedColor = if (it.ageVerified) Color(successColor) else Color(failureColor)
			profile = it
		}
	}

	Collapse {
		`in` = profile != null
		Paper {
			id = "idCheckBanner"
			sx {
				backgroundColor = NamedColor.white
				borderRadius = 0.px
				display = Display.flex
			}
			Stack {
				id = "idCheckBannerStack"
				spacing = responsive(1)
				sx {
					width = 100.pct
					alignItems = AlignItems.center
				}

				Stack {
					spacing = responsive(-1)
					sx {
						padding = Padding(10.px, 20.px)
						width = 100.pct
						alignItems = AlignItems.center
					}
					Avatar {
						sx {
							width = 82.px
							height = 82.px
						}
						src = profile?.faceImageUrl
					}
					Avatar {
						sx {
							color = NamedColor.white
							backgroundColor = ageVerifiedColor
							border = Border(1.px, LineStyle.solid, NamedColor.white)
							width = 24.px
							height = 24.px
							marginLeft = Auto.auto
						}
						Check {
							sx {
								width = 15.px
								height = 15.px
								display = if (profile?.ageVerified == true) Display.block else None.none
							}
						}
						Clear {
							sx {
								width = 15.px
								height = 15.px
								display = if (profile?.ageVerified == false) Display.block else None.none
							}
						}
					}
				}
				Typography {
					sx {
						fontWeight = integer(600)
						lineHeight = 1.em
						fontSize = 0.85.em
						color = Color("#595959")
					}
					+(profile?.name() ?: "")
				}
				Typography {
					sx {
						fontWeight = integer(600)
						lineHeight = 0.3.em
						fontSize = 2.em
						color = ageVerifiedColor
						paddingBottom = 20.px
					}
					+"AGE VERIFIED"
				}
			}
		}
	}
}

private fun IdentityVerificationProfile.name(): String = (if (lastName != null) "$lastName, " else "") + firstName
