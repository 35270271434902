package components.apple

import apple.canUseApplePay
import components.modals.AlertModal
import csstype.ClassName
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.launch
import mainScope
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.g
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.useState

external interface ApplePayButtonProps : Props {
	var appleBehavior: Deferred<(() -> dynamic)>

}


val applePayButton = FC<ApplePayButtonProps>("applePayButton") { props ->

	var showApplePayAlert by useState(false)
	val closeAlertModal = { showApplePayAlert = false }
	div {
		className = ClassName("column")
		div {
			className = ClassName("padding")
			a {
				className = ClassName("btn brand white")

				id = "apple-touch"

				onClick = {
					it.preventDefault()
					if (canUseApplePay()) {
						mainScope.launch { props.appleBehavior.await().invoke() }
					} else {
						showApplePayAlert = true
					}
				}


				span {
					className = ClassName("brand")
					svg {
						className = ClassName("svg-icon")
						viewBox = "0 0 49.03 20.128"
						g {
							transform = "translate(0)"
							id = "XMLID_44_"
							path {
								d =
									"M67.139,2.594A3.645,3.645,0,0,0,67.977,0a3.575,3.575,0,0,0-2.392,1.242,3.411,3.411,0,0,0-.858,2.493,3.012,3.012,0,0,0,2.413-1.141"
								transform = "translate(-58.177)"

								id = "XMLID_46_"
							}
							path {
								d =
									"M9.8,38.751c-1.332-.081-2.463.757-3.1.757s-1.615-.717-2.665-.7A3.933,3.933,0,0,0,.7,40.841C-.735,43.3.315,46.968,1.708,48.977c.676,1,1.494,2.09,2.564,2.049,1.02-.04,1.413-.656,2.645-.656s1.595.656,2.665.636c1.11-.02,1.807-1,2.483-1.989a8.66,8.66,0,0,0,1.11-2.291A3.617,3.617,0,0,1,11,43.445a3.716,3.716,0,0,1,1.746-3.089,3.741,3.741,0,0,0-2.948-1.6"
								transform = "translate(-0.014 -34.835)"

								id = "XMLID_45_"
							}
						}
						g {
							transform = "translate(17.482 1.141)"
							id = "XMLID_35_"
							path {
								d =
									"M178.8,11.3a4.687,4.687,0,0,1,4.916,4.906,4.738,4.738,0,0,1-4.987,4.926h-3.21v5.1H173.2V11.3Zm-3.281,7.884h2.655c2.019,0,3.17-1.09,3.17-2.968s-1.151-2.958-3.159-2.958h-2.665Z"
								transform = "translate(-173.2 -11.3)"

								id = "XMLID_41_"


							}
							path {
								d =
									"M283.1,58.473c0-1.918,1.464-3.008,4.149-3.18l2.887-.172v-.828c0-1.211-.8-1.878-2.211-1.878a2.022,2.022,0,0,0-2.19,1.524h-2.089C283.706,52,285.533,50.6,288,50.6c2.645,0,4.381,1.383,4.381,3.543v7.429h-2.14V59.786h-.05a3.813,3.813,0,0,1-3.412,1.908C284.6,61.673,283.1,60.391,283.1,58.473Zm7.036-.969v-.838l-2.574.162c-1.454.091-2.2.636-2.2,1.575,0,.908.787,1.5,2.019,1.5A2.523,2.523,0,0,0,290.136,57.5Z"
								transform = "translate(-272.006 -46.633)"
								id = "XMLID_38_"
							}
							path {
								d =
									"M384.78,66.728v-1.8a6.8,6.8,0,0,0,.686.04,1.852,1.852,0,0,0,1.958-1.555l.212-.666L383.7,51.9h2.423l2.736,8.812h.05l2.736-8.812h2.362l-4.078,11.417c-.929,2.614-2,3.472-4.25,3.472A6.391,6.391,0,0,1,384.78,66.728Z"
								transform = "translate(-362.452 -47.802)"

								id = "XMLID_36_"
							}
						}
					}
				}
			}
		}
		AlertModal{
			closeAlert = closeAlertModal
			title = "Apple Pay unavailable."
			text = "You cannot use Apple Pay with current browser. Please switch to Safari or choose another payment method."
			alertShow = showApplePayAlert
		}

	}

}
