package components.explainer

import csstype.ClassName
import csstype.px
import imported.swiper.Pagination
import imported.swiper.Swiper
import imported.swiper.SwiperSlide
import kotlinx.js.jso
import react.FC
import react.Props
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p



@JsModule("./images/explainer/how-it-works/screens/WebTab-Phone.png")
@JsNonModule
external val webTabPhoneImage: dynamic

@JsModule("./images/explainer/how-it-works/screens/WebTab-Connect-Payment.png")
@JsNonModule
external val webTabPayment: dynamic

@JsModule("./images/explainer/how-it-works/screens/WebTab-Empty.png")
@JsNonModule
external val webTabEmptyImage: dynamic

@JsModule("./images/explainer/how-it-works/screens/WebTab-Full.png")
@JsNonModule
external val webTabWithItems: dynamic

@JsModule("./images/explainer/how-it-works/screens/WebTab-Full-Scrolled.png")
@JsNonModule
external val webTabWithItemsScrolled: dynamic

@JsModule("./images/explainer/how-it-works/screens/WebTab-Complete.png")
@JsNonModule
external val webTabComplete: dynamic


val howItWorks = VFC {
	kotlinext.js.require("swiper/css")
	kotlinext.js.require("swiper/css/pagination")
	kotlinext.js.require("./styles/how-it-works.css")
	Swiper {
		modules = arrayOf(Pagination)
		autoHeight = true
		spaceBetween = 0
		slidesPerView = 1
		loop = false
		grabCursor = true
		pagination = jso { clickable = true }

		SwiperSlide {
			singleSlide {
				imgSrc = webTabPhoneImage
				alt = "Verify your phone number"
				title = "Verify your mobile number"
				text = "Enter your mobile number, and you'll receive an authentication code to verify your device."
				maxText = 420
			}
		}
		SwiperSlide {
			singleSlide {
				imgSrc = webTabPayment
				alt = "Connect a payment"
				title = "Connect a payment"
				text =
					"Connect a payment instantly using a mobile wallet (Apple Pay, Google Pay, etc.), or manually enter your credit card."
				maxText = 440
			}
		}

		SwiperSlide {
			singleSlide {
				imgSrc = webTabEmptyImage
				alt = "Order from the bartender"
				title = "Order from the bartender"
				text =
					"Now your tab is instantly opened at every bar. Show your tab number and order items from any bartender at any bar."
			}
		}

		SwiperSlide {
			singleSlide {
				imgSrc = webTabWithItems
				alt = "Items will automatically appear on web tab"
				title = "Items will automatically appear on web tab"
				text =
					"Bartender adds items to your tab from the point-of-sale, and items will display in real-time on your Web Tab."
				maxText = 420
			}
		}

		SwiperSlide {
			singleSlide {
				imgSrc = webTabWithItemsScrolled
				alt = "Ready to go? Select tip and close tab"
				title = "Ready to go? Select tip and close tab"
				text =
					"At the end of the night, make sure to check your ordered items and your tip amount before closing your tab."
			}
		}
		SwiperSlide {
			singleSlide {
				imgSrc = webTabComplete
				alt = "Get an email receipt"
				title = "Get an email receipt"
				text =
					"Your tab is now closed on the point-of-sale, and payment is complete. Enter your email address to receive a receipt."
			}
		}
	}
}


external interface SingleSlideProps : Props {
	var imgSrc: String
	var alt: String
	var title: String
	var text: String
	var maxText: Int?
}

val singleSlide = FC<SingleSlideProps> { props ->
	div {
		className = ClassName("section banner-bg")
		div {
			className = ClassName("phone-device")
			img {
				src = props.imgSrc
				alt = props.alt
			}
		}
	}
	div {
		className = ClassName("section")
		div {
			className = ClassName("container text-center")
			h3 {
				className = ClassName("no-top")
				+props.title
			}
			p {
				className = ClassName("float-middle no-top-bottom")
				props.maxText?.let {
					style = jso {
						maxWidth = it.px
					}
				}
				+props.text
			}
		}
	}
}
