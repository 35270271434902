package components.summary

import api.TabItem
import components.divs.SectionNoTopBottom
import components.divs.container
import components.item.ItemSmall
import csstype.ClassName
import emotion.css.cx
import react.FC
import react.Props
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul
import react.useState

external interface SummaryItemsProps : Props {
	var items: List<TabItem>
}

sealed interface ViewTitles {
	fun next(): ViewTitles
	fun text(): String
}

object ViewItems : ViewTitles {
	override fun next(): ViewTitles = HideItems

	override fun text(): String = "View Items"
}

object HideItems : ViewTitles {
	override fun next(): ViewTitles = ViewItems

	override fun text(): String = "Hide Items"
}

val SummaryItems = FC<SummaryItemsProps>("Summary Items") { props ->
	var title by useState(ViewItems as ViewTitles)
	kotlinext.js.require("./styles/ordered-items.css")
	div {
		className = ClassName("section less no-bottom")
		container {
			button {
				className = ClassName("btn no-animation small outline white")
				if (title == HideItems) {
					className = cx(className, ClassName(" active"))
				}

				onClick = {
					it.preventDefault()
					title = title.next()
				}
				+title.text()
			}
		}
	}
	div {


		className = cx(
			ClassName("section"),
			ClassName(" summary-items"), ClassName(" less"),
			ClassName(" no-bottom"),
			if (title == HideItems) {
				ClassName(" active")
			} else null
		)

		container {
			SectionNoTopBottom{
				ul {
					className = ClassName("ordered-items small clearfix")
					for (item in props.items) {
						li {
							ItemSmall {
								itemName = item.name
								itemTotal = item.price
								itemPrice = item.pricePerUnit
								quantity = item.quantity
							}
						}
					}
				}
			}
		}
	}
}
