package pages.tab

import api.Feed
import api.IdentityVerificationProfile
import api.Tab
import components.fullPageLoading
import components.layout
import components.qr.QrScanner
import components.seo
import csstype.Color
import getIdentityProfileQuery
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.http.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import kotlinx.js.jso
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import mainScope
import org.w3c.dom.get
import pages.getSingleFeedWithoutError
import react.Props
import react.VFC
import react.query.QueryFunctionContext
import react.query.QueryKey
import react.query.UseQueryOptions
import react.query.useQuery
import react.router.NavigateFunction
import react.router.useNavigate

external interface TabEmptyProps : Props {
	var tabNumber: String?
}

typealias QueryOptions = UseQueryOptions<Feed, ResponseException, Feed, QueryKey>

val feedQuery = QueryKey<QueryKey>("feed")

val tab = VFC("TabOpenPage") {

	val history = useNavigate()
	val useQuery = useQuery(
		feedQuery, pullFeed,
		jso<QueryOptions> {
			refetchInterval = 10000
			initialData = localStorage.get("FEED")?.let { Json.decodeFromString<Feed>(it) }
			onSuccess = history.processSuccess()
			onError = history.processError()
			retry = retryDefinition()

		}
	)
	layout {
		seo {
			titleTemplate = "Your Tab - %s"
		}
		if (useQuery.isLoading) {
			fullPageLoading {}
		} else {
			val liveFeed = useQuery.data ?: Feed(tab = Tab(items = arrayOf(), tabNumber = null))
			if (liveFeed.tab!!.items.isEmpty()) {
				tabEmpty {
					tabNumber = liveFeed.tab.tabNumber

				}
			} else {
				tabOrdered {
					feed = liveFeed
				}
			}
		}
		QrScanner{}
	}
}

private fun retryDefinition() = fun(count: Int, error: ResponseException): Boolean = if (count > 6) false
else
	!(count > 2 && error.response.status == HttpStatusCode.Unauthorized)

private fun NavigateFunction.processError(): (ResponseException) -> Unit =
	{ this("../phone") }


private fun NavigateFunction.processSuccess(): (Feed) -> Unit = {
	if (it.voided) {
		this("../voided")
	} else {
		if (!it.open)
			this("../complete")
	}
	localStorage.setItem("FEED", Json.encodeToString(it))
}


val pullFeed = { context: QueryFunctionContext<QueryKey, *> ->
	mainScope.async<Feed> {
		getSingleFeedWithoutError().body()
	}.asPromise()
}



