package pages

import api.ApiPayment
import api.getPayments
import api.updatePayment
import apple.applyApplePay
import apple.canUseApplePay
import co.rooam.components.payment.Payment
import components.animations.ErrorMessage
import components.divs.SectionNoTopBottom
import components.divs.container
import components.error.StandardError
import components.help.help
import components.layout
import components.location
import components.marketing.smallBanner
import components.modals.AlertModal
import components.paymentList
import components.rooamFooter
import components.seo
import csstype.ClassName
import csstype.Display
import csstype.None
import google.prepareGooglePay
import imported.googleanalitycs.gtag
import io.ktor.client.plugins.*
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.js.jso
import mainScope
import react.FC
import react.Props
import react.StateSetter
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.query.QueryKey
import react.query.UseQueryOptions
import react.query.useQuery
import react.router.NavigateFunction
import react.router.Params
import react.router.useNavigate
import react.router.useParams
import react.useEffectOnce
import react.useState
import kotlin.js.json

external interface ErrorData : Props {
	var errorMessage: Boolean?
}

typealias PaymentsQueryOptions = UseQueryOptions<Array<Payment>, ResponseException, Array<Payment>, QueryKey>


external interface SelectPaymentProps : Props {
	var navigate: (NavigateFunction, Params, StateSetter<Boolean>, StateSetter<Boolean>) -> Unit
	var showBack: Boolean?
	var error: ErrorData?
	var showDelete: Boolean?
	var errorMessageText: String?
}

var appleBehavior: (() -> Any)? = null
var googleBehavior: (() -> Any)? = null

val SelectPayment = FC<SelectPaymentProps> { props ->
	val history = useNavigate()
	val codeProps = useParams()
	val (loading, setLoading) = useState(false)
	val (showApplePayAlert, setShowApplePayAlert) = useState(false)

	val (error, setError) = useState(props.error?.errorMessage ?: false)

	var selectedPayment by useState<Payment?>(null)

	val paymentsQuery = useQuery(
		QueryKey<QueryKey>("payments"), getPayments,
		jso<PaymentsQueryOptions> {
			initialData = arrayOf(
				ApiPayment(
					label = "Loading Payments...",
					type = "LOADING",
					default = false,
					id = "NONE",
					image = ""
				)
			)
			onError = { history("../phone") }
			onSuccess ={
				console.log( it.find { payment -> payment.default } )
				selectedPayment = it.find { payment -> payment.default }
			}
			retry = { _, _ -> false }
		}
	)


	useEffectOnce {
		mainScope.launch {
			console.log("reallyOnce")
			googleBehavior = prepareGooglePay(
				null,
				{ mainScope.async { } },
				{
					setLoading(it)
					if (!it) {
						setError(!it)
					}

				}
			) {
				props.navigate(
					history,
					codeProps,
					setError,
					setLoading
				)
			}.await()
			appleBehavior = applyApplePay(
				{ },
				null,
				{ _, _ -> mainScope.async { } },
				{
					setLoading(it)
					if (!it) {
						setError(!it)
					}
				}) {
				props.navigate(
					history, codeProps, setError,
					setLoading
				)
			}.await()
		}

	}

	layout {
		seo { titleTemplate = "Select A Payment - %s" }
		SectionNoTopBottom {
			location {
				showBack = props.showBack
			}
		}
		AlertModal {
			this.title = "Apple Pay unavailable."
			this.text =
				"You cannot use Apple Pay with current browser. Please switch to Safari or choose another payment method."
			this.closeAlert = {
				setShowApplePayAlert(false)
				setLoading(false)
			}
			this.alertShow = showApplePayAlert
		}
		div {
			className = ClassName("section less no-bottom")




			style = jso {
				display = if (error) Display.block else None.none
			}
			container {
				div {
					className = ClassName("message error")
					ErrorMessage {
						icon = StandardError
						title = (props.errorMessageText ?: "Issue connecting payment.")
						text = " Please try again."

					}
				}
			}
		}
		smallBanner {
		}
		div {
			className = ClassName("section")
			container {
				h1 {
					className = ClassName("no-top-bottom")
					+"Select Payment."
				}
			}
		}
		SectionNoTopBottom {
			container {
				paymentList {
					this.payments = paymentsQuery.data!!
					this.onPaymentSelect = {
						selectedPayment = it
					}
					this.showDelete = props.showDelete

				}
			}
		}
		div {
			className = ClassName("section large no-bottom")
			container {
				button {
					className = ClassName("btn red ${if (loading) "disabled" else ""}")

					onClick = {
						it.preventDefault()
						gtag("event", "payment_select", json())
						setLoading(true)
						setError(false)
						if (selectedPayment?.type == "GP") {
							googleBehavior?.invoke()
						} else if (selectedPayment?.type == "AP") {
							if (
								canUseApplePay()
							) {
								appleBehavior?.invoke()
							} else {
								setShowApplePayAlert(true)
							}
						} else {

							selectedPayment?.let<Payment, Unit> {
								if (selectedPayment != paymentsQuery.data!!.find { it.default }
								) {
									updatePayment(it, {
										setError(true)
										setLoading(false)
									}, {
										props.navigate(
											history, codeProps, setError,
											setLoading
										)
									})
								} else {
									props.navigate(
										history, codeProps, setError,
										setLoading
									)
								}
							}
						}

					}


					+if (loading) "Please Wait..." else "Connect"
				}
			}
		}
		help { }
		rooamFooter { }
	}
}



