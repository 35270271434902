package styles


import csstype.*
import kotlinx.js.jso

object Toggle {
	val wrapper = emotion.css.css(jso {
		display = Display.inlineBlock
		width = 44.px
		height = 26.px
		backgroundColor = Color("#737373")
		top = 50.pct
		right = 15.px
		marginTop = (-13).px
		borderRadius = 26.px
		position = Position.absolute
	}
	)

	fun toggle(active: Boolean) = emotion.css.css(jso
	{
		display = Display.inlineBlock
		width = 22.px
		height = 22.px
		left = 2.px
		top = 50.pct
		marginTop = (-11).px
		backgroundColor = NamedColor.white
		borderRadius = 100.pct
		position = Position.absolute
		transition = 250.ms
//		transition(duration = 250.ms)
		transform = translate(if (active) 18.px else 0.px)

	})

	val active = emotion.css.css(jso
	{
		backgroundColor = Color("#3EC18E")
	})
	val toggleActive = emotion.css.css(jso
	{


	})
}
/*
.rewards-wrap .toggle-wrap {
    display: inline-block;
    width: 44px;
    height: 26px;
    background-color: #737373;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -13px;
    -webkit-border-radius: 26px;
            border-radius: 26px;
}
.rewards-wrap .toggle-wrap .toggle {
    display: inline-block;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 50%;
    left: 2px;
    margin-top: -11px;
    background-color: #ffffff;
    -webkit-border-radius: 100%;
            border-radius: 100%;
}
.rewards-wrap .toggle-wrap.active {
    background-color: #3EC18E;
}
.rewards-wrap .toggle-wrap:focus, .rewards-wrap .toggle-wrap:hover {
    background-color: #535353;
}
.rewards-wrap .toggle-wrap.active:focus, .rewards-wrap .toggle-wrap.active:hover {
    background-color: #3EC18E;
}
.rewards-wrap .toggle-wrap.active .toggle {
    left: auto;
    right: 2px;
}
.rewards-wrap .btn.rewards.active {
    background-color: #3EC18E;
    color: #000000;
}

 */
