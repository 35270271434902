package apple

import api.ActionMessage
import api.collectDeviceData
import api.error
import api.log
import api.postPayment
import imported.applepay.ApplePaySession
import imported.braintree.apple.braintreeApplePay
import kotlinx.browser.window
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.js.jso
import mainScope
import suspendedBrainTreeClient

fun canUseApplePay() = if (!window.asDynamic().ApplePaySession) {
	false
} else window.asDynamic().ApplePaySession.canMakePayments()


val applyApplePay = { appleNotAvailable: (Boolean) -> Unit,
                      totalPay: String?,
                      storeName: suspend (String, String) -> Unit,
                      loading: (Boolean) -> Unit,
                      navigation: (String) -> Unit
	->
	mainScope.async {
		if (!window.asDynamic().ApplePaySession) {
			return@async {
				appleNotAvailable(true)
			}
		}
		if (!window.asDynamic().ApplePaySession.canMakePayments()) {
			return@async {
				appleNotAvailable(true)
			}
		}

		val suspendedClient = suspendedBrainTreeClient.await()
		val braintreeApplePay = braintreeApplePay(jso { client = suspendedClient })
		val applePay = braintreeApplePay.await()
		var createPaymentRequest = applePay.createPaymentRequest(jso {
			total = jso {
				this.amount = (totalPay ?: "0.00")
				this.label = "Rooam"
				this.type = if (totalPay != null) "final" else "pending"
			}
			requiredBillingContactFields = arrayOf("name", "postalAddress")
		})

		return@async {

			loading(true)
			val applePaySession = ApplePaySession(3, createPaymentRequest)

			applePaySession.onvalidatemerchant = { event: dynamic ->
				mainScope.async {
					try {
						val merchantSession = applePay.performValidation(validationRequest = jso {
							displayName = "Rooam"
							validationURL = event.validationURL
						}).await()
						applePaySession.completeMerchantValidation(merchantSession)
					} catch (error: Throwable) {
						loading(false)
						console.error("Error validating merchant:", error)
						error(ActionMessage("apple_pay", "Error validating merchant, $error"))
						applePaySession.abort()
					}
				}.asPromise()
			}
			applePaySession.oncancel = { it: dynamic ->
				log(ActionMessage("apple_pay", "payment cancelled, $it"))
				loading(false)
			}
			applePaySession.onpaymentauthorized = { it: dynamic ->
				mainScope.launch {
					try {
						val deviceData =
							collectDeviceData(suspendedClient)
								.await()
								.deviceData
						val tokenize = applePay.tokenize(jso {
							this.token = it.payment.token
						}).await()
						val givenName = it.payment.billingContact.givenName.toString()
						val familyName = it.payment.billingContact.familyName.toString()
						storeNameWithSplitting(givenName, familyName, storeName)
						if (totalPay == null) {
							if (!postPayment(tokenize.nonce, deviceData, "APPLE_PAY").await()) {
								throw RuntimeException("Could not store payment")
							}
						}
						applePaySession.completePayment(js("ApplePaySession.STATUS_SUCCESS"))
						navigation(tokenize.nonce)
					} catch (e: Throwable) {
						applePaySession.completePayment(js("ApplePaySession.STATUS_FAILURE"))
						loading(false)
					}
				}
			}
			applePaySession.begin()
		}
	}
}

private suspend fun storeNameWithSplitting(
	givenName: String,
	familyName: String,
	storeName: suspend (String, String) -> Unit
) {
	if (givenName.isBlank()) {
		familyName.let {
			storeName(
				it.substringBefore(" "), it.substringAfter(" ")
			)
		}
	} else if (familyName.isBlank()) {
		givenName.let {
			storeName(
				it.substringBefore(" "), it.substringAfter(" ")
			)
		}
	} else {
		storeName(
			givenName,
			familyName
		)
	}
}
