package components.marketing

import api.ClaimRewardState
import api.updateClaimStateForCampaign
import api.getBannerForLocationIfNeeded
import api.mutateCampaingClaim
import components.divs.container
import components.marketing.model.*
import csstype.*
import csstype.LineStyle.Companion.solid
import emotion.react.css
import imported.googleanalitycs.gtag
import io.ktor.client.plugins.*
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.js.jso
import mainScope
import mui.icons.material.Check
import mui.icons.material.Close
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.AlertVariant
import mui.material.Avatar
import mui.material.Box
import mui.material.Collapse
import mui.material.Grow
import mui.material.IconButton
import mui.material.Paper
import mui.material.Snackbar
import mui.material.SnackbarOriginHorizontal
import mui.material.SnackbarOriginVertical
import mui.material.SvgIconSize
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.material.styles.useTheme
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.query.UseMutationOptions
import react.query.UseMutationResult
import react.query.useMutation
import react.router.useLocation
import kotlin.js.Promise

val smallBanner = VFC {
	var show by useState(false)
	var banner by useState(
		SmallBanner(
			"BANNER_SMALL",
			"",
			"",
			theme = BannerTheme("", ""),
			action = null,
			campaignId = ""
		)
	)
	var bannerActionTitle by useState<String?>(null)
	val location = useLocation()
	val (showClaimReward, setShowClaimReward) = useState(false)
	var claimError by useState(false)
	val (showCampaignRewardClaimed, setShowCampaignRewardClaimed) = useState(false)
	val theme = useTheme<Theme>()
	//TODO - change to react query
	useEffectOnce {
		mainScope.launch {
			getBannerForLocationIfNeeded<SmallBanner>(
				location,
				onSuccess = {
					val bannerResponse: SmallBanner = it
					banner = bannerResponse
					bannerActionTitle = bannerResponse.action?.title
					show = true
					if (bannerResponse.action is BannerActionClaimReward) {
						updateClaimStateForCampaign(
							CampaignId(bannerResponse.campaignId),
							setShowCampaignRewardClaimed
						)
					}
				}, onFailure = {
					show = false
				})
		}
	}
	val claim = useMutation(
		::mutateCampaingClaim,
		jso<UseMutationOptions<ClaimRewardState, ResponseException, CampaignId, String>> {
			onSuccess = { _, _, _ ->
				setShowCampaignRewardClaimed(true)
				Promise.resolve("")
			}
			onError = { _, _, _ ->
				claimError = true
				Promise.resolve("")
			}

		})
	Snackbar {
		open = claimError
		autoHideDuration = 4000
		anchorOrigin = jso{
			vertical = SnackbarOriginVertical.top
			horizontal = SnackbarOriginHorizontal.center
		}
		onClose={_,_ ->
			claimError = false
		}
		Alert {
			variant = AlertVariant.filled
			severity= AlertColor.error
			action = IconButton.create {
				onClick = { claimError = false }
				Close {}
			}
			AlertTitle {
				title = "Issue claiming promotion."
			}
			+"Please try again"

		}
	}
	Collapse {
		`in` = show
		div {
			css {
				paddingTop = 10.px
			}
			container {
				Paper {
					onClick = {
						if (bannerActionTitle != null && !showCampaignRewardClaimed)
							setShowClaimReward(true)
					}
					sx {
						backgroundColor = Color(banner.theme.backgroundColor)
						border = Border(1.px, LineStyle.solid, Color(banner.theme.borderColor))
						display = Display.flex
						padding = Padding(10.px, 14.px)
					}

					span {
						css {
							width = 80.px
							minWidth = 80.px
							alignItems = AlignItems.center
							display = Display.flex
							marginRight = 14.px
						}
						img {
							src = banner.imageUrl
						}
					}
					Box {
						Typography {
							variant = TypographyVariant.body2
							sx {
								fontSize = 0.7.em
								lineHeight = 16.px
								fontWeight = integer(500)
							}
							+banner.text
						}
						Grow {
							`in` = bannerActionTitle != null
							Typography {
								variant = TypographyVariant.body2
								sx {
									fontSize = 0.7.em
									lineHeight = 16.px
									color = theme.palette.text.secondary
								}
								+(if (showCampaignRewardClaimed) "Claimed" else bannerActionTitle ?: "")
							}
						}

					}

					Grow {
						`in` = showCampaignRewardClaimed
						Avatar {
							sx {
								color = theme.palette.text.primary
								backgroundColor = theme.palette.success.main
								border = Border(3.px, solid, theme.palette.success.dark)
								width = 30.px
								height = 30.px
								marginLeft = Auto.auto
							}
							Check {
								fontSize = SvgIconSize.small
								sx {
									width = 0.5.em
								}
							}
						}
					}
				}
			}
		}

	}

	BannerShowModal {
		booleanShowModal = showClaimReward
		info = banner
		claimActionPressed = handleClaimRewardClaimActionPressed(setShowClaimReward, claim)
		closeActionPressed = handleClaimRewardCloseActionPressed(setShowClaimReward)
	}
}

private fun handleClaimRewardClaimActionPressed(
	setShowClaimReward: StateSetter<Boolean>,
	mutation: UseMutationResult<ClaimRewardState,ResponseException,CampaignId,String>
): (CampaignId) -> Unit {
	return { campaignId ->
		mutation.mutate(campaignId,null)
		setShowClaimReward(false)
	}
}

private fun handleClaimRewardCloseActionPressed(setShowClaimReward: StateSetter<Boolean>): () -> Unit {
	return {
		setShowClaimReward(false)
	}
}

private fun handleBannerClickAction(banner: SmallBanner, setShowClaimReward: (Boolean) -> Unit) {
	gtag("event", "banner_clicked", banner.toJson())
	when (banner.action) {
		is BannerActionClaimReward -> {
			gtag("event", "banner_clicked_action_claim_reward", banner.toJson())
			setShowClaimReward(true)
		}
		is BannerActionOpenLink -> {
			gtag("event", "banner_clicked_action_open_link", banner.toJson())
			window.open(banner.action.url, banner.action.target)
		}
		else -> {}
	}
}
