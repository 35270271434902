package pages.explainer

import components.divs.SectionNoTopBottom
import components.divs.container
import components.explainer.cards
import components.explainer.explainerFAQ
import components.explainer.floatText
import components.explainer.howItWorks
import components.explainer.paymentOptions
import components.icons.infoIcon
import components.installationComponent
import components.layout
import components.location
import components.rooamFooter
import components.seo
import csstype.ClassName
import csstype.None
import csstype.px
import emotion.react.css
import co.rooam.utilities.functions.isInstalled
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.js.jso
import mui.material.Divider
import mui.material.DividerVariant
import mui.system.sx
import org.w3c.dom.events.Event
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.dom.Link
import react.useEffectOnce
import react.useState


@JsModule("./images/explainer/hero/hero-product-grid.png")
@JsNonModule
external val heroImage: dynamic

val Explainer = VFC {

	var isInstalled by useState(isInstalled())
	val isPaytronix = sessionStorage.getItem("taoEnabled")
	useEffectOnce {
		val installEvent={ _ : Event ->
			isInstalled = true
		}
		val appinstalled = "appinstalled"
		window.addEventListener(appinstalled, installEvent )
		cleanup {
			window.removeEventListener(appinstalled,installEvent)
		}
	}

	kotlinext.js.require("./styles/explainer.css")

	layout {
		seo {
			titleTemplate = "%s - What is a Web Tab?"
		}
		SectionNoTopBottom {
			location {
				isLong = true
				showBack = false
				showMenu = false
				isExplainer = true
			}
		}
		SectionNoTopBottom {
			floatText {}
		}
		div {
			className = ClassName("section")
			div {
				className = ClassName("container text-center")
				div {
					className = ClassName("float-middle")
					style = jso {
						maxWidth = 300.px
					}
					cards {}
				}
			}
		}
		div {
			className = ClassName("section less no-bottom")
			div {
				className = ClassName("container text-center")
				p {
					className = ClassName("float-middle no-top-bottom")
					style = jso {
						maxWidth = 480.px
					}
					+"""You've landed on a Web Tab link — a fast and secure way to open and close your bar tab with your phone. Keep your tab open all night and when you’re ready to leave, simply tap 'Close Tab'."""
				}
				div {
					className = ClassName("section")
					Link {
						className = ClassName("btn red block")
						to = "../"
						+"""Get Started"""
					}
				}

			}
		}
		Divider{
			sx{
				marginTop=20.px
				marginBottom=20.px
			}
			variant=DividerVariant.middle
			+"scroll to discover more"
		}
		div {
			className = ClassName("section less no-bottom")
			div {
				className = ClassName("container text-center")
				div {
					className = ClassName("icon hero")
					infoIcon {}
				}
				h1 {
					className = ClassName("big no-top-bottom")
					+"How it works?"
				}
			}
		}
		div {
			className = ClassName("section less no-bottom")
			howItWorks {}
		}
		div {
			className = ClassName("section largest no-bottom")
			div {
				className = ClassName("containter text-center")
				h1 {
					className = ClassName("big float-middle no-top-bottom")
					style = jso {
						maxWidth = 380.px
					}
					+"Pay with any payment method you wish."
				}
			}
		}
		div {
			className = ClassName("section less no-bottom")
			div {
				className = ClassName("section less no-bottom")
				container {
					paymentOptions {}
				}
			}
			div {
				className = ClassName("container text-center")
				div {
					className = ClassName("section no-bottom")
					Link {
						to = "../"
						className = ClassName("btn block")
						+"Get Started"
					}
				}
			}

		}
		div {

			css(ClassName("section largest no-bottom")){
				if (isPaytronix!=null) display = None.none
			}
			div {
				className = ClassName("container text-center")
				div {
					css(ClassName("icon hero")){

					}
					style = jso{
						if (isInstalled) display = None.none
					}
					svg {
						className = ClassName("svg-icon")
						viewBox = "0 0 24 24"
						path {
							fill = "#555759"
							d =
								"M9 3h6v2h-6v-2zm3 15l7-8h-4v-4h-6v4h-4l7 8zm3-16v-2h-6v2h6zm3.213-.246l-1.213 1.599c2.984 1.732 5 4.955 5 8.647 0 5.514-4.486 10-10 10s-10-4.486-10-10c0-3.692 2.016-6.915 5-8.647l-1.213-1.599c-3.465 2.103-5.787 5.897-5.787 10.246 0 6.627 5.373 12 12 12s12-5.373 12-12c0-4.349-2.322-8.143-5.787-10.246z"
						}
					}
				}
				h1 {
					css(ClassName("big float-middle no-top")){
						if (isInstalled) display = None.none
					}
					style = jso {
						maxWidth = 400.px
					}
					+"Save this link to your home screen for easy access."
				}
				p {
					css(ClassName("float-middle no-top-bottom")){
						if (isInstalled) display = None.none
					}
					style = jso {
						maxWidth = 420.px
					}
					+"Want to easily find this link and open your tab before you arrive at the show or location?"
				}
				div {
					className = ClassName("section no-bottom")
					installationComponent {
					}
				}
			}
		}
		div {
			className = ClassName("section large no-bottom")
			div {
				className = ClassName("section no-top")
				img {
					src = heroImage
					alt = "Hero of Web Tab"
				}
			}
			div {
				className = ClassName("container text-center")
				h1 {
					className = ClassName("float-middle no-top-bottom")
					style = jso {
						maxWidth = 450.px
					}
					+"The fastest way to open and close your tab. No app download or account creation needed."
				}
				div {
					className = ClassName("section no-bottom")
					Link {
						to = "../"
						className = ClassName("btn red block")
						+"Get Started"
					}
				}
			}
		}
		div {
			className = ClassName("section largest no-bottom")
			div {
				className = ClassName("container text-center")
				div {
					className = ClassName("icon hero")
					svg {
						className = ClassName("svg-icon")
						xmlns = "http://www.w3.org/2000/svg"
						viewBox = "0 0 24 24"
						path {
							fill = "#555759"
							d =
								"M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"
						}
					}
				}
				h1 {
					className = ClassName("section large no-top-bottom")
					+"Got questions?"
				}
				explainerFAQ {}
				div {
					className = ClassName("section large no-bottom")
					Link {
						to = "../"
						className = ClassName("btn block")
						+"Get Started"
					}
				}
			}
		}
		rooamFooter {}

	}

}
