package co.rooam.components.tab.tips

import co.rooam.icons.DollarIcon
import csstype.AlignItems
import csstype.Border
import csstype.ClassName
import csstype.Color
import csstype.Display
import csstype.FlexDirection
import csstype.HtmlAttributes
import csstype.JustifyContent
import csstype.LineStyle
import csstype.NamedColor
import csstype.TextShadow
import csstype.TransitionProperty
import csstype.integer
import csstype.ms
import csstype.pct
import csstype.px
import imported.numberformat.NumberFormatProps
import imported.numberformat.Values
import imported.numberformat.numberFormat
import kotlinext.js.js
import kotlinx.js.jso
import mui.material.Card
import mui.material.CardActionArea
import mui.material.Divider
import mui.material.FormControlColor
import mui.material.FormControlProps
import mui.material.FormControlVariant
import mui.material.Grid
import mui.material.InputBaseProps
import mui.material.PaperVariant
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import popper.core.Name
import react.FC
import react.ForwardRef
import react.Props
import react.PropsWithRef
import react.ReactNode
import react.create
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.dom.onChange
import react.useRef
import react.useState


external interface TipProps : Props {
	var subtotal: String
	var tips: Map<Int, String>
	var chosenTip: Pair<Int, String>
	var onTipChange: (Int, String) -> Unit
	var tipColor: FormControlColor?
	var divider: ReactNode?
}

val Tip = FC<TipProps> { tipProps ->
	val (active, setTipActive) = useState(tipProps.chosenTip.first)
	val theme = useTheme<Theme>()
	Grid {
		container = true
		spacing = responsive(1)
		columns = responsive(
			Breakpoint.xs to responsive(tipProps.tips.size * 4),
			Breakpoint.sm to responsive(tipProps.tips.size * 4),
			Breakpoint.md to responsive(tipProps.tips.size * 4)
		)
		tipProps.tips.forEach {
			Grid {
				item = true
				xs = 4
				CardActionArea {

					sx {
						height = 100.pct
					}
					Card {
						onClick = { event ->
							setTipActive(it.key)
							tipProps.onTipChange(it.key, it.value)
						}
						elevation = 6
						if (active == it.key) {
							variant = PaperVariant.outlined
						}
						sx {
							paddingTop = 10.px
							paddingBottom = 11.px
							height = 100.pct
							display = Display.flex
							alignItems = AlignItems.center
							justifyContent = JustifyContent.center
							flexDirection = FlexDirection.column
							border = Border(1.px, LineStyle.solid, color = NamedColor.transparent)
							if (active == it.key) {
								backgroundColor = NamedColor.white
								color = NamedColor.black
								borderColor =
									theme.palette.getContrastText(theme.palette.background.default).unsafeCast<Color>()

							}
							transitionDuration = 250.ms
							transitionProperty = "background-color".unsafeCast<TransitionProperty>()

						}
						Typography {
							sx {
								fontWeight = integer(600)
								fontSize = 16.px
								lineHeight = 18.px
							}

							if (it.key == 0) {
								+"No Tip"
							} else {
								+"${it.key}%"
							}
						}
						if (it.key != 0) {
							Typography {
								sx {
									fontWeight = integer(500)
									fontSize = 13.px
									lineHeight = 15.px
								}
								+"\$${it.value}"
							}

						}
					}
				}
			}
		}
	}
	if (tipProps.divider == null) {
		Divider {
			sx {
				color = theme.palette.divider
			}
			+"or custom"
		}
	} else {
		+tipProps.divider
	}

	numberFormat {
		thousandSeparator = true
		allowNegative = false
		decimalScale = 2
		onValueChange = {
			if (it.floatValue < 1.0e14) {
				tipProps.onTipChange(-1, it.value)
				setTipActive(-1)
			}
		}
		fullWidth = true
		placeholder = "Enter Custom Tip Amount"
		customInput = TextField
		color = if (tipProps.tipColor == null) FormControlColor.secondary else tipProps.tipColor
		asDynamic().InputProps = jso<InputBaseProps> {
			startAdornment = DollarIcon.create()
		}
	}
}
