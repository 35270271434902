package api

import api.storage.storeRooamHeader
import co.rooam.components.payment.Payment
import imported.braintree.data.braintreeDataCollector
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.Job
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.js.jso
import kotlinx.serialization.Serializable
import mainScope
import react.query.QueryFunctionContext
import react.query.QueryKey
import kotlin.js.Promise

val getPayments = { context: QueryFunctionContext<QueryKey, *> ->
	mainScope.async {
		client.get("/payments") {
			addSessionHeader()
			expectSuccess = true
		}.also { it.storeRooamHeader() }.body<Array<ApiPayment>>() as Array<Payment>
	}.asPromise()
}


val postPayment = { nonce: String, deviceData: String?, type: String ->
	mainScope.async {
		val post: HttpResponse =
			client.post(
				"/payments"
			) {
				setBody(PaymentRequest(nonce = nonce, deviceData = deviceData, paymentType = type))
				addSessionHeader()
				header("Content-Type", ContentType("application", "json"))
				expectSuccess = false
			}.also { it.storeRooamHeader() }
		if (post.status != HttpStatusCode.OK) {
			error(ActionMessage("payment_add", "failed to add payment"))
			return@async false
		}
		return@async true
	}
}

val updatePayment = { payment: Payment,
                      errorHandle: () -> Unit,
                      navigation: () -> Unit ->
	mainScope.launch {
		val put: HttpResponse =
			client.put(
				"/payments/${payment.id}"
			) {
				setBody(UpdatePaymentRequest(paymentType = payment.type, default = true))
				addSessionHeader()
				header("Content-Type", ContentType("application", "json"))
				expectSuccess = false
			}.also { it.storeRooamHeader() }
		if (put.status != HttpStatusCode.OK) {
			errorHandle()
		} else
			navigation()
	}
}

fun deletePaymentCall(payment: Payment): Promise<HttpResponse> {
	return mainScope.async {
		try {
			client.delete(
				"/payments/${payment.id}"
			) {
				addSessionHeader()
				header("Content-Type", ContentType("application", "json"))
				expectSuccess = true
			}.also { it.storeRooamHeader() }
		} catch (t: ResponseException) {
			t.response.storeRooamHeader()
			throw t
		}
	}.asPromise()
}


fun collectDeviceData(braintreeClientAwaited: Any) =
	braintreeDataCollector(jso {
		this.client = braintreeClientAwaited
		this.kount = true
	})


@Serializable
data class PaymentRequest(val paymentType: String, val nonce: String, val deviceData: String? = null)

@Serializable
data class UpdatePaymentRequest(val paymentType: String, val default: Boolean = true)
