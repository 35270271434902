package co.rooam.icons

import mui.material.SvgIcon
import mui.material.SvgIconProps
import react.FC
import react.create
import react.createElement
import react.dom.svg.ReactSVG


val DollarIcon: FC<SvgIconProps> = { props: SvgIconProps ->
	createElement(SvgIcon, props = props, children = arrayOf(ReactSVG.path.create {
		d = """M11.8,10.9c-2.3-0.6-3-1.2-3-2.1c0-1.1,1-1.8,2.7-1.8c1.4,0,2.1,0.5,2.4,1.4C14,8.7,14.3,9,14.8,9h0.3
                        c0.7,0,1.1-0.6,0.9-1.3c-0.4-1.2-1.4-2.2-3-2.5V4.5C13,3.7,12.3,3,11.5,3S10,3.7,10,4.5v0.7C8.1,5.6,6.5,6.8,6.5,8.8
                        c0,2.3,1.9,3.5,4.7,4.1c2.5,0.6,3,1.5,3,2.4c0,0.7-0.5,1.8-2.7,1.8c-1.6,0-2.5-0.6-2.8-1.4C8.5,15.3,8.2,15,7.8,15H7.5
                        c-0.7,0-1.1,0.7-0.9,1.3c0.6,1.4,1.9,2.2,3.4,2.5v0.7c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-0.6c1.9-0.4,3.5-1.5,3.5-3.6
                        C16.5,12.5,14.1,11.5,11.8,10.9z
                        """
	}))
}.unsafeCast<FC<SvgIconProps>>()
