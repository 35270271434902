import api.*
import api.hooks.useServiceWorker
import api.storage.StorageKeys
import api.storage.storeRooamHeader
import imported.braintree.client.BraintreeOptions
import imported.braintree.client.braintreeClient
import imported.googleanalitycs.install
import imported.sentry.withSentryReactRouterV6Routing
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.js.jso
import org.w3c.dom.events.Event
import pages.*
import pages.account.account
import pages.complete.TabComplete
import pages.explainer.Explainer
import pages.identity.verification.verifyIdentityPage
import pages.tab.tab
import react.Props
import react.VFC
import react.create
import react.createContext
import react.createElement
import react.query.QueryKey
import react.query.UseQueryOptions
import react.query.useQuery
import react.router.*
import react.useEffectOnce
import react.useLayoutEffectOnce
import react.useState
import kotlin.lazy

val mainScope = MainScope()

val basePathClear = "/venue/"

external interface CodeProps : Props {
	var code: String
}


fun locationBase(location: Params) = "$basePathClear${location["code"]}"
fun locationBase(placeCode: String?, location: Params) = "$basePathClear${placeCode ?: location["code"]}"

var defferedPrompt = createContext<Pair<Event?, () -> Unit>?>(null)

val SentryRoutes = withSentryReactRouterV6Routing(Routes)

val app = VFC("WebTab") {

	var isPaytronix by useState(sessionStorage.getItem("taoEnabled"))

	getBraintreeClient()
	useServiceWorker()

	var beforePrompt by useState<Event?>(null)

	val resetRef = { beforePrompt = null }
	useLayoutEffectOnce {
		val root = window.document.documentElement
		if (isPaytronix != null) {
			root?.asDynamic().style.setProperty("---background-color-wrapper", "#242526")
			root?.asDynamic().style.setProperty("---border-radius-standard-6px", "0px")
			root?.asDynamic().style.setProperty("---border-radius-standard-5px", "0px")
			root?.asDynamic().style.setProperty("---border-radius-standard-4px", "0px")
			root?.asDynamic().style.setProperty("---border-radius-standard-3px", "0px")
			root?.asDynamic().style.setProperty("---border-radius-standard-8px", "0px")
			root?.asDynamic().style.setProperty("---border-radius-standard-10px", "0px")
		}
		window.addEventListener("beforeinstallprompt", { event ->
			event.preventDefault()
			console.log(event)
			beforePrompt = event
		})
	}
	useEffectOnce {
		install(googleAnalytics)
	}

	defferedPrompt.Provider {
		value = beforePrompt to resetRef
		SentryRoutes {

			Route {

				path = "/venue"


				Route {
					index = true
					element = notExisting.create()
				}
				Route {
					this.path = ":code"

					Route {
						index = true
						this.element = slashPage.create()
					}
					Route {
						this.path = "explainer"
						this.element = createElement(Explainer)
					}
					Route {
						this.path = "phone"
						this.element = createElement(loginPhone)
					}
					Route {
						path = "verify"

						element =
							verify.create {
								phoneNumberFormatted = sessionStorage.getItem(StorageKeys.formattedPhone) ?: ""
							}

					}
					Route {
						path = "account"
						element = account.create {}

					}
					Route {
						path = "addpayment"
						element =
							addPayment.create {
								navigate = { it(-1) }
							}

					}
					Route {
						path = "select"
						element =
							SelectPayment.create {
								this.navigate = { history, _, _, _ ->
									log(ActionMessage("select_payment", "changed selected payment"))
									history(-1)
								}
								this.showBack = true
								this.showDelete = false
							}
					}

					Route {
						path = "open"

						element =

							SelectPayment.create {
								console.log("Creating-Route")
								this.showDelete = true
								this.navigate = { history, codeProps, errorSetter, loadingSetter ->
									openTab(codeProps["code"], {
										errorSetter(true)
										loadingSetter(false)
										error(ActionMessage("tab_open", "Checkin failed"))
									},
										{
											history("${locationBase(codeProps)}/overdue")
											log(ActionMessage("tab_open", "Failed due to overdue"))
										}
									) {
										val tips = getTips()
										val receive = tips.body<Tips>()
										setTips(receive)
										log(ActionMessage("tab_open", "Success"))
										history("${locationBase(codeProps)}/tab")
									}

								}
								this.error = useLocation().asDynamic().state
								this.errorMessageText = "Issue Opening Tab."
								this.showBack = false
							}

					}

					Route {
						path = "help"
						element =
							helpPage.create()
					}
					Route {
						path = "voided"
						element = tabVoided.create()
					}
					Route {
						path = "complete"
						element = TabComplete.create { showFeedback = true }
					}

					Route {
						path = "overduecomplete"
						element = TabComplete.create {

							this.showFeedback = false

						}

					}
					Route {
						path = "tab"
						element =
							tab.create {
							}

					}
					Route {
						path = "overdue"
						element = tabOverdue.create {}
					}

					Route {
						path = "verifyidentity"
						element = verifyIdentityPage.create()
					}
				}
			}

			Route {
				path = "/*"
				element = notExisting.create {}
				index = true

			}
		}
	}
}


val getCode = { phoneNumber: String,
                history: NavigateFunction,
                disablePhone: (String?) -> Unit ->
	mainScope.launch {
		val response: HttpResponse =
			client.post {
				url(path = "/login/code")
				setBody(Phone(phoneNumber))
				header("Content-Type", ContentType("application", "json"))
				expectSuccess = false
			}
		if (response.status == HttpStatusCode.BadRequest) {
			val receive = response.body<Map<String, Map<String, String?>>>()
			val errors = receive["errors"]
			disablePhone(errors?.values?.firstOrNull { it != null })
		} else {
			response.storeRooamHeader()
			history("../verify")
		}
	}
}

@Deprecated("need to change to one from query")
val suspendedBrainTreeClient by lazy {

	mainScope.async {
		val any = jso<BraintreeOptions> {
			authorization = loadToken().body<Token>().braintreeToken
		}
		try {
			return@async braintreeClient(any).await()
		} catch (e: Throwable) {
			console.warn("Connecting to braintree failed retrying:", e)
			for (i in 1..10) {
				try {
					delay(500)
					return@async braintreeClient(any).await()
				} catch (ex: Throwable) {
					console.warn("Connecting to braintree failed retry($i):", ex)
				}
			}
			throw CannotProcessPaymentException()
		}
	}
}


fun getBraintreeClient() = useQuery(
	QueryKey<QueryKey>("braintreeClient"),
	{
		mainScope.async {
			val any = jso<BraintreeOptions> {
				authorization = loadToken().body<Token>().braintreeToken
			}
			braintreeClient(any).await()
		}.asPromise()
	},
	jso<UseQueryOptions<Any, Any, Any, QueryKey>> {
		staleTime = 3600000
		cacheTime = 3600000
		retry = { count, error -> count < 10 }
	}
)

fun getIdentityProfileQuery() = useQuery(
	QueryKey<QueryKey>("identityProfile"),
	{
		getIdentityProfile()
	},
	jso<UseQueryOptions<Any, Any, Any, QueryKey>> {
		staleTime = 3600000
		cacheTime = 3600000
		retry = { count, error -> count < 10 }
		onError = { error ->
			console.error("Error getting Identity Verification Profile", error)
		}
	}
)

class CannotProcessPaymentException : RuntimeException()
