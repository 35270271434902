package co.rooam.components.tab.live

import csstype.AlignItems
import csstype.Border
import csstype.Color
import csstype.Display
import csstype.LineStyle
import csstype.NamedColor
import csstype.Padding
import csstype.deg
import csstype.em
import csstype.integer
import csstype.linearGradient
import csstype.number
import csstype.pct
import csstype.px
import csstype.rgba
import csstype.stop
import emotion.react.css
import imported.transitiongroup.TransitionGroup
import mui.material.Avatar
import mui.material.Collapse
import mui.material.Paper
import mui.material.PaperVariant
import mui.material.Stack
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML

interface OrderedItem {
	val name: String
	val price: String
	val pricePerUnit: String
	val quantity: Int

}
external interface OrderedItemsProps : Props {
	var items: Array<OrderedItem>
}



data class GradientColor(
	val start: Color,
	val startOpaque: Color,
	val stop: Color,
	val stopOpaque: Color
)


private const val opacity = 0.3

val itemColorGradients = listOf(
	GradientColor(
		rgba(93, 75, 218, 1.0),
		rgba(93, 75, 218, opacity),
		rgba(102, 137, 232, 1.0),
		rgba(102, 137, 232, opacity)
	),
	GradientColor(
		rgba(93, 0, 184, 1.0),
		rgba(93, 0, 184, opacity),
		rgba(151, 20, 152, 1.0),
		rgba(151, 20, 152, opacity)
	), GradientColor(
		rgba(169, 47, 169, 1.0),
		rgba(169, 47, 169, opacity),
		rgba(220, 46, 132, 1.0),
		rgba(220, 46, 132, opacity)
	), GradientColor(
		rgba(235, 69, 92, 1.0),
		rgba(235, 69, 92, opacity),
		rgba(245, 112, 56, 1.0),
		rgba(245, 112, 56, opacity)
	), GradientColor(
		rgba(243, 92, 0, 1.0),
		rgba(243, 92, 0, opacity),
		rgba(252, 163, 0, 1.0),
		rgba(252, 163, 0, opacity)
	)

)

val OrderedItems = FC<OrderedItemsProps> { props ->
	val theme = useTheme<Theme>()
	Stack {
		spacing = responsive(1)
		sx {
			marginBottom = 30.px
		}
		TransitionGroup {
			component = null
			props.items.forEachIndexed { index, value ->
				Collapse {
					Paper {
						variant = PaperVariant.elevation
						sx {
							padding = Padding(top = 13.px, right = 17.px, bottom = 13.px, left = 18.px)
							display = Display.flex
							alignItems = AlignItems.center
							border = Border(1.px, LineStyle.solid, theme.palette.divider)
						}
						elevation = 4

						Avatar {
							sx {
								marginRight = 15.px
								width = 50.px
								height = 50.px
								backgroundImage = linearGradient(
									45.deg,
									stop(itemColorGradients[index % 5].startOpaque, 0.pct),
									stop(itemColorGradients[index % 5].stopOpaque, 100.pct)
								)
								backgroundColor = NamedColor.transparent
							}
							Avatar {
								sx {
									width = 40.px
									height = 40.px
									color = theme.palette.getContrastText(itemColorGradients[index%5].start.toString()).unsafeCast<Color>()
									fontWeight = integer(600)
									fontSize = 20.px
									backgroundImage = linearGradient(
										45.deg,
										stop(itemColorGradients[index % 5].start, 0.pct),
										stop(itemColorGradients[index % 5].stop, 100.pct)
									)
								}
								+"${value.quantity}"
							}
						}
						ReactHTML.div {
							css { flexGrow = number(1.0) }
							Typography {
								sx {
									fontWeight = integer(600)
									lineHeight = 1.em
									fontSize = 0.85.em
								}
								+value.name
							}
							Typography {
								sx {
									color = theme.palette.text.secondary
									lineHeight = 1.em
									fontSize = 0.85.em
								}
								+"@ \$${value.pricePerUnit} each"
							}
						}
						Typography {
							sx {
								fontWeight = integer(600)
								lineHeight = 1.em
								fontSize = 0.85.em
							}
							+"\$${value.price}"
						}
					}
				}
			}
		}
	}
}
