package components

import imported.sentry.setUser
import kotlinext.js.require
import kotlinx.browser.sessionStorage
import kotlinx.coroutines.launch
import mainScope
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.main
import react.useEffectOnce
import kotlin.js.json


val layout = FC<PropsWithChildren> { props ->

	useEffectOnce {
		mainScope.launch {
			updateSentryUser()
		}
	}

	require("./styles/global.css")
	main {
		+props.children
	}
}


fun updateSentryUser() = currentUserPhone()
	?.let { phone ->
		setUser(json("username" to phone))
	}

fun currentUserPhone() = sessionStorage.getItem("phone")
