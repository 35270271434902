package pages

import api.*
import apple.applyApplePay
import apple.canUseApplePay
import co.rooam.components.payment.Payment
import co.rooam.components.receipt.RooamReceipt
import co.rooam.components.tab.live.OrderedItems
import co.rooam.utilities.functions.sum
import components.*
import components.animations.RotatingLoader
import components.divs.SectionNoTopBottom
import components.divs.container
import components.help.help
import components.modals.AlertModal
import components.utils.orbit
import csstype.ClassName
import csstype.Display.Companion.block
import csstype.None
import csstype.integer
import csstype.px
import google.prepareGooglePay
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.js.jso
import locationBase
import mainScope
import mui.icons.material.Payment
import mui.lab.LoadingButton
import mui.lab.LoadingPosition
import mui.material.ButtonVariant
import mui.material.Container
import mui.material.Size
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.query.QueryKey
import react.query.useQuery
import react.router.useNavigate
import react.router.useParams

var googleBehaviorOverdue: (() -> Any)? = null
var appleBehaviorOverdue: (() -> Unit)? = null

val apiPaymentInitialData = ApiPayment(
	label = "Loading Payments......",
	type = "LOADING",
	default = false,
	id = "NONE",
	image = ""
)

val tabOverdue = FC<Props> {
	val useHistory = useNavigate()
	val useParams = useParams()

	val (loading, setLoading) = useState(false)
	val (payments, setPayments) = useState<Array<Payment>>(arrayOf(apiPaymentInitialData))
	val (overdueResponse, setOverdueResponse) = useState<OverdueResponse?>(null)
	val (selectedPayment, setSelectedPayment) = useState<Payment?>(null)
	val (paymentError, setPaymentError) = useState(false)
	val (showApplePayAlert, setShowApplePayAlert) = useState(false)
	val paymentsQuery = useQuery(
		QueryKey<QueryKey>("payments"), getPayments,
		jso<PaymentsQueryOptions> {
			initialData = arrayOf(apiPaymentInitialData)
			onError = { useHistory("../phone") }
			onSuccess ={
				setSelectedPayment(it.find { payment -> payment.default })
			}
			retry = { _, _ -> false }
		}
	)
	useEffectOnce {
		mainScope.launch {
			var stopProcessing = false
			var overdueAmount: String? = null
			getOverdueDetails(setOverdueResponse, { overdueAmount = it }) {
				moveToNext(useHistory, useParams)
				stopProcessing = true
			}.await()
			if (stopProcessing) return@launch
			googleBehaviorOverdue = prepareGooglePay(
				overdueAmount,
				{ mainScope.async { } },
				{
					setLoading(it)
					if (!it) {
						setPaymentError(!it)
					}
				}
			) {
				payOverdue(it, {
					setPaymentError(true)
					setLoading(false)
				}) {
					useHistory("${locationBase(useParams)}/overduecomplete")
				}
			}.await()
			appleBehaviorOverdue = applyApplePay(
				{ },
				overdueAmount,
				{ _, _ -> mainScope.async { } },
				{
					setLoading(it)
					if (!it) {
						setPaymentError(!it)
					}
				}) {

				payOverdue(it, {
					setPaymentError(true)
					setLoading(false)
				}) {
					useHistory("${locationBase(useParams)}/overduecomplete")
				}
			}.await()
		}

	}
	layout {
		seo {
			titleTemplate = "Unpaid Tab - %s"
		}

		SectionNoTopBottom {
			TabOverdueHeader {}

			if (overdueResponse == null) {
				TabOverdueLoading {
					message = "Getting Tab details."
				}
			} else {
				TabOverduePayments {
					this.payments = paymentsQuery.data ?: payments
					this.setSelectedPayment = setSelectedPayment
				}
				if (!overdueResponse.overdueTabs.isEmpty()) {
					TabOverdueDetails {
						this.overdueResponse = overdueResponse
					}
				} else {
					div {
						className = ClassName("section large no-bottom")
					}
				}
				TabOverdueTotal {
					this.overdueResponse = overdueResponse
				}
				TabOverduePaymentError {
					this.show = paymentError
				}
				TabOverduePay {
					this.payment = selectedPayment ?: payments.find { it.default } ?: payments[0]
					this.loading = loading
					this.overdueResponse = overdueResponse
					this.onPaymentDidComplete = {
						useHistory("${locationBase(useParams)}/overduecomplete")
					}
					this.onPaymentDidStart = {
						setLoading(true)
						setPaymentError(false)
					}
					this.onPaymentDidFail = {
						setPaymentError(true)
						setLoading(false)
					}
					this.onApplePayNotAvailable = {
						setShowApplePayAlert(true)
					}
				}
				TabOverdueTipsDisclaimer {}
			}
			help { }
			rooamFooter { }
		}
		AlertModal {
			this.title = "Apple Pay unavailable."
			this.text =
				"You cannot use Apple Pay with current browser. Please switch to Safari or choose another payment method."
			this.closeAlert = {
				setShowApplePayAlert(false)
				setLoading(false)
			}
			this.alertShow = showApplePayAlert
		}
	}
}

val TabOverdueHeader = VFC("header") {
	div {
		className = ClassName("section large text-center")
		container {
			div {
				className = ClassName("orbit-box medium")
				orbit()
				div {
					className = ClassName("icon")
					svg {
						className = ClassName("svg-icon")
						viewBox = "0 0 24 24"
						path {
							d =
								"M12,7c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1s-1-0.4-1-1V8C11,7.4,11.4,7,12,7z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10 c5.5,0,10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20z M13,17h-2v-2h2V17z"


							fill = "#ffffff"

						}
					}
				}
			}
			h2 {
				className = ClassName("no-top-bottom")
				+"Unpaid Tab."
			}
			p {
				className = ClassName("float-middle no-bottom")


				style = jso {
					maxWidth = 480.px
				}
				+"Something went wrong while processing your payment. Before you can Rooam again you must settle the balance from your last tab."
			}
		}
	}
}

external interface TabOverdueLoadingProps: Props {
	var message: String
}

val TabOverdueLoading = FC<TabOverdueLoadingProps>("loading") { props ->
	div {
		className = ClassName("section less no-bottom")
		div {
			className = ClassName("container text-center")
			SectionNoTopBottom {
				div {
					className = ClassName("message loading")
					RotatingLoader {
						text = props.message
					}
				}
			}
		}
	}
}

external interface TabOverduePaymentsProps: Props {
	var payments: Array<Payment>
	var setSelectedPayment: StateSetter<Payment?>
}

val TabOverduePayments = FC<TabOverduePaymentsProps>("payments") { props ->
	div {
		div {
			className = ClassName("section no-top")
			container {
				h2 {
					className = ClassName("no-top-bottom")
					+"Select Payment"
				}
			}
		}
		SectionNoTopBottom {
			container {
				paymentList {
					this.payments = props.payments
					onPaymentSelect = {
						props.setSelectedPayment(it)
					}
					showDelete = false
					reload = {}
				}
			}
		}
	}
}

external interface TabOverdueResponseProps: Props {
	var overdueResponse: OverdueResponse
}

val TabOverdueDetails = FC<TabOverdueResponseProps>("detais") { props ->
	val overdueResponse = props.overdueResponse

	div {
		div {
			className = ClassName("section large no-bottom")
			container {
				h2 {
					className = ClassName("no-top-bottom")
					+"Tab Details"
				}
			}
		}
		div {
			className = ClassName("section no-bottom")
			container {
				OrderedItems {
					items = overdueResponse.overdueTabs[0].items.toTypedArray()
				}
			}
		}
		div {
			className = ClassName("section")
			container {
				RooamReceipt {
					this.subtotal = overdueResponse.overdueTabs[0].subTotal
					this.tip = overdueResponse.overdueTabs[0].tip ?: "0.00"
					this.tax = overdueResponse.overdueTabs[0].tax
					this.fees = overdueResponse.overdueTabs[0].baseFare ?: "0.00"
					this.serviceCharges = overdueResponse.overdueTabs[0].serviceCharges
					this.discount = overdueResponse.overdueTabs[0].discounts ?: "0.00"
					this.taxesFees = sum(
						overdueResponse.overdueTabs[0].tax,
						sum(
							overdueResponse.overdueTabs[0].serviceCharges,
							overdueResponse.overdueTabs[0].baseFare
						)
					)
				}
			}
		}
	}
}

val TabOverdueTotal = FC<TabOverdueResponseProps>("total") { props ->
	val overdueResponse = props.overdueResponse

	div {
		className = ClassName("section less bar")
		container {
			div {
				className = ClassName("row clearfix")
				div {
					className = ClassName("column")
					p {
						className = ClassName("text-white font-weight-600 no-top-bottom")
						+"Total:"
					}
				}
				div {
					className = ClassName("column text-right")
					p {
						className = ClassName("text-white font-weight-600 no-top-bottom")
						id = "tab-total"
						+"\$${overdueResponse.total()}"
					}
				}
			}
		}
	}
}

external interface TabOverduePaymentErrorProps: Props {
	var show: Boolean
}

val TabOverduePaymentError = FC<TabOverduePaymentErrorProps>("payment-error") { props ->
	div {
		className = ClassName("section less no-bottom")
		style = jso {
			display = if (props.show) block else None.none
		}
		container {
			div {
				className = ClassName("message error")
				div {
					className = ClassName("message-wrap")
					span {
						className = ClassName("icon")
						svg {
							className = ClassName("svg-icon")
							viewBox = "0 0 24 24"
							path {
								d =
									"M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"
								fill = "#ec0b19"

							}
						}
					}
					p {
						className = ClassName("small text-white no-top-bottom")
						span {
							className = ClassName("font-weight-600")
							+"Issue Closing Tab."
						}
						+" Please try again."
					}
				}
			}
		}
	}
}

val TabOverdueTipsDisclaimer = VFC("tips-disclaimer") {
	SectionNoTopBottom {
		container {
			div {
				className = ClassName("link-box text-center no-bottom")
				p {
					className = ClassName("small text-grey no-top-bottom")
					+"Unpaid tabs are set with a 20% tip unless tip was previously selected."
				}
			}
		}
	}
}

external interface TabOverduePayProps: Props {
	var payment: Payment
	var loading: Boolean
	var overdueResponse: OverdueResponse
	var onPaymentDidStart: () -> Unit
	var onPaymentDidFail: () -> Unit
	var onPaymentDidComplete: () -> Unit
	var onApplePayNotAvailable: () -> Unit
}

val TabOverduePay = FC<TabOverduePayProps>("pay") { props ->
	val payWithApplePay = {
		if (canUseApplePay()) {
			appleBehaviorOverdue?.invoke()
		} else {
			props.onApplePayNotAvailable()
		}
	}
	val payWithGooglePay = { googleBehaviorOverdue?.invoke() }
	val payWithCard = {
		updatePayment(props.payment, { props.onPaymentDidFail() }) {
			payOverdue(null, { props.onPaymentDidFail() }) {
				props.onPaymentDidComplete()
			}
		}
	}

	SectionNoTopBottom {
		div {
			className = ClassName("container")
			div {
				className = ClassName("section less no-bottom")
				LoadingButton {
					variant = ButtonVariant.contained
					size = Size.large
					loading = props.loading
					sx {
						textTransform = None.none
						fontSize = 17.px
						fontWeight = integer(600)
					}
					onClick = {
						it.preventDefault()
						props.onPaymentDidStart()
						when (props.payment.type) {
							"AP" -> payWithApplePay()
							"GP" -> payWithGooglePay()
							else -> payWithCard()
						}
					}
					loadingPosition = LoadingPosition.start

					fullWidth = true

					if (props.loading) {
						+"Closing Tab..."
					} else {
						+"Close Tab: \$${props.overdueResponse.total()}"
					}

				}
			}
		}
	}
}
