package co.rooam.utilities.functions


fun sum(tax: String?, serviceCharges: String?): String {
	val taxes = tax ?: "0.00"
	val fees = serviceCharges ?: "0.00"
	val taxesInCents = toCents(taxes)
	val feesInCents = toCents(fees)
	return toMoney(taxesInCents + feesInCents)
}

fun minus(larger: String?, smaller: String?): String {
	val largerNotNull = larger ?: "0.00"
	val smallerNotNull = smaller ?: "0.00"

	val largerInCents = toCents(largerNotNull)
	val smallerInCents = toCents(smallerNotNull)
	if (largerInCents < smallerInCents) return "0.00"
	return toMoney(largerInCents - smallerInCents)

}

fun String.lowerThan(rewards: String, subtotal: String?): Boolean {
	val cents = toCents(this)
	return (cents < toCents(rewards)) and
			(cents < toCents(subtotal ?: "0.00"))
}


fun toMoney(i: Long): String {
	val cents = i % 100
	val dollars = i / 100
	return "$dollars.${if (cents < 10) "0$cents" else cents}"
}

fun tipRate(subTotal: String?, tipValue: Int): String {
	val subTotalOrZero = subTotal ?: "0.00"
	return toMoney(toCents(subTotalOrZero) * tipValue / 100)
}

fun toCents(money: String): Long =
	if (money.contains(".")) {
		val split = money.replace(",", "").split(".")
		split[0].toDollars() +
				if (split[1].isBlank()) 0 else split[1].toInt()
	} else {
		money.toDollars()
	}

fun String.toDollars(): Long = if (this.isNotEmpty()) {
	this.toLong() * 100
} else {
	0L
}
