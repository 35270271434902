package pages

import api.Login
import api.StateResponse
import api.addSessionHeader
import api.addSessionStaticHeader
import api.client
import api.resendCode
import api.storage.StorageKeys
import api.storage.storeRooamHeader
import components.divs.SectionNoTopBottom
import components.divs.container
import components.help.help
import components.layout
import components.location
import components.marketing.smallBanner
import components.rooamFooter
import components.seo
import csstype.ClassName
import csstype.Display
import csstype.None
import imported.numberformat.Values
import imported.numberformat.numberFormat
import imported.sentry.setUser
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.sessionStorage
import kotlinx.coroutines.launch
import kotlinx.js.jso
import kotlinx.serialization.Serializable
import mainScope
import react.FC
import react.PropsWithChildren
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.NavigateFunction
import react.router.Params
import react.router.useNavigate
import react.router.useParams
import react.useState
import kotlin.js.json

external interface VerifyProps : PropsWithChildren {
	var phoneNumberFormatted: String
}

external interface OTP {
	var code: String
}

val verify = FC<VerifyProps> { props ->
	val location = useParams()
	val (error, setError) = useState(false)
	val (resendState, setResendState) = useState(false)
	val (errorMessage, setErrorMessage) = useState("Code did not match")
	val (loading, setLoading) = useState(false)
	val (codeValues, setCodeValues) = useState(jso<Values>{ value = "" })
	val useHistory = useNavigate()
	val submit: (MouseEvent<*, *>) -> Unit = {
		it.preventDefault()
		if (!loading) {
			setLoading(true)
			login(
				sessionStorage.getItem(StorageKeys.phone) ?: "CLEANED UP",
				codeValues.value,
				{ value ->
					setError(true)
					value?.let { setErrorMessage(value) }
					setLoading(false)
				},
				useHistory,
				location
			)
		}
	}
	layout {
		seo {
			titleTemplate = "Verify Your Phone - %s"
		}
		SectionNoTopBottom {
			location {
				showMenu = false
			}
			smallBanner {
			}
			div {
				className = ClassName("section no-bottom")
				container {
					h1 {
						className = ClassName("no-top-bottom")
						+"Enter the Code."
					}
					p {
						className = ClassName("no-bottom")
						+"Sent to ${props.phoneNumberFormatted}"
					}
				}
			}
			div {
				className = ClassName("section less no-bottom")
				container {
					form {
						method = "get"
						action = "/account"
						id = "form-phone-number"
						div {
							className = ClassName("section less no-top")
							style = jso {


								display = if (error) Display.block else None.none
							}
							div {
								className = ClassName("message error")
								div {
									className = ClassName("message-wrap")
									span {
										className = ClassName("icon")
										svg {
											className = ClassName("svg-icon")
											viewBox = "0 0 24 24"
											path {
												d =
													"M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"


												fill = "#ec0b19"


											}
										}
									}
									p {
										className = ClassName("small text-white no-top-bottom")
										span {
											className = ClassName("font-weight-600")
											+"$errorMessage."
										}
										+" Please try again."
									}
								}
							}
						}
						div {
							className = ClassName("row clearfix")
							div {
								className = ClassName("column full")
								numberFormat {
									className = ClassName("form-control")
									id = "verify-code"
									placeholder = "Enter Code Here"
									format = "###-###"
									onValueChange = {
										setCodeValues(it)
									}
									value = codeValues.value
									autoComplete = "one-time-code"

								}
							}
						}
						div {
							className = ClassName("link-box no-bottom")
							button {
								className = ClassName("btn red ${if (loading) "disabled" else ""}")

								onClick = submit

								+if (loading) "Loading..." else "Next"

							}
						}
					}
				}
			}
			SectionNoTopBottom {
				container {
					div {
						className = ClassName("link-box no-bottom text-center")
						p {
							className = ClassName("small text-grey no-top-bottom")
							+"Problems with your auth-code? "
							a {
								className = ClassName("text-link ${if (resendState) "text-green" else ""}")

								onClick = {
									it.preventDefault()

									if (!resendState) {
										resendCode(sessionStorage.getItem(StorageKeys.phone) ?: "CLEANED UP",
											{ setResendState(it) }) {
											setErrorMessage(it ?: "An Error Has Happened")
											setError(true)
										}

									}

								}
								+if (resendState) "Code sent" else "Send a new code"
							}
						}
					}
				}
			}
			help { }
			rooamFooter {}
		}
	}

}


val login = { phone: String,
              code: String,
              setError: (String?) -> Unit,
              history: NavigateFunction,
              locationProps: Params ->
	mainScope.launch {
		val post: HttpResponse =
			client.post {
				url(path = "/login")
				setBody(Login(number = phone, code = code, placeCode = locationProps["code"]?:""))
				header("Content-Type", ContentType("application", "json"))
				addSessionHeader()
				expectSuccess = false
			}
		if (HttpStatusCode.OK != post.status) {
			val receive = post.body<Map<String, Map<String, String>>>()
			val errors = receive["errors"]
			setError(errors?.values?.first())
			return@launch
		}
		post.storeRooamHeader()
		setUser(
			json(
				"username" to phone
			)
		)
		moveToNext(history, locationProps)
	}

}

suspend fun getState(): HttpResponse {
	val get: HttpResponse = client.get {
		url(path = "/")
		header("Content-Type", ContentType("application", "json"))
		addSessionHeader()
		expectSuccess = false
	}.also { it.storeRooamHeader() }
	return get
}


suspend fun getStateQuery(): StateResponse {
	val get: HttpResponse = client.get {
		url(path = "/")
		header("Content-Type", ContentType("application", "json"))
		addSessionHeader()
		expectSuccess = true
	}.also { it.storeRooamHeader() }
	return get.body()
}

suspend fun getTips(): HttpResponse {
	val get: HttpResponse = client.get {
		url(path = "tab")
		header("Content-Type", ContentType("application", "json"))
		addSessionHeader()
		expectSuccess = false
	}.also { it.storeRooamHeader() }
	return get
}


suspend fun getSingleFeed(): HttpResponse = client.get {
	url(path = "feed")
	header("Content-Type", ContentType("application", "json"))
	addSessionStaticHeader()
	expectSuccess = false
}

suspend fun getSingleFeedWithoutError(): HttpResponse = client.get {
	url(path = "feed")
	header("Content-Type", ContentType("application", "json"))
	addSessionStaticHeader()
	expectSuccess = true
}



@Serializable
data class Tips(val tips: List<Tip>, val defaultTip: Tip)

@Serializable
data class Tip(val percentage: Int)
