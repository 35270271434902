package components.animations

import csstype.ClassName
import emotion.css.cx
import react.ComponentType
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span

external interface ErrorMessageProps : Props {
	var title: String
	var text: String
	var icon: ComponentType<*>
	var isSmall: Boolean?
}

val ErrorMessage = FC<ErrorMessageProps>("Error Message") { props ->
	div {
		className = ClassName("message-wrap")
		props.icon.create {
		}
		p {
			className = cx(
				ClassName("text-white no-top-bottom"),
				if (props.isSmall == true) {
					ClassName(" small")
				} else null
			)

			span {
				className = ClassName("font-weight-600")
				+props.title
			}
			+props.text
		}
	}
}
