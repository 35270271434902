package components.modals

import co.rooam.theme.ThemesProvider
import csstype.Auto
import csstype.ClassName
import csstype.Display
import csstype.Margin
import csstype.NamedColor
import csstype.None
import csstype.Padding
import csstype.TextAlign
import csstype.pct
import csstype.px
import emotion.react.css
import kotlinx.js.jso
import mui.material.Box
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.Drawer
import mui.material.DrawerAnchor
import mui.material.styles.ThemeProvider
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span


external interface ConfirmModalProps : Props {
	var text: String
	var title: String
	var confirmButtonText: String?
	var showIcon: Boolean?
	var booleanShowModal: Boolean?
	var confirmActionPressed: () -> Unit
	var cancelActionPressed: () -> Unit
}

val ConfirmModal = FC<ConfirmModalProps> { props ->

	val modalConfirmImage = kotlinext.js.require("./images/icon/confirm.png")

	ThemeProvider {
		theme = ThemesProvider.dialogTheme
		Drawer {
			open = props.booleanShowModal
			onClose = { event, _ ->
				event.stopPropagation()
				props.cancelActionPressed() }
			anchor = DrawerAnchor.bottom
			PaperProps = jso {
				sx {
					borderTopRightRadius = 10.px
					borderTopLeftRadius = 10.px
					textAlign = TextAlign.center
					color = NamedColor.black
				}
			}
			Box {
				sx {
					padding = Padding(38.px, 28.px, 34.px)
				}



				span {
					css(ClassName("up-down")) {
						display = if (props.showIcon == true) Display.inlineBlock else None.none
						margin = Margin(0.px, 0.px, 14.px)
						width = 80.px
						height = Auto.auto
					}
					img {
						alt = "Confirm Tab Icon"
						src = modalConfirmImage
					}
				}
				h2 {
					css(ClassName("text-black")) {}
					+props.title
				}
				DialogContent {
					p {
						className = ClassName("text-grey")
						+props.text
					}
				}
				DialogActions {
					Button {
						sx {
							width = 100.pct
							minHeight = 48.px
							maxHeight = 72.px
						}
						onClick = {
							it.stopPropagation()
							props.cancelActionPressed() }
						color = ButtonColor.primary

						variant = ButtonVariant.outlined
						+"Cancel"

					}
					Button {
						sx {
							width = 100.pct
							minHeight = 48.px
							maxHeight = 72.px
						}
						onClick = {
							it.stopPropagation()
							props.confirmActionPressed() }
						color = ButtonColor.primary

						variant = ButtonVariant.contained
						+(props.confirmButtonText ?: "Confirm")
					}
				}


			}


		}
	}
}



