package components.qr

import api.IdentityVerificationProfile
import api.pour.PourInfo
import api.pour.PourResponse
import api.pour.	pourDrink
import csstype.*
import getIdentityProfileQuery
import io.ktor.client.plugins.*
import kotlinx.js.jso
import mui.icons.material.QrCode
import mui.material.Alert
import mui.material.AlertColor
import mui.material.Fab
import mui.material.FabVariant
import mui.material.Snackbar
import mui.system.sx
import react.VFC
import react.query.InvalidateQueryFilters
import react.query.QueryKey
import react.query.UseMutationOptions
import react.query.useMutation
import react.query.useQueryClient
import react.useEffect
import react.useState
import kotlin.js.Promise


val QrScanner = VFC {
	var pourResponse by useState<PourResponse?>(null)
	var showModal by useState(false)
	var showAlert by useState(false)
	val queryClient = useQueryClient()
	val mutation =
		useMutation(
			::pourDrink,
			jso<UseMutationOptions<PourResponse, ResponseException, PourInfo, String>> {
				onSuccess = { response, _, _ ->
					queryClient.invalidateQueries(
						QueryKey<QueryKey>("feed"),
						jso<InvalidateQueryFilters<String>> {},
						jso {})
					pourResponse = response
					showAlert = true
					Promise.resolve("")
				}
			})

	var identityProfileQuery = getIdentityProfileQuery()
	var showQrScannerButton by useState(false)
	useEffect(identityProfileQuery.data) {
		(identityProfileQuery.data as? IdentityVerificationProfile)?.let {
			showQrScannerButton = it.ageVerified
		}
	}

	Snackbar {
		open = showAlert
		sx {
			position = Position.fixed
			bottom = 80.px
			right = 16.px

		}
		autoHideDuration = 5000
		onClose = { _, _ ->
			showAlert = false
			 }
		Alert {
			sx{
				width = 100.pct
			}
			severity = if (pourResponse?.success == true) AlertColor.success else AlertColor.error
			+(if (pourResponse?.success == true) {
				"You can now play or pour"
			} else {
				pourResponse?.errorMessage ?: "UnknownError"
			})
		}
	}
	Fab {
		onClick = { showModal = true }
		sx {
			display = if (showQrScannerButton) Display.inlineFlex else None.none
			position = Position.fixed
			bottom = 16.px
			right = 16.px
			color = NamedColor.white
			backgroundColor = Color("#6533F1")
		}
		variant = FabVariant.extended
		QrCode {
			sx{
				marginRight = 2.px
			}
		}
		+" Scan to Play or Pour"
	}
	QrModal {
		booleanShowModal = showModal
		cancelActionPressed = { showModal = false }
		confirmActionPressed = { barId, tapId ->
			val pourInfo = PourInfo(barId, tapId)
			console.log("Send POUR INFO:", pourInfo)
			mutation.mutate(pourInfo, null)
			showModal = false
		}
	}


}

